import { Grid } from '@mui/material'
import React, { FunctionComponent } from "react"
import { currentLocale, preRegistrationLinkTemplateFor } from "../../../domain/services/MetadataService"
import { PaymentStore } from "../../../domain/stores/payment-store"
import { ConfirmationPageSection } from "./ConfirmationPageSection"
import {isBunk} from "../../../domain/helpers/vendor-helpers";
import {VendorCode} from '../../../domain/models/search-models';

interface IPreregistrationBlurbProps {
  paymentStore: PaymentStore
}

export const PreregistrationBlurb: FunctionComponent<IPreregistrationBlurbProps> = ({paymentStore}) => {
  if (isBunk(paymentStore.vendor.code as VendorCode)) return null

  const urlTemplate = preRegistrationLinkTemplateFor(paymentStore.partner.code)
  if (!urlTemplate) return null

  const url = urlTemplate
    .replace("{reservationNumber}", paymentStore.reservationNumber!)
    .replace("{surname}", paymentStore.customer.surname)

  const body = ({
    "en-GB": (
      <>
        <Grid item>
          We know your holiday time is precious.<br />
          Fill out our online check in form to accelerate your pick up time, and make the most of your road trip adventure.
        </Grid>

        <Grid item>
          <a href={url} className="partner-link">
            Check in online and save time
          </a>
        </Grid>
      </>
    ),
    "de-DE": (
      <>
        <Grid item>
          Wir wissen, dass Ihre Urlaubszeit kostbar ist.<br />
          Füllen Sie unser Voranmeldungsformular aus, um Ihre Abholzeit zu verkürzen und das Beste aus Ihrem Selbstfahrer-Abenteuer zu machen.
        </Grid>

        <Grid item>
          <a href={url} className="partner-link">
            Vorregistrieren und Zeit sparen
          </a>
        </Grid>
      </>
    )
  })[currentLocale()]

  return (
    <ConfirmationPageSection headerKey="headers.confirmation.preregistration">
      {body}
    </ConfirmationPageSection>
  )
}

