import { Grid } from '@mui/material'
import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { ConfirmationPageSection } from "./ConfirmationPageSection"

interface IRegisterLocationDetailsBlurbProps {
  onClick: () => void
}

export const RegisterLocationDetailsBlurb: FunctionComponent<IRegisterLocationDetailsBlurbProps> = ({onClick}) => {
  return (
    <ConfirmationPageSection headerKey="dialogs.preregistration.header">
      <Grid item>
        <FormattedMessage id="dialogs.preregistration.intro_short" />
      </Grid>

      <Grid item>
        <button onClick={onClick} className="partner-link">
          <FormattedMessage id="dialogs.preregistration.trigger" />
        </button>
      </Grid>
    </ConfirmationPageSection>
  )
}
