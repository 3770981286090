import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'

const VehicleDisclaimerCar = (_params: IImportantInfoSection) => {
  return VehicleDisclaimerCarEN()
}

const VehicleDisclaimerCarEN = () => (
  <div className='important-info'>
    <h3>Please Note</h3>
    <p>
      <ul>
        <li>Vehicles shown are examples. Specific makes/models within a car class may vary in availability and features such as passenger seating, luggage capacity, equipment and mileage.</li>
        <li>For vehicles with 3rd row seating, the luggage capacity will vary when the 3rd row is upright and/or folded down.</li>
      </ul>
    </p>
  </div>
)

export default VehicleDisclaimerCar
