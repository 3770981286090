import {Grid, TextField} from '@mui/material'
import {observer, useObserver} from 'mobx-react-lite'
import moment from 'moment'
import React, {FunctionComponent, ReactNode, useContext} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { FormattedMessage } from 'react-intl'
import {useHistory} from 'react-router-dom'
import {SearchStoreContext} from '../../App'
import calendar from '../../assets/img/calendar.svg'
import campervan from '../../assets/img/camper-van.svg'
import {formatForUrl} from '../../domain/helpers/date-formatters'
import * as Analytics from '../../utils/analytics'

import { LocalizationProvider, MobileDatePicker, PickersDay, pickersDayClasses, PickersDayProps } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { registerLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';

import './UpdateSearch.scss'
import { orange } from '@mui/material/colors'
import { SearchParameters } from '../../domain/models/search-models'

const renderOption = (keyPrefix: string, key: string, description: string) => (
  <option key={`${keyPrefix}-${key}`} value={key}>
    {description}
  </option>
)

interface ICustomInputProps {
  image: string
  children: ReactNode
  labelKey?: string
}

interface IPickupDateSelectorProps {
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void
}

interface IDropoffDateSelectorProps {
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void
}

const renderWeekPickerDay = (
  _date: Date,
  _selectedDates: Array<Date | null>,
  pickersDayProps: PickersDayProps<Date>
) => {
  const style = {
    [`&&.${pickersDayClasses.root}`]: {
      fontSize: "1.2rem",
      outline: "none"
    },

    [`&&.${pickersDayClasses.selected}`]: {
      backgroundColor: orange.A400
    },

    [`&&.${pickersDayClasses.disabled}`]: {
      opacity: 0.4
    }
  }

  return (
    <PickersDay {...pickersDayProps} sx={style} />
  );
};

const CustomInput: FunctionComponent<ICustomInputProps> = props => (
  <Grid container item className='select-apollo' justifyContent="space-between">
    <meta name="viewport" content="width=device-width, user-scalable=no" />
    <Grid item xs={2}>
      <div className='select-apollo__img'>
        <img alt='vehicle' src={props.image} />
      </div>
    </Grid>
    <Grid item xs={9} sm={10} container>
      {props.labelKey && (
        <Grid item className='select-apollo__title' xs={12}>
          <FormattedMessage id={props.labelKey} />
        </Grid>
      )}
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  </Grid>
)

const PickupLocationSelector = observer(() => {
  const store = useContext(SearchStoreContext)

  return useObserver(() => (
    <CustomInput image={campervan} labelKey='headers.pickup'>
      <select
        className='select-apollo__options'
        id='pickupSelect'
        value={store.pickupDepot?.code}
        onChange={e => store.updatePickupDepot(e.target.value)}
        disabled={!store.pickupDepots}
      >
        {store.pickupDepots?.map(depot => renderOption('pickup-date', depot.code, depot.name))}
      </select>
    </CustomInput>
  ))
})

const DropoffLocationSelector = observer(() => {
  const store = useContext(SearchStoreContext)

  return useObserver(() => (
    <CustomInput image={campervan} labelKey='headers.dropoff'>
      <select
        className='select-apollo__options'
        id='pickupSelect'
        value={store.dropOffDepot?.code}
        onChange={e => store.updateDropoffDepot(e.target.value)}
        disabled={!store.dropOffDepots}
      >
        {store.dropOffDepots?.map(depot => renderOption('dropoff-date', depot.code, depot.name))}
      </select> 
    </CustomInput>
  ))
})

const PickupDateSelector = observer(({ onChange }: IPickupDateSelectorProps) => {
  const store = useContext(SearchStoreContext)

  return useObserver(() => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomInput image={calendar} labelKey="Pick Up Date">
        <MobileDatePicker
          inputFormat="dd MMMM yyyy"
          disableCloseOnSelect={false}
          disablePast={true}
          renderInput={props => <TextField fullWidth margin="none" size="small" variant="standard" value={store.pickupDate} {...props} /> }
          showToolbar={false}
          renderDay={renderWeekPickerDay}
          className="select-apollo__date"
          DialogProps={{
            className: "apollo-calender"
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: '1.2rem',
              paddingTop: 0.5,
              paddingLeft: 0.5
            }
          }}
          value={store.pickupDate}
          minDate={new Date()}
          showDaysOutsideCurrentMonth={true}
          shouldDisableDate={date => !store.isOpenForPickupOn(date)}
          onChange={onChange} />
      </CustomInput>
    </LocalizationProvider>
  ))
})

const DropoffDateSelector = observer(({ onChange }: IDropoffDateSelectorProps) => {
  const store = useContext(SearchStoreContext)

  return useObserver(() => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomInput image={calendar} labelKey="Drop Off Date">
        <MobileDatePicker
          inputFormat="dd MMMM yyyy"
          disableCloseOnSelect={false}
          disablePast={true}
          renderInput={props => <TextField fullWidth margin="none" size="small" variant="standard" {...props} /> }
          showToolbar={false}
          renderDay={renderWeekPickerDay}
          className="select-apollo__date"
          DialogProps={{
            className: "apollo-calender"
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: '1.2rem',
              paddingTop: 0.5,
              paddingLeft: 0.5
            }
          }}
          showDaysOutsideCurrentMonth={true}
          value={store.dropOffDate}
          minDate={moment(store.pickupDate).toDate()}
          shouldDisableDate={date => !store.isOpenForDropoffOn(date)}
          onChange={onChange} />
      </CustomInput>
    </LocalizationProvider>
  ))
})

const RerunSearchButton = observer(() => {
  let history = useHistory()
  const store = useContext(SearchStoreContext)

  const searchUrlFromParams = (params: SearchParameters) => {
    return [
      '/search',
      params.partner_code,
      params.country,
      params.vehicle_type,
      params.pick_up_location,
      params.drop_off_location,
      params.pick_up_date,
      params.drop_off_date,
      params.drivers_licence,
      params.adults,
      params.children,
      params.driver_age,
      params.promo_code
    ].join('/')
  }

  return useObserver(() => (
    <FormattedMessage id="action-buttons.update-search">
      {(t) => (
        <input
          type='button'
          value={t as string}
          className='update-search__update'
          onClick={() => {
            Analytics.trackEvent('refine_search')
            
            const updatedSearch: SearchParameters = {
              ...store.searchParameters!,
              pick_up_location: store.pickupDepot!.code,
              drop_off_location: store.dropOffDepot!.code,
              pick_up_date: formatForUrl(store.pickupDate!),
              drop_off_date: formatForUrl(store.dropOffDate!),
            }

            history.push(searchUrlFromParams(updatedSearch))
          }}
        />            
      )}
    </FormattedMessage>
  ))
})

export const UpdateSearchDialogLayout = observer(() => {
  const store = useContext(SearchStoreContext)
  registerLocale('de', de)

  return useObserver(() => (
    <Grid container>
      <Grid item xs={12} container justifyContent='space-around' spacing={1}>
        <Grid item xs={6}>
          <PickupLocationSelector />
        </Grid>

        <Grid item xs={6}>
          <PickupDateSelector onChange={(date) => store.setPickupDate(date)} />
        </Grid>

        <Grid item xs={6}>
          <DropoffLocationSelector />
        </Grid>

        <Grid item xs={6}>
          <DropoffDateSelector onChange={(date) => store.updateDropOffDate(date)} />
        </Grid>

        <Grid item xs={12}>
          <RerunSearchButton />
        </Grid>
      </Grid>
    </Grid>
  ))
})

const UpdateSearch = () => {
  const store = useContext(SearchStoreContext)
  registerLocale('de', de)

  return useObserver(() => (
    <Grid container className='update-search'>
      <Grid item xs={12} container justifyContent='space-around' spacing={1}>
        <Grid item xs={6} md={3}>
          <PickupLocationSelector />
        </Grid>

        <Grid item xs={6} md={3}>
          <DropoffLocationSelector />
        </Grid>

        <Grid item xs={6} md={2}>
          <PickupDateSelector onChange={(date) => store.setPickupDateAndUpdateDropoffDate(date)} />
        </Grid>

        <Grid item xs={6} md={2}>   
          <DropoffDateSelector onChange={(date) => store.updateDropOffDate(date)} />
        </Grid>

        <Grid item xs={12} md={2}>
          <RerunSearchButton />
        </Grid>
      </Grid>
    </Grid>
  ))
}

export default observer(UpdateSearch)
