import React from 'react'
import { formatMoney } from '../../../domain/helpers/number-formatters'
import { BookingStore } from '../../../domain/stores/booking-store'
import { PaymentStore } from '../../../domain/stores/payment-store'
import { defaultFallbackBondDisclaimer } from '../../../domain/services/MetadataService'

interface IBondPayableNoticeProps {
  store: BookingStore | PaymentStore
}

export const BondPayableNotice = ({store}: IBondPayableNoticeProps) => {
  const coverMetadata = store.selectedCoverMetadata
  const currency = store.paymentOptions[0].currency
  const bondAmount = store.paymentOptions[0].refundableBond
  const payableAtPickup = store.paymentOptions[0].payableAtPickup || 0

  if (coverMetadata?.bondDisclaimer === false)
    return null;
  
  let disclaimer = !coverMetadata?.bondDisclaimer || coverMetadata?.bondDisclaimer! === true
    ? defaultFallbackBondDisclaimer
    : coverMetadata?.bondDisclaimer

  const processedDisclaimer = disclaimer
    .split(/(\{[^}]+\})/)
    .map((item) => {
      if (item === '{product}')
        return <span key={item} className='selected-cover'>{coverMetadata?.displayName}</span>
      else if (item === '{bondLabel}')
        return coverMetadata?.bondLabel
      else if (item === '{currency_amount}')
        return <span key={item} className='selected-cover'>{currency} {formatMoney(bondAmount)}</span>
      else if (item === '{currency_externalAmount}')
        return <span key={item} className='selected-cover'>{currency} {formatMoney(payableAtPickup)}</span>
      else
        return item
    })

  return (
    <div className='u-margin-top-med u-italic'>
      <div>
        <span className='astrics'>&#42; </span>
        {processedDisclaimer}
      </div>
    </div>
  )
}