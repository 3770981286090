import React, { useEffect } from "react"
import { Grid } from '@mui/material'
import { trackEvent } from "../../utils/analytics"
import { ExtendedInvoiceRedemption, PointsRedemptionResultPayload } from "../../domain/models/search-models"
import { useObserver } from "mobx-react-lite"
import { toast } from 'react-toastify'
import { useIntl } from "react-intl"
import { config } from '../../domain/lib/config'

import './PointsPlusPayBookingPaymentOption.scss'
import { formatNumber } from "../../domain/helpers/number-formatters"

declare var qantas: any

const QFFProviderKey = "QFFBURN-AU"
const useQFFPointsEnabled = () => typeof qantas !== 'undefined'

interface IHasRedemptions {
  redemptions: ExtendedInvoiceRedemption[]
}

interface IPointsPlusPayBookingPaymentOptionProps extends IHasRedemptions {
  value: number
  onPointRedemptionRegistered: (pointsProvider: string, payload: PointsRedemptionResultPayload) => void
}

interface IPointsPlusPayBookingPaymentOptionsSelectorProps extends IPointsPlusPayBookingPaymentOptionProps {
  redemptionOptions: string[]
}

const renderQFFUsePointsButton = (value: number, callback: (payload: PointsRedemptionResultPayload) => void, onError: (errorCode: string) => void) => {
  qantas.Button.render({
    Client: {
      id: config.qantasPointRedemptionApiKey,
      name: config.qantasPointRedemptionApiClientId
    },
    onLoad: () => { trackEvent("qff-widget-load") },
    onClicked: () => { trackEvent("qff-widget-click") },
    payment: (actions: any) => { return actions.createQuote(value) },

    onAuthorize: (data: any) => {
      if (data.errorCode)
        onError(data.errorCode)
      else
        callback({
          value: data.currencyAmount,
          memberNumber: data.memberNumber,
          pointsUsed: Math.abs(data.pointsBalanceDelta),
          reference: data.quoteNumber,
          _raw: data
        })
    },

    onError: (data: any) => {
      console.log("QFF: ON_ERROR", data)

      if (data.errorCode)
        onError(data.errorCode)
    }
  }, "#qantasContainer")
}

export const PointsPlusPayBookingPaymentOption = ({redemptions, redemptionOptions, value, onPointRedemptionRegistered}: IPointsPlusPayBookingPaymentOptionsSelectorProps) => {
  return (
    <>
      {
         redemptionOptions.includes(QFFProviderKey) && (
           <QFFAUPointsPlusPayBookingPaymentOption value={value} onPointRedemptionRegistered={onPointRedemptionRegistered} redemptions={redemptions} />
         )
      }
    </>
  )
}

export const QFFAUPointsPlusPayBookingPaymentOption = ({redemptions, value, onPointRedemptionRegistered}: IPointsPlusPayBookingPaymentOptionProps) => {
  const canRender = useQFFPointsEnabled()
  const currentRedemptionsKnown = redemptions.length > 0
  const intl = useIntl()

  useEffect(() => {
    const pointsRegisteredCallback = (r: PointsRedemptionResultPayload) => {
      onPointRedemptionRegistered(QFFProviderKey, r)
    }
  
    const onPointsRegistrationError = (code: string) => {
      toast.error(intl.formatMessage({id: `booking.redemption-error.qff.${code}`}))
    }
  
    if (canRender && !currentRedemptionsKnown) {
      const container = document.getElementById("qantasContainer")
      if (container) container.innerHTML = ""
      renderQFFUsePointsButton(value, pointsRegisteredCallback, onPointsRegistrationError)
    }
  }, [canRender, currentRedemptionsKnown, value, onPointRedemptionRegistered, intl])

  return useObserver(() => {
    return (
      canRender ? (
        <div className='booking-payment-option'>
          <Grid container>
            <Grid className='booking-payment-option__title' item xs={11}>
              Qantas Frequent Flyer Member?
            </Grid>
    
            <Grid container item xs={12} className='booking-payment-option__body'>
              {
                !currentRedemptionsKnown
                  ? <QFFRedemptionPanel />
                  : <QFFRedemptionDetailPanel redemptions={redemptions} />
              }
            </Grid>
          </Grid>
        </div>
      ) : null
    )
  })
}

const QFFRedemptionPanel = () => (
  <>
    <Grid container item xs={12} className='booking-payment-option__item' justifyContent='space-between'>
      <Grid className='booking-payment-option__item-title' item>
        Use Qantas Points towards all or part of your road trip<span className='astrics'>&#42;</span>.<br />
      </Grid>
    </Grid>

    <div id="qantasContainer"></div>

    <Grid container item xs={12} className='booking-payment-option__item' justifyContent='space-between'>
      <Grid className='booking-payment-option__item-title' item>
        A minimum of 4,000 points is required.
      </Grid>
    </Grid>
  </>
)

const QFFRedemptionDetailPanel = ({redemptions}: IHasRedemptions) => {
  const totalPoints = redemptions.map(r => r.quantity?.quantity || 0).reduce((t, v) => t + v, 0)

  return (
    <>
      <Grid container item xs={12} className='booking-payment-option__item' justifyContent='space-between'>
        <Grid container item xs={9} className='booking-payment-option__item' justifyContent='space-between'>
          <Grid className='booking-payment-option__item-title' item>
            Qantas Points Redeemed
          </Grid>
        </Grid>

        <Grid className='booking-payment-option__item-amount' item xs={3}>
          {formatNumber(totalPoints)}
        </Grid>
      </Grid>
    </>
  )
}