import { Grid } from '@mui/material'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatNumber } from '../../domain/helpers/number-formatters'
import Image from '../Image/Image'

export interface ILoyaltyPointsEarnBadgeProps {
  provider: string
  amount: number
}

export const LoyaltyPointsEarnBadge = ({provider, amount}: ILoyaltyPointsEarnBadgeProps) => {
  const t = useIntl()

  return (
    <Grid container item justifyContent="flex-start" alignItems="center" direction="row">
      <Grid item xs={1}>
        <Image
          url={`/logos/${provider}.png`}
          description={t.formatMessage({id: `logo_alt.loyalty_scheme.${provider}`})}
          defaultImage=''
          className="loyaltyProgramLogo"
        />
      </Grid>

      <Grid item xs={11} className="loyaltyProgramEarnText">
        <span>
          <FormattedMessage id={`messages.possible_loyalty_earn.${provider}`} values={{points: formatNumber(amount)}} />
        </span>
        <span><sup>&dagger;</sup></span>
      </Grid>
    </Grid>
  )
}