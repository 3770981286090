import { VendorCode } from "../models/search-models"

export const isApollo = (vendorCode: VendorCode): boolean => {
  return vendorCode === 'APAU'
    || vendorCode === 'APDE'
    || vendorCode === 'APFR'
    || vendorCode === 'APNZ'
    || vendorCode === 'APUK'
    || vendorCode === 'APIE'
    || vendorCode === 'APUS'
}

export const isApolloEU = (vendorCode: VendorCode): boolean => {
  return vendorCode === 'APDE'
    || vendorCode === 'APFR'
    || vendorCode === 'APUK'
    || vendorCode === 'APIE'
}

export const isCheapa = (vendorCode: VendorCode): boolean => {
  return vendorCode === 'CCAU'
    || vendorCode === 'CCNZ'
    || vendorCode === 'CCUS'
}

export const isHippie = (vendorCode: VendorCode): boolean => {
  return vendorCode === 'HCAU'
    || vendorCode === 'HCNZ'
}

export const isStar = (vendorCode: VendorCode): boolean => {
  return vendorCode === 'SRAU'
    || vendorCode === 'SRNZ'
    || vendorCode === 'SRUS'
}

export const isBunk = (vendorCode: VendorCode): boolean => {
  return vendorCode === 'BKGB'
    || vendorCode === 'BKIE'
}

export const isApolloCars = (vendorCode: VendorCode): boolean => {
  return vendorCode === 'FFAU'
    || vendorCode === 'FFNZ'
    || vendorCode === 'FFUS'
    || vendorCode === 'ZEAU'
    || vendorCode === 'ZENZ'
    || vendorCode === 'ZEUS'
}

export const possibleVendorGroupsFor = (vendorCode: VendorCode): string[] => {
  const result: string[] = []

  const maybePush = (item: string) => {
    if (!result.includes(item))
      result.push(item)
  }

  if (isApollo(vendorCode)) maybePush('apollo')
  if (isApolloEU(vendorCode)) maybePush('apolloeu')
  if (isCheapa(vendorCode)) maybePush('cheapa')
  if (isHippie(vendorCode)) maybePush('hippie')
  if (isStar(vendorCode)) maybePush('star')
  if (isBunk(vendorCode)) maybePush('bunk')
  if (isApolloCars(vendorCode)) maybePush('apollocars')

  return result
}