import { CircularProgress, Dialog, Grid, LinearProgress } from '@mui/material'
import React from "react"
import { FunctionComponent } from "react"
import { useHistory } from "react-router-dom"
import { SpecialSearchResultCandidate } from "../../domain/models/specialSearchResultCandidate"
import { SelectableProduct } from "../../domain/stores/product"
import { SearchStore } from "../../domain/stores/search-store"
import { NameAndBerth } from "./NameAndBerth"
import Image from '../Image/Image'
import { observer, useObserver } from "mobx-react-lite"
import defaultImage from '../../assets/img/default-vehicle.png'
import { FormattedHTMLMessage, FormattedMessage } from "react-intl"
import { FeaturesTable } from "./FeaturesTable"
import styles from './SuggestedDealsDialog.module.scss'
import { ScoredAndRevisedSearchResult } from "../../domain/services/Api"
import { formatDate } from "../../domain/helpers/date-formatters"
import { viewHotDealsLinkFor } from "../../domain/services/MetadataService"
import { selectProduct } from "../../utils/analytics"
import { EditSearchStore } from "../../domain/stores/edit-search-store"
import { DropoffDatePicker, DropoffLocationPicker, PickupDatePicker, PickupLocationPicker } from "../BookingDetails/EditBookingParameters"
import ResultPrice from "./ResultPrice"
import { useContext } from "react"
import { EditSearchStoreContext } from "../../App"

interface ISuggestedDealsDialogProps {
  isOpen: boolean
  onClose: () => void
  searchStore: SearchStore
}

interface ISuggestedDealItemProps {
  searchStore: SearchStore
  suggestedDeal: SpecialSearchResultCandidate
  index: number
}

interface IRevisedSearchParameterDisplayProps {
  searchRevisionDetail: ScoredAndRevisedSearchResult
}

interface IEditSuggestedDealProps {
  deal: SpecialSearchResultCandidate
  searchStore: SearchStore
  onSubmit: (s: EditSearchStore) => void
}

interface IDealEditContainerProps {
  store: EditSearchStore
  deal: SpecialSearchResultCandidate
  onSubmit: (s: EditSearchStore) => void
}

interface IDealEditFormFieldsProps {
  store: EditSearchStore
}

export const SuggestedDealsDialog: FunctionComponent<ISuggestedDealsDialogProps> = props => {
  const specialSearchResults = props.searchStore.specialSearchResults
  const partnerCode = props.searchStore.searchParameters?.partner_code
  if (!specialSearchResults || !partnerCode) return null

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <Grid container className={styles.dialog_container} spacing={3}>
        <Grid item>
          <p className={styles.sub_header}>
            <FormattedMessage id="hotdeals.dialog_header" />
          </p>

          <p className={styles.light}>
            <FormattedHTMLMessage id="hotdeals.dialog_blurb" tagName="p" />
          </p>
        </Grid>

        {
          specialSearchResults.sort((a, b) => b.specialDetail.score - a.specialDetail.score).slice(0, 3).map((d, i) => (
            <Grid container item key={d.specialDetail.deal.inventoryControlNumber}>
              <DealPanel searchStore={props.searchStore} suggestedDeal={d} index={i} />
            </Grid>
          ))
        }

        <Grid item xs={12}>
          <a href={viewHotDealsLinkFor(partnerCode)} className={styles.view_more_link} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="hotdeals.view_all_link_text" />
          </a>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export const DealPanel: FunctionComponent<ISuggestedDealItemProps> = ({searchStore, suggestedDeal, index}) => {
  return (
    <Grid container item className={styles[suggestedDeal.product.info.vendorCode]}>
      <SpecialSearchResultItem searchStore={searchStore} suggestedDeal={suggestedDeal} index={index} />
    </Grid>
  )
}

const SpecialSearchResultItem: FunctionComponent<ISuggestedDealItemProps> = ({ searchStore, suggestedDeal, index }) => {
  const product = suggestedDeal.product
  const revisedSearchResultParameters = suggestedDeal.specialDetail
  const dealDetail = suggestedDeal.specialDetail.deal
  const selectableProduct = new SelectableProduct(product, index)

  const history = useHistory()
  const vehicle = product.info

  return useObserver(() => {
    const nameAndBerth = (
      <NameAndBerth name={vehicle.meta.name} berth={vehicle.berth} vendorCode={vehicle.vendorCode} />
    )

    const vehicleImage = (
      <Image
        url={`/images/${vehicle.code}.png`}
        defaultImage={defaultImage}
        description={`${vehicle.name} | ${vehicle.code} | ${vehicle.vendorName}`}
        className={styles.image}
      />
    )

    const onDealSelected = (editSearchStore: EditSearchStore) => {
      selectProduct(selectableProduct.toAnalyticsProduct(), 'suggested_deals')
      history.push(editSearchStore.bookingUrl)
    }

    return (
      <Grid item container className={styles.special_search_result_item}>
        <Grid container direction="row" alignItems="center">
          <Grid container item xs={12} md={7} direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={1}>
            <Grid item>
              {nameAndBerth}
            </Grid>

            <Grid item className={styles.heavy}>
              {dealDetail.ratePlan.name}
            </Grid>

            <Grid item className={styles.vehicle_blurb}>
              <FormattedHTMLMessage id={`vehicle.descriptions.${vehicle.meta.code.toUpperCase()}`} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} className={styles.image_wrapper}>
            {vehicleImage}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <DealResultDeltas searchRevisionDetail={revisedSearchResultParameters} />
        </Grid>

        <Grid container alignItems='flex-end'>
          <Grid item xs={12} className={styles.sub_header}>
            <FormattedMessage id='search-result.included-features' />
          </Grid>
          <Grid item xs={12}>
            <FeaturesTable features={vehicle.meta.features} vendorCode={vehicle.vendorCode} />
          </Grid>
        </Grid>

        <Grid container item alignItems='flex-end'>
          <Grid item className={styles.sub_header}>
            <FormattedMessage id='search-result.customise-deal' />
          </Grid>
          <Grid container item>
            <DealEditForm searchStore={searchStore} deal={suggestedDeal} onSubmit={onDealSelected} />
          </Grid>
        </Grid>
      </Grid>
    )
  })
}

const DealResultDeltas: FunctionComponent<IRevisedSearchParameterDisplayProps> = ({searchRevisionDetail}) => {
  return (
    <Grid container item justifyContent="space-evenly" className={styles.deal_result_deltas}>
      <Grid item>
        <p className={styles.heavy}>{searchRevisionDetail.revisedStartLocationCode}</p>
        <p className={styles.light}>{formatDate(searchRevisionDetail.revisedStartDateTime)}</p>
      </Grid>
      <Grid item>
        <p className={styles.highlight}>&rarr;</p>
      </Grid>
      <Grid item>
        <p className={styles.heavy}>{searchRevisionDetail.revisedEndLocationCode}</p>
        <p className={styles.light}>{formatDate(searchRevisionDetail.revisedEndDateTime)}</p>
      </Grid>
    </Grid>
  )
}

const DealEditForm: FunctionComponent<IEditSuggestedDealProps> = ({ searchStore, deal, onSubmit }) => {
  const editSearchStore = useContext(EditSearchStoreContext).getSubInstanceFor(searchStore, deal)

  return useObserver(() => {
    return !editSearchStore.bookingParameters
      ? <CircularProgress />
      : <DealEditContainer store={editSearchStore} deal={deal} onSubmit={onSubmit} />
  })
}

const DealEditContainer: FunctionComponent<IDealEditContainerProps> = observer(({ store, deal, onSubmit }) => {
  return (
    <Grid container item spacing={1} alignItems="stretch">
      <DealEditFormFields store={store} />

      <Grid container item alignItems="center">
        <DealPriceResultSection store={store} deal={deal} onSubmit={onSubmit} />
      </Grid>
    </Grid>
  )
})

const DealPriceResultSection: FunctionComponent<IDealEditContainerProps> = observer(({ store, deal, onSubmit }) => {
  if (store.isLoading) return null
  if (store.isBusy) return <ProgressIndicator />
  if (store.nowPrice) return <PriceAndSelectButton store={store} deal={deal} onSubmit={onSubmit} />

  const getQuoteForRelocation = async () => store.getQuoteForRelocation()
  return (
    <button className={styles.secondary_button} onClick={getQuoteForRelocation}>
      <FormattedMessage id='search-result.check-price' />
    </button>  
  )
})

const ProgressIndicator: FunctionComponent = observer(() => {
  return (
    <Grid item xs={12}>
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
      </div>
    </Grid>
  )
})

const PriceAndSelectButton: FunctionComponent<IDealEditContainerProps> = observer(({ store, deal, onSubmit}) => {
  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <ResultPrice
          currency={store.nowPrice!.currency}
          amount={store.nowPrice!.amount}
          vendorCode={deal.product.info.vendorCode} />
      </Grid>

      <Grid item xs={12}>
        <button className={styles.primary_button} onClick={() => onSubmit(store)}>
          <FormattedMessage id='search-result.select-deal' />
        </button>
      </Grid>
    </Grid>
  )
})

const DealEditFormFields: FunctionComponent<IDealEditFormFieldsProps> = observer(({ store }) => {
  return (
    <Grid container item spacing={1} justifyContent="space-between">
      <Grid container item md={6} spacing={1} direction="column">
        <Grid item>
          <label>
            <FormattedMessage id="headers.search_filter.pickup_location" />
          </label>
          {
            store.isLoading
              ? <LinearProgress />
              : <PickupLocationPicker store={store} />
          }
        </Grid>

        <Grid item>
          <div className={styles.datePickerWrapper}>
            {
              !store.isLoading && (
                <PickupDatePicker store={store} />
              )
            }
          </div>
        </Grid>
      </Grid>

      <Grid container item md={6} spacing={1} direction="column">
        <Grid item>
          <label>
            <FormattedMessage id="headers.search_filter.dropoff_location" />
          </label>
          {
            store.isLoading
              ? <LinearProgress />
              : <DropoffLocationPicker store={store} />
          }        
        </Grid>

        <Grid item>
          <div className={styles.datePickerWrapper}>
            {
              !store.isLoading && (
                <DropoffDatePicker store={store} />
              )
            }
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
})