import {Grid} from '@mui/material'
import React, {FunctionComponent} from 'react'
import {FormattedMessage} from 'react-intl'
import {ReactComponent as Info} from '../../assets/img/info.svg'
import {VendorCode} from '../../domain/models/search-models'
import * as Analytics from '../../utils/analytics'
import './SpecsLink.scss'

interface ISpecsLinkProps {
  vendorCode: VendorCode
  moreDetails: string
}
export const SpecsLink: FunctionComponent<ISpecsLinkProps> = props => (
  <Grid item className={`specs-link-${props.vendorCode}`} xs={12} sm={6}>
    <a target='_blank' rel='noopener noreferrer' href={props.moreDetails} onClick={() => Analytics.trackEvent('view_vehicle_specs')}>
      <Info />
      {'   '}
      <FormattedMessage id='search-result.vehicle-specs' />
    </a>
  </Grid>
)
