import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'
import { termsConditionsLinkFor } from '../../domain/services/MetadataService'

const TravelRestrictionsPolicyCar = (params: IImportantInfoSection) => {
  return DefaultTravelRestrictionsPolicyCarEN(params)
}

const DefaultTravelRestrictionsPolicyCarEN = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Travel Restrictions</h3>
    <p>
      Not all cars are suitable for all driving situations, so it's important to choose the right car
      for your journey. At our discretion, we may restrict car travel in certain areas due to adverse
      road or weather conditions, and the distance to nominated destinations in relation to the length
      of the rental period.
      Please review our <a href={termsConditionsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        Terms and Conditions - Travel restrictions
      </a> section prior to booking.
    </p>
  </div>
)

export default TravelRestrictionsPolicyCar
