import React from 'react'
import './EmailMeQuoteCheckbox.scss'
import { FormattedMessage } from 'react-intl'

export interface IEmailSubscriptionProps {
  onChange: (checked: boolean) => void
}

const EmailMeQuoteCheckbox = (props: IEmailSubscriptionProps) => {
  return (
    <div className='es checkbox'>
      <input onChange={e => props.onChange(e.target.checked)} id="EmailMeQuoteCheckbox" type='checkbox' />
      <label htmlFor="EmailMeQuoteCheckbox">
        <FormattedMessage id="email_me_quote_checkbox_label" />
      </label>
    </div>
  )
}

export default EmailMeQuoteCheckbox
