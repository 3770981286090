import React from 'react'
import {ReactComponent as Default} from '../../assets/img/default.svg'
import {ReactComponent as TCBUN} from '../../assets/img/extras/extra_2_chairs_1_table.svg'
import {ReactComponent as FWDCOV} from '../../assets/img/extras/extra_4wd_cover.svg'
import {ReactComponent as TCBUND} from '../../assets/img/extras/extra_6_chairs_1_table.svg'
import {
  ReactComponent as FKMSDAY,
  ReactComponent as MILES,
} from '../../assets/img/extras/extra_additional_500_miles.svg'
import {
  ReactComponent as BABY,
  ReactComponent as BABYS08,
  ReactComponent as CHILD,
  ReactComponent as GBABYS64,
} from '../../assets/img/extras/extra_baby_seat.svg'
import {ReactComponent as BEDDOUB} from '../../assets/img/extras/extra_bedding_towels.svg'
import {ReactComponent as BEDSING} from '../../assets/img/extras/extra_bedding_towels.svg'
import {ReactComponent as BIG4IP} from '../../assets/img/extras/extra_big4.svg'
import {ReactComponent as BOOST} from '../../assets/img/extras/extra_booster_seat.svg'
import {ReactComponent as CHAIR} from '../../assets/img/extras/extra_camping_chair.svg'
import {ReactComponent as TABLE} from '../../assets/img/extras/extra_camping_table.svg'
import {ReactComponent as PHCFCO} from '../../assets/img/extras/extra_drop_n_go_check_out.svg'
import {ReactComponent as DNG} from '../../assets/img/extras/extra_DNG_checkin.svg'
import {ReactComponent as DRIVER} from '../../assets/img/extras/extra_extra_driver_fee.svg'
import {ReactComponent as GENP} from '../../assets/img/extras/extra_generator_package.svg'
import {ReactComponent as GPS} from '../../assets/img/extras/extra_GPS.svg'
import {ReactComponent as HEATER} from '../../assets/img/extras/extra_heater_fan.svg'
import {ReactComponent as LINEN} from '../../assets/img/extras/extra_linen_bedding_kits.svg'
import {ReactComponent as PKITS} from '../../assets/img/extras/extra_personal_kits.svg'
import {ReactComponent as PETFEE} from '../../assets/img/extras/extra_pet_friendly.svg'
import {ReactComponent as PLATEP} from '../../assets/img/extras/extra_plate_pass.svg'
import {ReactComponent as PLB} from '../../assets/img/extras/extra_PLB.svg'
import {ReactComponent as POCWIFI} from '../../assets/img/extras/extra_portable_wifi.svg'
import {ReactComponent as CAMPGAS} from '../../assets/img/extras/extra_pre-purchased_gas_bottles.svg'
import {ReactComponent as RAP} from '../../assets/img/extras/extra_roadside_assist.svg'
import {ReactComponent as SATPHONE} from '../../assets/img/extras/extra_satellite_phone.svg'
import {ReactComponent as SNOW} from '../../assets/img/extras/extra_snow_chains.svg'
import {ReactComponent as SLI} from '../../assets/img/extras/extra_supplement_insurance.svg'
import {ReactComponent as TOILCH} from '../../assets/img/extras/extra_toilet_chemical.svg'
import {ReactComponent as TOLL} from '../../assets/img/extras/extra_toll_package.svg'
import {ReactComponent as TRAVTAB} from '../../assets/img/extras/extra_travel_tab.svg'
import {ReactComponent as WAP} from '../../assets/img/extras/extra_windscreen_tyre_protection.svg'
import {ReactComponent as TOWHITCH} from '../../assets/img/extras/extra_towhitch.svg'
import {ReactComponent as TOWMIR} from '../../assets/img/extras/extra_towing_mirrors.svg'
import {ReactComponent as FIRSTAID} from '../../assets/img/extras/extra_firstaidkit.svg'
import {ReactComponent as GAP} from '../../assets/img/extras/extra_windscreen_tyre_protection.svg'
import {ReactComponent as VWKIT} from '../../assets/img/extras/extra_vwkit.svg'
import {ReactComponent as GAZEBO} from '../../assets/img/extras/extra_gazebo.svg'
import {ReactComponent as CAMP_FURNITURE} from '../../assets/img/extras/extra_camp_furniture.svg'
import {ReactComponent as BIKE} from '../../assets/img/extras/extra_bike.svg'
import {ReactComponent as MCEUCOV} from '../../assets/img/extras/extra_europe.svg'
import {ReactComponent as EASY_RETURN} from '../../assets/img/extras/extra_easy_return.svg'
import {ReactComponent as SPECIAL_COVER} from '../../assets/img/extras/extra_windscreen_tyre_protection.svg'
import {ReactComponent as TRANSFER} from '../../assets/img/extras/extra_bus.svg'
import {ReactComponent as BKRACK} from '../../assets/img/extras/extra_bike_rack.svg'
import {ReactComponent as YOUNG_DRIVER} from '../../assets/img/extras/young_driver.svg'
import {ReactComponent as COVREFG} from '../../assets/img/extras/covid_refund_guarantee.svg'
import {SvgProps} from '../models/common'

const iconMap = new Map<string, React.FunctionComponent<SvgProps>>([
  ['PHCFCO', PHCFCO],
  ['ANIMFEE', PETFEE],
  ['WAP', WAP],
  ['CAMPGAS', CAMPGAS],
  ['BABY', BABY],
  ['BOOST', BOOST],
  ['DRIVER', DRIVER],
  ['GPS', GPS],
  ['SNOW', SNOW],
  ['TOILCH', TOILCH],
  ['PLB', PLB],
  ['4WDCOV', FWDCOV],
  ['BIG4IP', BIG4IP],
  ['RAP', RAP],
  ['GAS', CAMPGAS],
  ['BABYS0-8', BABYS08],
  ['HEATER', HEATER],
  ['TOLL', TOLL],
  ['TRAVTAB', TRAVTAB],
  ['500KMSDAY', FKMSDAY],
  ['BIG4IP4', BIG4IP],
  ['BIG4IP2', BIG4IP],
  ['TABLE', TABLE],
  ['CHAIR', CHAIR],
  ['SATPHONE', SATPHONE],
  ['SATBEACON', PLB],
  ['PKITS', PKITS],
  ['LINEN', LINEN],
  ['MILE500PK', MILES],
  ['SLI', SLI],
  ['GENP', GENP],
  ['BEDDOUB', BEDDOUB],
  ['BEDSING', BEDSING],
  ['GBOOST', BOOST],
  ['CHILD', CHILD],
  ['BKGPS', GPS],
  ['POCWIFI', POCWIFI],
  ['4WDCOVXT', FWDCOV],
  ['BKBOOST', BOOST],
  ['GBABYS6-4', GBABYS64],
  ['TCBUN', TCBUN],
  ['TCBUND', TCBUND],
  ['PLATEP', PLATEP],
  ['PETFEE', PETFEE],
  ['ADJTH', TOWHITCH],
  ['TOWMIR', TOWMIR],
  ['TOWMIRR', TOWMIR],
  ['FIRSTAID', FIRSTAID],
  ['TRAVTABADV', TRAVTAB],
  ['VWTKIT', VWKIT],
  ['BKTCBUN', CAMP_FURNITURE],
  ['GAZEBO', GAZEBO],
  ['BIKEFR', BIKE],
  ['MCEUCOV', MCEUCOV],
  ['EASRET', EASY_RETURN],
  ['WINTER', SNOW],
  ['SPECO', SPECIAL_COVER],
  ['FRTRANST', TRANSFER],
  ['FRTRANSF', TRANSFER],
  ['BKRACK', BKRACK],
  ['BKWMT', SPECIAL_COVER],
  ['YOUNGDR', YOUNG_DRIVER],
  ['USCHILD', CHILD],
  ['USBOOSTER', BOOST],
  ['USINFANT', BABY],
  ['OOHDO', DNG],
  ['OOHPU', DNG],
  ['UNLMIL', MILES],
  ['GAP', GAP],
  ['COVREFG', COVREFG],
])

export const getExtrasIcon = (code: string): React.FunctionComponent<SvgProps> => {
  const icon = iconMap.get(code.toUpperCase())
  return icon || Default
}
