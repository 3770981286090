import React from 'react'
import IPartnerConfirmationComponent from "./component-interface"
import { isPartner } from '../../../domain/helpers/partner-helpers'
import { Campstay } from './Campstay'
import { WorkTracker } from '../../LoadingPanel/work-tracker'
import { currentLocale } from '../../../domain/services/MetadataService'
import * as VendorHelper from '../../../domain/helpers/vendor-helpers'
import { VendorCode } from '../../../domain/models/search-models'

export const UpsellSection = ({store}: IPartnerConfirmationComponent) => {
  if (currentLocale() !== 'en-GB')
    return null

  const vendorCode = store.vendor.code as VendorCode
  const partnerCode = store.partner.code

  const isApollo = isPartner(partnerCode, 'apollobrands')
  const isApolloEU = isPartner(partnerCode, 'apolloeu')
  const isApolloCars = VendorHelper.isApolloCars(vendorCode)

  if (isApollo && !isApolloEU && !isApolloCars)
    return <Campstay store={store} tracker={new WorkTracker({})} />
  else
    return null
}