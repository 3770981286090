import { RentalActivityPriced, RentalCharge, SelectedEquipmentService, MandatoryFee, ChargeCalculation } from './api-models'
import { processPriceBreakdown, processDiscountBreakdown, processDistanceBreakdown, IPriceBreakdown, IDiscountBreakdown, IDistanceBreakdown, PriceBreakdownUnit } from '../../utils/regx'
import { formatMoney } from '../helpers/number-formatters'
import { CoverOption } from './search-models'
import { productLabelFor } from '../stores/product';
import { InvoiceDetail } from '../helpers/payment-helpers';
import { IntlShape } from 'react-intl';
import { ancillaryMetadataFor } from '../services/MetadataService';
import { calculatePossibleEarnRatesFrom, LoyaltyProductFeeCodes } from '../services/LoyaltyPointsService';
import { PaymentOptionType } from '../../components/BookingPayment/BookingPaymentOption';

export enum BookingSummarySectionType {
  VehicleHire = 'vehicle_hire',
  Inclusions = 'inclusions',
  LiabilityReduction = 'liability_reduction',
  Savings = 'savings',
}

export interface MessageDescriptorWithOptionalValues {
  templateKeys: string[]
  values?: any
}

export interface FlattenedCalculations {
  priceExplanations: IPriceBreakdown[]
  discountExplanations: IDiscountBreakdown[]
  distanceExplanations: IDistanceBreakdown[]
}

export interface BookingSummaryLineItemModel {
  description?: string
  pricingExplanation?: string
  pricingExplanationTemplate?: MessageDescriptorWithOptionalValues
  amount: number
}

export interface BookingSummarySectionModel {
  type: BookingSummarySectionType
  items: BookingSummaryLineItemModel[]
}

export const tryRenderSummaryLine = (intl: IntlShape, template?: MessageDescriptorWithOptionalValues, explicit?: string): string | undefined => {
  if (!template) return explicit
  return template.templateKeys.map(t => intl.formatMessage({ id: t }, template.values)).join('')
}

export const processRentalChargeSummaryLines = (rentalCharge: RentalCharge, travelCountryCode: string | undefined): BookingSummaryLineItemModel[] => {
  const flattenedCalculations = flattenPriceCalculations(rentalCharge.calculations)

  const items: BookingSummaryLineItemModel[] = flattenedCalculations.priceExplanations
    .map(line => ({
      pricingExplanationTemplate: priceBreakdownToTranslatableMessage(line),
      amount: line.qty * (line.unitCount || 1) * line.perUnitPrice
    }))
  
  items.push(...flattenedCalculations.discountExplanations
    .map(line => ({
      pricingExplanationTemplate: discountBreakdownToTranslatableMessage(line),
      amount: line.totalDiscount * line.percentage / 100
    })))
  
  items.push(...flattenedCalculations.distanceExplanations
    .map(line => ({
      pricingExplanationTemplate: distanceBreakdownToTranslatableMessage(line),
      amount: 0
    })))

  return items
}

export const processProductPricingSummaryLine = (item: SelectedEquipmentService | MandatoryFee, travelCountryCode: string | undefined): BookingSummaryLineItemModel => {
  const result: BookingSummaryLineItemModel = {
    description: ancillaryMetadataFor(item.product.code, travelCountryCode).displayName || item.product.name,
    amount: item.price?.amount || 0,
  }

  if (item.pricing) {
    result.pricingExplanation = item.pricing

    const breakdown = processPriceBreakdown(item.pricing)
    if (breakdown) {
      result.pricingExplanationTemplate = priceBreakdownToTranslatableMessage(breakdown)
    }
  }

  return result
}

const addOrUpdateCalculationEntry = (calculations: IPriceBreakdown[], qty: number, unitCount: number | undefined, units: PriceBreakdownUnit, pricePerUnit: number) => {
  const existing = calculations.find(p => p.units === units && p.perUnitPrice === pricePerUnit)

  if (!existing) {
    calculations.push({
      perUnitPrice: pricePerUnit,
      unitCount: unitCount,
      units: units,
      qty: qty
    })
  } else if (unitCount) {
    existing.unitCount = (existing.unitCount || 0) + unitCount
  }
}

const flattenPriceCalculations = (calculations?: ChargeCalculation[]): FlattenedCalculations => {
  const flattenedCalculations: IPriceBreakdown[] = [] as IPriceBreakdown[]
  const discountExplanations: IDiscountBreakdown[] = [] as IDiscountBreakdown[]
  const distanceExplanations: IDistanceBreakdown[] = [] as IDistanceBreakdown[]

  (calculations || []).forEach(calc => {
    const description = (calc.pricing || '').split('(')[0]
    const breakdown = processPriceBreakdown(description)
    const discountBreakdown = processDiscountBreakdown(description)
    const distanceBreakdown = processDistanceBreakdown(description)

    if (breakdown) {
      if (breakdown.units)
        addOrUpdateCalculationEntry(flattenedCalculations, breakdown.qty, breakdown.unitCount, breakdown.units, breakdown.perUnitPrice)

      if (breakdown.included)
        addOrUpdateCalculationEntry(flattenedCalculations, 1, breakdown.included.qty, breakdown.included.units, 0)
    } else if (discountBreakdown) {
      discountExplanations.push(discountBreakdown)
    } else if (distanceBreakdown) {
      distanceExplanations.push(distanceBreakdown)
    }
  })

  return {
    priceExplanations: flattenedCalculations.sort((a, b) => a.perUnitPrice - b.perUnitPrice),
    discountExplanations: discountExplanations,
    distanceExplanations: distanceExplanations
  }
}

const priceBreakdownToTranslatableMessage = (breakdown: IPriceBreakdown): MessageDescriptorWithOptionalValues => {
  if (breakdown.perUnitPrice === 0) {
    const templateKey = breakdown.units
      ? `booking.summary.pricing.free_allowance.${breakdown.units}.${breakdown.unitCount === 1 ? 'single' : 'multiple'}`
      : 'booking.summary.pricing.free_allowance'

    return {
      templateKeys: [templateKey],
      values: { qty: breakdown.unitCount || breakdown.qty }
    }
  }

  const result: MessageDescriptorWithOptionalValues = {
    templateKeys: [],
    values: {
      qty: breakdown.qty,
      unit_count: breakdown.unitCount,
      per_unit_price: formatMoney(breakdown.perUnitPrice)
    }
  }

  if (breakdown.qty > 1) {
    result.templateKeys.push('booking.summary.pricing.with_multiple_selections')
  }

  if (breakdown.unitCount && breakdown.units) {
    const countKey = breakdown.unitCount === 1 ? 'single' : 'multiple'
    result.templateKeys.push(`booking.summary.pricing.qty_at.${breakdown.units}.${countKey}`)
  }

  result.templateKeys.push(`booking.summary.pricing.price_per.${breakdown.units || 'each'}`)
  return result
}

const discountBreakdownToTranslatableMessage = (breakdown: IDiscountBreakdown): MessageDescriptorWithOptionalValues => {
  return {
    templateKeys: ['booking.summary.pricing.discount'],
    values: {
      percentage: breakdown.percentage * -1,
      currency: breakdown.currency,
      original_amount: formatMoney(breakdown.totalDiscount)
    }
  }
}

const distanceBreakdownToTranslatableMessage = (breakdown: IDistanceBreakdown): MessageDescriptorWithOptionalValues => {
  if (breakdown.unlimited)
    return {
      templateKeys: ['booking.summary.pricing.distance_allowance.unlimited']
    }

  return {
    templateKeys: [`booking.summary.pricing.distance_allowance.${breakdown.units}`],
    values: {
      qty: breakdown.qty,
      overage_price: formatMoney(breakdown.overagePrice!)
    }
  }
}

export default class BookingSummaryModel {
  private coverMetadata: CoverOption;
  public sections: BookingSummarySectionModel[] = []

  private vehicleCharges: RentalCharge[] = []
  private vehicleInclusionCharges: RentalCharge[] = []
  private vehicleDiscounts: RentalCharge[] = []

  constructor(
    readonly rental: RentalActivityPriced,
    readonly invoice: InvoiceDetail,
    readonly selectedCover: CoverOption,
    readonly selectedPaymentOption: PaymentOptionType | null,
    readonly totalAmount: number | null
  ) {
    const vehicleCode = this.rental.rental.product?.code
    this.coverMetadata = selectedCover

    this.vehicleCharges = this.rental.rental.charges
      ?.filter(c => (c.totalPrice?.amount || 0) >= 0 && c.product?.code === vehicleCode) || []
    
    this.vehicleInclusionCharges = this.rental.rental.charges
      ?.filter(c => (c.totalPrice?.amount || 0) >= 0 && c.product?.code !== vehicleCode) || []
    
    this.vehicleDiscounts = this.rental.rental.charges
      ?.filter(c => (c.totalPrice?.amount || 0) < 0) || []

    this.sections = [
      this.getVehicleHireSection(),
      this.getSavingsSection(),
      this.getLiabilityReductionSection(),
      this.getInclusionsSection(),
    ]
  }

  bondLabel(): string {
    return this.coverMetadata.bondLabel!
  }

  getVehicleHireSection(): BookingSummarySectionModel {
    const countryCode = this.travelCountryCode
    const results = this.vehicleCharges.flatMap(c => processRentalChargeSummaryLines(c, countryCode))
    return {type: BookingSummarySectionType.VehicleHire, items: results}
  }

  excludedFees(): string[] {
    return ["SDEBIT", ...LoyaltyProductFeeCodes]
  }

  getInclusionsSection(): BookingSummarySectionModel {
    const countryCode = this.travelCountryCode

    const kmCharge = this.vehicleInclusionCharges
      .flatMap(item => {
        const product = item.product!
        const summaryLines = processRentalChargeSummaryLines(item, countryCode)
        const displayValue = ancillaryMetadataFor(product.code, countryCode).displayName || product.name

        summaryLines.forEach(line => {
          line.description = displayValue
        })

        return summaryLines
      })

    const fees = this.rental.ancillaries
      .fees?.filter(item => !this.excludedFees().includes(item.product.code) || item.price?.amount !== 0)
      .map(fee => processProductPricingSummaryLine(fee, countryCode)) || []

    const equipmentAndServices = this.rental.ancillaries
      .equipmentAndServices
      ?.map(item => processProductPricingSummaryLine(item, countryCode)) || []

    return {
      type: BookingSummarySectionType.Inclusions,
      items: [...kmCharge, ...fees, ...equipmentAndServices],
    }
  }

  getLiabilityReductionSection(): BookingSummarySectionModel {
    const coverage = this.rental.ancillaries.coverage
    return {
      type: BookingSummarySectionType.LiabilityReduction,
      items: [
        {
          description: this.coverMetadata.displayName,
          amount: coverage?.price?.amount || 0,
        },
      ],
    }
  }

  getSavingsSection(): BookingSummarySectionModel {
    const discount = this.vehicleDiscounts
      .flatMap(item => {
        return (item.calculations || []).map(calc => {
          const breakdown = processDiscountBreakdown(calc.pricing || '')
          const discountExplanation = breakdown
            ? {
                templateKeys: ["booking.summary.pricing.discount"],
                values: {
                  percentage: breakdown.percentage * -1,
                  currency: breakdown.currency,
                  original_amount: formatMoney(breakdown.totalDiscount)
                }
              }
            : undefined

          return {
            description: productLabelFor(item.product!, this.rental),
            pricingExplanationTemplate: discountExplanation,
            amount: calc.price?.amount || 0,
          }
        })
      })

    return {
      type: BookingSummarySectionType.Savings,
      items: discount,
    }
  }

  get loyaltyEarnRates() {
    return calculatePossibleEarnRatesFrom(this.rental)
  }

  get displayedAmountPayable() {
    return formatMoney(this.selectedPaymentOption?.payNow || this.depositPayable)
  }

  get amountPayableToApollo() {
    return this.invoice?.charge?.amount.amount || 0
  }

  get amountPayableToSupplier() {
    return this.invoice?.charge?.externalAmount?.amount || 0
  }

  get totalCustomerAmountPayable() {
    return this.totalAmount || (this.amountPayableToApollo + this.amountPayableToSupplier);
  }

  get refundableBond() {
    return this.rental.ancillaries.coverage?.liability?.amount
  }

  get depositPayable() {
    return this.invoice?.charge?.defaultAmount.amount ||
      this.amountPayableToApollo
  }

  private get travelCountryCode() {
    return this.rental.rental.startDepot?.country?.code
  }
}
