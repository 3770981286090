import React, {ReactNode} from 'react'
import {ReactComponent as Default} from '../../assets/img/default.svg'
import {ReactComponent as AirconSvg} from '../../assets/img/features/aircon.svg'
import {ReactComponent as AutomaticSvg} from '../../assets/img/features/automatic.svg'
import {ReactComponent as ManualOrAutomaticSvg} from '../../assets/img/features/manual_or_automatic.svg'
import {ReactComponent as ChildseatsSvg} from '../../assets/img/features/childseats.svg'
import {ReactComponent as FridgeSvg} from '../../assets/img/features/fridge.svg'
import {ReactComponent as ManualSvg} from '../../assets/img/features/manual.svg'
import {ReactComponent as MicrowaveSvg} from '../../assets/img/features/microwave.svg'
import {ReactComponent as OffroadSvg} from '../../assets/img/features/offroad.svg'
import {ReactComponent as ShowerSvg} from '../../assets/img/features/shower.svg'
import {ReactComponent as StoveSvg} from '../../assets/img/features/stove.svg'
import {ReactComponent as ToiletSvg} from '../../assets/img/features/toilet.svg'
import {ReactComponent as TvSvg} from '../../assets/img/features/tv.svg'
import {ReactComponent as GPSSvg} from '../../assets/img/features/gps.svg'
import {ReactComponent as BikeRackSvg} from '../../assets/img/features/bike_rack.svg'
import {ReactComponent as ReversingCameraSvg} from '../../assets/img/features/reversing_camera.svg'
import {ReactComponent as OvenGrillSvg} from '../../assets/img/features/oven.svg'
import {ReactComponent as HeaterSvg} from '../../assets/img/features/heating.svg'
import {Feature, VendorCode} from '../../domain/models/search-models'
import './FeaturesTable.scss'
import { FormattedMessage } from 'react-intl'

interface IFeaturesProps {
  features: Feature[]
  vendorCode: VendorCode
}
export const FeaturesTable = ({features, vendorCode: brand}: IFeaturesProps) => {
  let iconMap = new Map<Feature, ReactNode>()
  iconMap.set('offroad', <OffroadSvg className='svg-colors' />)
  iconMap.set('stove', <StoveSvg className='svg-colors' />)
  iconMap.set('fridge', <FridgeSvg className='svg-colors' />)
  iconMap.set('manual', <ManualSvg />)
  iconMap.set('manual_or_automatic', <ManualOrAutomaticSvg />)
  iconMap.set('microwave', <MicrowaveSvg className='svg-colors' />)
  iconMap.set('tv', <TvSvg className='svg-colors' />)
  iconMap.set('aircon', <AirconSvg />)
  iconMap.set('toilet', <ToiletSvg />)
  iconMap.set('shower', <ShowerSvg />)
  iconMap.set('automatic', <AutomaticSvg className='svg-colors' />)
  iconMap.set('childseats', <ChildseatsSvg className='svg-colors' />)
  iconMap.set('GPS', <GPSSvg className='svg-colors' />)
  iconMap.set('bikerack', <BikeRackSvg className='svg-colors' />)
  iconMap.set('reversingcamera', <ReversingCameraSvg className='svg-colors' />)
  iconMap.set('ovengrill', <OvenGrillSvg className='svg-colors' />)
  iconMap.set('heating', <HeaterSvg className='svg-colors' />)

  const iconPath = (feature: Feature) => {
    let icon = iconMap.get(feature)
    return icon ? icon : <Default className='svg-colors' />
  }

  return (
    <div className='features__container'>
      {features.map(feature => {
        return (
          <div className='features__content' key={feature}>
            <div className={`features__icon features--icon-color--${brand}`}>
              {iconPath(feature)}
            </div>
            <span className='features__title'>
              <FormattedMessage id={`vehicle.features.${feature}`} />
            </span>
          </div>
        )
      })}
    </div>
  )
}
