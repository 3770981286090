import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'

const ChildRestraintPolicyCar = (_params: IImportantInfoSection) => {
  return DefaultChildRestraintPolicyCarEN()
}

const DefaultChildRestraintPolicyCarEN = () => (
  <div className='important-info'>
    <h3>Child Restraint Policy</h3>
    <p>
      <strong>Current Law Requirements:</strong><br />

      <ul>
        <li><strong>Children Under Six months</strong> must be using a rear facing child restraint with a built in harness, they must not be seated in the front row if there are two or more rows of seats.</li>
        <li><strong>Six months - under Four-year-old</strong> children must be using a rear facing or forward-facing child restraint with a build in harness, they cannot be seated in the front row if there are two or more rows of seats.</li>
        <li><strong>Four years - Seven-year-old</strong> children must be using a forward-facing child restraint with a built-in harness that has been approved or an approved booster seat which is properly fastened, and the seat belt/child safety harness can be adjusted.</li>
        <li><strong>Seven years and older</strong> must be using a properly adjusted and fastened child restraint or adult seatbelt, size depending.</li>
      </ul>
    </p>

    <p>
      <strong>Definitions:</strong><br />

      <ul>
        <li><strong>Child Seat</strong> – suitable for children 6 months to 4 years</li>
        <li><strong>Booster Seat</strong> – suitable for children over 4 years.</li>
      </ul>
    </p>

    <p>
      <em>It is the renter's responsibility to ensure that the correct seats have either been pre-booked or their own seats have been provided.</em>
    </p>
  </div>
)

export default ChildRestraintPolicyCar
