import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'

const TimeTravelPolicy = (params: IImportantInfoSection) => {
  return (
    <div className='important-info'>
      <h3>Time Travel Policy</h3>
      <p>
        <ol>
          <li>It is forbidden to talk to people about the travel in time in years prior to its creation.</li>
          <li>It is very important you don't fall in love with your great great grandmother/grandfather. It will get things very complicated in the future.</li>
        </ol>
      </p>
    </div>
  )
}

export default TimeTravelPolicy
