import React from 'react'
import IPartnerConfirmationComponent from "./component-interface"
import { Grid } from '@mui/material'
import { formatMoney } from '../../../domain/helpers/number-formatters'
import { isBunkPartner } from '../../../domain/helpers/partner-helpers'
import { FormattedMessage } from 'react-intl'
import { ConfirmationPageSection } from './ConfirmationPageSection'
import { formatDate } from '../../../domain/helpers/date-formatters'

export const OutstandingFinancials = (props: IPartnerConfirmationComponent) => {
  const store = props.store

  if (store.balancePayment.payable <= 0 && store.balancePayment.refundBond <= 0)
    return null

  return (
    <ConfirmationPageSection headerKey="headers.payments.to_be_made">
      {store.balancePayment.payable > 0 && <OutstandingBalance store={store} />}
      {false && store.balancePayment.refundable > 0 && <OverpaymentNotice store={store} />}
      {store.balancePayment.chargesAtPickup > 0 && <PickupCharges store={store} />}
      {store.balancePayment.refundBond > 0 && <RefundableBondNotice store={store} />}
    </ConfirmationPageSection>
  )
}

const OutstandingBalance = (props: IPartnerConfirmationComponent) => {
  if (isBunkPartner(props.store.partner.code)) {
    return OutstandingBalanceBunk(props);
  } else {
    return OutstandingBalanceApollo(props);
  }
}

const OutstandingBalanceApollo = ({store}: IPartnerConfirmationComponent) => {
  const details = store.balancePayment
  const amount = details.adminFee + details.payable

  return (
    <>
      <Grid item xs={9}>
        <div className='booking-confirmed__header'>
          <FormattedMessage id="headers.payments.prior_to_pickup" values={{dueDate: formatDate(store.invoiceResponse?.charge?.amountDueDate!)}} />
        </div>
        <div className='booking-confirmed__additional'>
          <FormattedMessage id="messages.payments.balance_owing_with_card_fees_not_automatic" />
        </div>
      </Grid>
      <Grid item xs={3} className='booking-confirmed__currency'>
        {details.currency} {formatMoney(amount)}
      </Grid>
    </>
  )
}

const OutstandingBalanceBunk = ({store}: IPartnerConfirmationComponent) => {
  const details = store.balancePayment
  const amount = details.adminFee + details.payable

  return (
    <>
      <Grid item xs={9}>
        <div className='booking-confirmed__header'>
          <FormattedMessage id="headers.payments.prior_to_pickup" values={{dueDate: formatDate(store.invoiceResponse?.charge?.amountDueDate!)}} />
        </div>
        <div className='booking-confirmed__additional'>
          <FormattedMessage id="messages.payments.balance_owing_no_card_fees_not_automatic" />
        </div>
      </Grid>
      <Grid item xs={3} className='booking-confirmed__currency'>
        {details.currency} {formatMoney(amount)}
      </Grid>
    </>
  )
}

const OverpaymentNotice = ({store}: IPartnerConfirmationComponent) => {
  const details = store.balancePayment

  return (
    <>
      <Grid item xs={9}>
        <div className='booking-confirmed__header'>
          <FormattedMessage id="headers.payments.time_of_pickup" />
        </div>
        <div className='booking-confirmed__additional'>
          <FormattedMessage id="messages.payments.refundable_amount_notice" />
        </div>
      </Grid>
      <Grid item xs={3} className='booking-confirmed__currency'>
        {details.currency} {formatMoney(details.refundable)}
      </Grid>
    </>
  )
}

const PickupCharges = ({store}: IPartnerConfirmationComponent) => {
  const details = store.balancePayment

  return (
    <>
      <Grid item xs={9}>
        <div className='booking-confirmed__header'>
          <FormattedMessage id="headers.payments.time_of_pickup" />
        </div>
        <div className='booking-confirmed__additional'>
          <FormattedMessage id="messages.payments.remaining_amount_notice" />
        </div>
      </Grid>
      <Grid item xs={3} className='booking-confirmed__currency'>
        {details.currency} {formatMoney(details.chargesAtPickup)}
      </Grid>
    </>
  )
}

const RefundableBondNotice = ({store}: IPartnerConfirmationComponent) => {
  const details = store.balancePayment

  return (
    <>
      <Grid item xs={9}>
        <div className='booking-confirmed__header'>
          <FormattedMessage id="headers.payments.time_of_pickup" />
        </div>
        <div className='booking-confirmed__additional'>
          <FormattedMessage id="messages.payments.refundable_security_deposit_notice" values={{bondLabel: store.selectedCoverMetadata?.bondLabel || 'Bond'}} />
        </div>
      </Grid>
      <Grid item xs={3} className='booking-confirmed__currency'>
        {details.currency} {formatMoney(details.refundBond)}
      </Grid>
    </>
  )
}