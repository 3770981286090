import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'
 
const CovidInfoRestrictions = (params: IImportantInfoSection) => {
 
    if (params.locale === 'en-GB') {
      return DefaultCovidInfoRestrictionsEN(params)
    } else {
      return DefaultCovidInfoRestrictionsDE
    }
  }
 

const DefaultCovidInfoRestrictionsEN = ({partnerCode, countryCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Guest Responsibilities (COVID-19)</h3>
    <p>
    It is the guest's responsibility (main driver and other adults on each booking) to keep up to date and comply with advice, 
    rules and restrictions from government and health authorities, and do their research in regards to leisure, 
    business and essential service travelling, border closures, lockdowns and caravan park/campground requirements.
    </p>
  </div>
)

const DefaultCovidInfoRestrictionsDE = (
  <div className='important-info'>
    
    <p>
    </p>
  </div>
)

export default CovidInfoRestrictions
