import {Grid} from '@mui/material'
import React, {FunctionComponent, useState} from 'react'
import {IExtra, Item} from './Item'

export interface ISection {
  section: string
  items: IExtra[]
}
interface ISectionsProps {
  extrasOptions: ISection[]
}

export const Sections: FunctionComponent<ISectionsProps> = props => {
  const [openSections, setOpenSections] = useState(new Set<string>())
  return (
    <>
      {props.extrasOptions.map((item, index) => (
        <Grid container className={`booking-details-section`} key={index}>
          <Grid
            item
            xs={12}
            className='booking-details-section__name'
            onClick={() => {
              if (openSections.has(item.section)) {
                openSections.delete(item.section)
                setOpenSections(new Set(openSections))
              } else {
                openSections.add(item.section)
                setOpenSections(new Set(openSections))
              }
            }}
          >
            {item.section}
            <span className='booking-details-section__toggle'>
              {openSections.has(item.section) ? '-' : '+'}
            </span>
          </Grid>
          <Grid
            container
            item
            className={`${openSections.has(item.section) ? '' : 'booking-details-section--hidden'}`}
          >
            {item.items.map(item => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={item.code}>
                  <Item key={item.code} item={item} />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      ))}
    </>
  )
}
