import {observer} from 'mobx-react-lite'
import React from 'react'
import {ReactComponent as Amex} from '../../assets/img/cards/amex.svg'
import {ReactComponent as Diners} from '../../assets/img/cards/diners.svg'
import {ReactComponent as Master} from '../../assets/img/cards/master.svg'
import {ReactComponent as Union} from '../../assets/img/cards/union.svg'
import {ReactComponent as Visa} from '../../assets/img/cards/visa.svg'
import {SvgProps} from '../../domain/models/common'
import './CardCharges.scss'
import { IPaymentOptionMetadata } from '../../domain/services/MetadataService'
import { formatMoney } from '../../domain/helpers/number-formatters'

export interface ICardChargesProps {
  selected?: string,
  paymentOptionMetadata: IPaymentOptionMetadata
}

type CreditCardType = 'visa' | 'master' | 'amexx' | 'diners' | 'union'
const cardIconMap: {[key in CreditCardType]: React.FunctionComponent<SvgProps>} = {
  "visa": Visa,
  "master": Master,
  "amexx": Amex,
  "diners": Diners,
  "union": Union
}

const CardCharges = ({selected, paymentOptionMetadata}: ICardChargesProps) => {
  const getClassName = (current: CreditCardType) => {
    if (!selected) return ''
    return current.toUpperCase() === selected.toUpperCase()
      ? 'card-charges-selected'
      : 'card-charges-muted'
  }

  const surchargeRateTable = Object.entries(paymentOptionMetadata.surcharges!).map(([cardType, rate]) => ({
    cardType: cardType as CreditCardType,
    rate: rate,
    icon: cardIconMap[cardType as CreditCardType]
  }))

  return (
    <div className='surcharge-rate'>
      <div className='card-charges'>
        <div className='card-charges__line'>
          {surchargeRateTable.map(item => (
            <div key={item.cardType} className='card-charges__icon'>
              <item.icon className={getClassName(item.cardType)} />
            </div>
          ))}
        </div>
        <div className='card-charges__line'>
          {surchargeRateTable.map((item) => item.rate > 0 && (
            <div key={item.cardType} className='card-charges__icon'>
              {`${formatMoney(item.rate)}%`}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default observer(CardCharges)
