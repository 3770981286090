import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'
import { isBunkPartner } from '../../domain/helpers/partner-helpers'
import { termsConditionsLinkFor } from '../../domain/services/MetadataService'

const TravelRestrictionsPolicy = (params: IImportantInfoSection) => {
  if (isBunkPartner(params.partnerCode)) {
    if (params.locale === 'en-GB') {
      return BunkTravelRestrictionsPolicyEN(params)
    } else {
      return BunkTravelRestrictionsPolicyDE(params)
    }
  } else {
    if (params.locale === 'en-GB') {
      return DefaultTravelRestrictionsPolicyEN(params)
    } else {
      return DefaultTravelRestrictionsPolicyDE(params)
    }
  }
}

const DefaultTravelRestrictionsPolicyEN = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Travel Restrictions</h3>
    <p>
      Not all RVs are suitable for all driving situations, so it's important to choose the right RV
      for your journey. At our discretion, we may restrict RV travel in certain areas due to
      adverse road or weather conditions, and the distance to nominated destinations in relation to
      the length of the rental period.
      
      Please review the <strong>Travel Restrictions</strong> section in our <a href={termsConditionsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        Terms &amp; Conditions
      </a> prior to booking.
    </p>
  </div>
)

const DefaultTravelRestrictionsPolicyDE = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Nutzung des Fahrzeuges</h3>
    <p>
      Nicht alle Wohnmobile sind für alle Reisesituationen geeignet, daher ist es wichtig, den richtigen
      Fahrzeugtyp für Ihre Reise zu wählen. Apollo kann nach eigenem Ermessen die Fahrt in bestimmte
      Gebiete aufgrund ungünstiger Straßen- oder Wetterbedingungen und der Entfernung zu den angegebenen
      Zielen im Verhältnis zur Mietdauer einschränken. Bitte lesen Sie vor der Buchung <a href={termsConditionsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        unsere AGB's durch - Verbotene Nutzung des Fahrzeuges
      </a>.
    </p>
  </div>
)

const BunkTravelRestrictionsPolicyEN = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Travel Restrictions</h3>
    <p>
      Not all motorhomes are suitable for all driving situations, so it's important to choose the right
      vehicle type for your journey. Bunk Campers, at its discretion may restrict travel in certain areas
      due to adverse road or weather conditions, and the distance to nominated destinations in relation
      to the length of the rental period.
      Please review our <a href={termsConditionsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        Terms and Conditions - Prohibited Use of the Vehicle
      </a> section prior to booking.
    </p>
  </div>
)

const BunkTravelRestrictionsPolicyDE = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Nutzung des Fahrzeuges</h3>
    <p>
      Nicht alle Wohnmobile sind für alle Reisesituationen geeignet, daher ist es wichtig, den richtigen
      Fahrzeugtyp für Ihre Reise zu wählen. Bunk Campers kann nach eigenem Ermessen die Fahrt in bestimmte
      Gebiete aufgrund ungünstiger Straßen- oder Wetterbedingungen und der Entfernung zu den angegebenen
      Zielen im Verhältnis zur Mietdauer einschränken. Bitte lesen Sie vor der Buchung <a href={termsConditionsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        unsere AGB's durch - Verbotene Nutzung des Fahrzeuges
      </a>.
    </p>
  </div>
)

export default TravelRestrictionsPolicy
