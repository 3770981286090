import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'
import { termsConditionsLinkFor } from '../../domain/services/MetadataService'

const Restricted4WDPolicy = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Restricted 4WD Access</h3>
    <p>
      Our 4WD RVs are ready to take adventurous travelers off the beaten track, however for your
      safety there are areas from which our vehicles are restricted and/or require written
      permission from Apollo prior to travel.
    </p>
    <p>
      Please review the <strong>Travel Restrictions</strong> section in our <a href={termsConditionsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        Terms &amp; Conditions
      </a> prior to booking.
    </p>
    <p>
      Please note: Our 4WD RVs are unsuitable for beach driving.
    </p>
  </div>
)

export default Restricted4WDPolicy
