import {Grid} from '@mui/material'
import React, {ChangeEvent, FunctionComponent, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import './GuestDetails.scss'
import { BasicContactInformation } from '../../domain/models/search-models'
import { requiresMembershipNumber } from '../../domain/helpers/partner-helpers'
import { validateMembershipNumber } from '../../domain/services/AffiliateMemberNumberValidationService'
import classNames from 'classnames'
import { Hidden } from '../Hidden'

const Label: FunctionComponent<{htmlFor: string; className?: string}> = props => (
  <label htmlFor={props.htmlFor} className={props.className}>
    {props.children}
    <span className='astrics'>&#42;</span>
  </label>
)

interface IContactDetailProps{
  formState: BasicContactInformation
  partnerCode: string | undefined
  onChange: (key: string, value: string) => void
}

const ContactDetails: FunctionComponent<IContactDetailProps> = (props)  => {
  const {formState, onChange, partnerCode} = props
  const [membershipNumberValid, setMembershipValid] = useState(true)

  const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.name, event.target.value)
  }

  const handleMembershipNumberChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    if (!membershipNumberValid) validateMembershipNumberField(event.target.value)
    handleChange(event)
  }

  const membershipNumberFieldLostFocus = (event: any) => {
    validateMembershipNumberField(event.target.value)
  }

  const validateMembershipNumberField = (value: string) => {
    const isSkippedOrValid = !partnerCode || validateMembershipNumber(partnerCode, value)
    setMembershipValid(isSkippedOrValid)
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <Label htmlFor='title'>
          <FormattedMessage id='guest-details.title' />
        </Label>
        <select name='title' id='title' onChange={handleChange} value={formState.title}>
          <FormattedMessage id='guest-details.title-mr'>
            {t => <option value='Mr'>{t}</option>}
          </FormattedMessage>
          <FormattedMessage id='guest-details.title-mrs'>
            {t => <option value='Ms'>{t}</option>}
          </FormattedMessage>
          <FormattedMessage id='guest-details.title-dr'>
            {t => <option value='Dr'>{t}</option>}
          </FormattedMessage>
        </select>
      </Grid>
      <Hidden smDown>
        <Grid item xs={6} ></Grid>
      </Hidden>
      <Grid xs={12} md={6} item>
        <div>
          <FormattedMessage id='guest-details.first-name'>
            {(t) => (
              <>
                <Label htmlFor='firstName'>{t}</Label>
                <input
                  name='firstName'
                  type='text'
                  id='firstName'
                  placeholder={t as string}
                  onChange={handleChange}
                  value={formState.firstName}
                />
              </>
            )}
          </FormattedMessage>
        </div>
      </Grid>
      <Grid xs={12} md={6} item>
        <div>
          <FormattedMessage id='guest-details.last-name'>
            {(t) => (
              <>
                <Label htmlFor='lastName'>{t}</Label>
                <input
                  name='surname'
                  type='text'
                  id='lastName'
                  placeholder={t as string}
                  onChange={handleChange}
                  value={formState.surname}
                />
              </>
            )}
          </FormattedMessage>
        </div>
      </Grid>
      <Grid xs={12} md={6} item>
        <div>
          <FormattedMessage id='guest-details.email-address'>
            {(t) => (
              <>
                <Label htmlFor='email'>{t}</Label>
                <input
                  name='email'
                  type='text'
                  id='email'
                  placeholder={t as string}
                  onChange={handleChange}
                  value={formState.email}
                />
              </>
            )}
          </FormattedMessage>
        </div>
      </Grid>
      <Grid xs={12} md={6} item>
        <div>
          <FormattedMessage id='guest-details.confirm-email'>
            {(t) => (
              <>
                <Label htmlFor='confirmEmail'>{t}</Label>
                <input
                  name='confirmEmail'
                  type='text'
                  id='confirmEmail'
                  placeholder={t as string}
                  onChange={handleChange}
                  value={formState.confirmEmail}
                />
              </>
            )}
          </FormattedMessage>
        </div>
      </Grid>
      
      <Grid xs={12} md={6} item>
        <div>
          <FormattedMessage id='guest-details.phone-number'>
            {(t) => (
              <>
                <Label htmlFor='phoneNumber'>{t}</Label>
                <input
                  name='telephone'
                  type='text'
                  id='phoneNumber'
                  placeholder={t as string}
                  onChange={handleChange}
                  value={formState.telephone}
                />
              </>
            )}
          </FormattedMessage>
        </div>
      </Grid>

      {partnerCode && requiresMembershipNumber(partnerCode) && (
        <Grid xs={12} md={6} item>
          <div>
            <FormattedMessage id='guest-details.membership-number'>
              {(t) => (
                <>
                  <Label htmlFor='membershipNumber'>{t}</Label>
                  <input
                    name='membershipNumber'
                    type='text'
                    id='membershipNumber'
                    className={classNames({"invalid": !membershipNumberValid})}
                    placeholder={t as string}
                    onChange={handleMembershipNumberChange}
                    onBlur={membershipNumberFieldLostFocus}
                    value={formState.membershipNumber}
                  />
                </>
              )}
            </FormattedMessage>

            {
              !membershipNumberValid && (
                <div className="validation-text">
                  <FormattedMessage id="guest-details.membership-number.not-valid" />
                </div>
              )
            }
          </div>
        </Grid>
      )}
    </>
  )
}

export default ContactDetails
