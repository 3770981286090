import React, {createContext} from 'react'
import {IntlProvider} from 'react-intl'
import {Redirect, Route, Switch} from 'react-router'
import {BrowserRouter, RouteComponentProps} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import './App.scss'
import {CoverAndExtras} from './components/BookingDetails/CoverAndExtras'
import BookingPayment from './components/BookingPayment/BookingPayment'
import PaymentConfirmation from './components/BookingPayment/PaymentConfirmation'
import {TokenisationSuccess} from './components/BookingPayment/TokenisationSuccess'
import {BookingSearchResults} from './components/BookingSearchResults/BookingSearchResults'
import GuestDetails from './components/GuestDetails/GuestDetails'
import {WorkTracker} from './components/LoadingPanel/work-tracker'
import {BookingStore} from './domain/stores/booking-store'
import { EditSearchStore } from './domain/stores/edit-search-store'
import {PaymentStore} from './domain/stores/payment-store'
import {SearchStore} from './domain/stores/search-store'

import './styles/_brandHelpers.scss'

import EnglishTranslation from './assets/translations/en.json'
import GermanTranslation from './assets/translations/de.json'
import { SupportedLocale } from './domain/models/search-models'
import { currentLocale, setCurrentLocale } from './domain/services/MetadataService'

const tracker = new WorkTracker({})
export const BookingStoreContext = createContext(new BookingStore(tracker))
export const SearchStoreContext = createContext(new SearchStore(new WorkTracker({})))
export const PaymentStoreContext = createContext(new PaymentStore(tracker))
export const EditSearchStoreContext = createContext(new EditSearchStore())

interface LocaleParameters {
  locale?: SupportedLocale
}

interface LocaleWrapperProps extends RouteComponentProps<LocaleParameters> {}

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Route path="/:locale(en-GB|de-DE)?" component={LocaleWrapper}></Route>
    </BrowserRouter>
  )
}

const LocaleWrapper = (props: LocaleWrapperProps) => {
  const translations = {
    'en-GB': EnglishTranslation,
    'de-DE': GermanTranslation,
  }

  setCurrentLocale(props.match.params.locale)
  const locale = currentLocale()

  return (
    <IntlProvider locale={locale} messages={translations[locale]} onError={() => {}}>
      <ToastContainer />
        <Switch>
          <Route
            path={`${props.match.path}/search/:partner_code/:country/:vehicle_type/:pick_up_location/:drop_off_location/:pick_up_date/:drop_off_date/:drivers_licence/:adults/:children/:driver_age/:promo_code?/`}
            component={BookingSearchResults}
          />
          <Route
            path={`${props.match.path}/booking/:partner_code/:country/:vehicle_type/:pick_up_location/:drop_off_location/:pick_up_date/:drop_off_date/:drivers_licence/:adults/:children/:driver_age/:vehicle_code/:rate_plan_code/`}
            component={CoverAndExtras}
          />
          <Route path={`${props.match.path}/guest/`} component={GuestDetails} />
          <Route
            path={`${props.match.path}/payment/success/:reservation_number/:surname/`}
            component={TokenisationSuccess}
          />
          <Redirect // TODO: make sure that the server redirect the locale back
            from={`${props.match.path}/payment/fail/:reservationNumber/:lastName`}
            to={`${props.match.path}/payment/:reservationNumber/:lastName/?paymentError=true`}
          />

          <Route path={`${props.match.path}/payment/confirmation/:reservationNumber/:lastName`} component={PaymentConfirmation} />
          <Route path={`${props.match.path}/payment/:reservationNumber/:lastName`} component={BookingPayment} />

          <Route
            component={() => {
              window.location.href = 'https://www.apollocamper.com/'
              return null
            }}
          />
        </Switch>
    </IntlProvider>
  );
}

export default App
