export interface IErrorResponseDetail {
  type?: string,
  detail?: string,
  status?: number
}

export interface IApiResult<T> {
  error?: IErrorResponseDetail,
  data?: T
}

export const ApiResult = <T>(result: {data?: T, error?: IErrorResponseDetail}): IApiResult<T> => {
  return {
    error: result.error,
    data: result.data
  }
}
