import {action, observable} from 'mobx'
import {Charge} from '../../components/BookingSearchResults/DailyRates'
import {WorkTracker} from '../../components/LoadingPanel/work-tracker'
import {MandatoryFee, RentalActivityBase, RentalCharge, FeeProductInfo} from '../models/api-models'
import {LocalisedVehicleMeta, VendorCode} from '../models/search-models'
import {BookingSearchResultItem, getProductAlternatives} from '../services/Api'
import {ancillaryMetadataFor, vehicleMetadataFor} from '../services/MetadataService'
import {SearchStore} from './search-store'
import {defineMessages} from 'react-intl'
import { processRentalChargeSummaryLines, processProductPricingSummaryLine } from '../models/bookingSummaryModel'
import { calculatePossibleEarnRatesFrom } from '../services/LoyaltyPointsService'
import { IAnalyticsProduct } from '../../utils/analytics'

const messages = defineMessages({
  loadingMessage: {
    id: 'product.get.alternatives.loading.message'
  },
})

export interface IAlternateResult {
  code: string
  name: string
  currency: string
  ratePlanCode: string
  pickupDepotCode: string
  pickupDepotName: string
  dropOffDepotCode: string
  dropOffDepotName: string
  pickupDateTime: Date
  dropOffDateTime: Date
  vendorCode: string
  total: number
  charges: Charge[]
}

export interface Vehicle {
  isAvailable: boolean
  berth: number
  images: string[]
  rating: number
  ratingCount: number
  vendorName: string
  vendorCode: VendorCode
  overviewUrl: string
  seatingCapacity: {
    total: number
    child: number
    infant: number
  }
  sleepingCapacity: {
    adult: number
    child: number
  }
  code: VendorCode
  ratePlanCode: string
  name: string
  children?: number
  currency: string
  total: number
  meta: LocalisedVehicleMeta
}

export class SelectableProduct {
  public product: Product
  public index: number

  constructor(private readonly _product: Product, private readonly _index: number) {
    this.product = _product
    this.index = _index
  }

  toAnalyticsProduct(): IAnalyticsProduct {
    return {
      list_position: this.index,
      code: this.product.info.code,
      name: this.product.info.code,
      vendor: this.product.info.vendorCode,
      total: this.product.info.total,
    }
  }
}

export const productLabelFor = (product: FeeProductInfo, rentalActivity: RentalActivityBase): string => {
  if (product.code === "SPECDISC")
    return rentalActivity.ratePlan.name || product.name || ''
  else
    return ancillaryMetadataFor(product.code, rentalActivity.rental.startDepot?.country?.code).displayName || product.name || '';
}

export const getDailyChargesFor = (rentalActivity: RentalActivityBase): Charge[] => {
  const rentalCharges = (rentalActivity.rental.charges || [])
    .map(charge => toCharge(charge, rentalActivity))

  const feeCharges = (rentalActivity.ancillaries.fees || [])
    .map(fee => feesToCharge(fee, rentalActivity))
    
  return [...rentalCharges, ...feeCharges].filter((c: Charge | null) => c !== null) as Charge[]
}

const toCharge = (item: RentalCharge, rentalActivity: RentalActivityBase): Charge => {
  return {
    price: item.totalPrice,
    description: productLabelFor(item.product!, rentalActivity),
    rates: processRentalChargeSummaryLines(item, rentalActivity.rental.startDepot?.country?.code)
  }
}

const feesToCharge = (item: MandatoryFee, rentalActivity: RentalActivityBase): Charge | null => {
  if (!item || !item.price || !item.product || !item.product.name || item.price.amount <= 0)
    return null

  return {
    price: item.price,
    description: productLabelFor(item.product, rentalActivity),
    rates: [processProductPricingSummaryLine(item, rentalActivity.rental.startDepot?.country?.code)]
  }
}

export class Product {
  @observable public alternatives: IAlternateResult[] | null = null
  public info: Vehicle
  public code: string
  public readonly tracker: WorkTracker

  private _calculatedLoyaltyPoints: {[provider: string]: number} | null = null

  constructor(private readonly store: SearchStore, private readonly item: BookingSearchResultItem) {
    this.info = this.toVehicle(item)
    this.code = this.info.code
    this.tracker = store.tracker
  }

  @action async getAlternatives() {
    if (this.alternatives) return

    const params = {
      ...this.store.searchParameters!,
      vehicle_code: this.info.code,
      rate_plan_code: this.info.ratePlanCode,
    }
    const result = await this.tracker.track(getProductAlternatives(params), {
      message: messages.loadingMessage,
      analyticsKey: 'search_alternatives'
    })

    this.alternatives = result.results.map(this.toAlternatives)
  }

  public getDailyCharges(): Charge[] {
    return getDailyChargesFor(this.item.result)
  }

  public canEarnLoyaltyPoints() {
    const points = this.loyaltyProgramEarnValues()
    return this.info.isAvailable && points && Object.values(points).some(v => v > 0)
  }

  public loyaltyProgramEarnValues() {
    if (!this._calculatedLoyaltyPoints)
      this._calculatedLoyaltyPoints = calculatePossibleEarnRatesFrom(this.item.result)

    return this._calculatedLoyaltyPoints
  }

  private toVehicle = (i: BookingSearchResultItem) => {
    let item = i.result
    const currency = item.rental.totalPrice?.currency
    let product = item.rental.product!
    let vehicle: Vehicle = {
      berth: product.sleeping?.adult || 0,
      code: product.code as VendorCode,
      ratePlanCode: item.ratePlan.code,
      images: [],
      meta: vehicleMetadataFor(product.code),
      name: product.name || '',
      sleepingCapacity: {
        adult: product.sleeping?.adult || 0,
        child: product.sleeping?.child || 0,
      },
      vendorCode: item.vendor.code as VendorCode,
      rating: 9.1, // TODO: add to api
      overviewUrl: product.productOverviewUrl || '',
      ratingCount: 123, // TODO: add to api
      seatingCapacity: {
        total: product.seating?.total || 0,
        child: product.seating?.restraintsChild || 0,
        infant: product.seating?.restraintsInfant || 0
      },
      vendorName: item.vendor.name || '',
      currency: currency || '',
      total: i.total,
      isAvailable: item.inventoryStatus === 'Yes',
    }
    return vehicle
  }

  private toAlternatives = (item: BookingSearchResultItem): IAlternateResult => {
    const result = item.result
    const rental = result.rental
    const endDepot = rental.endDepot!
    const startDepot = rental.startDepot!
    return {
      code: this.info.code,
      name: this.info.name,
      vendorCode: this.info.vendorCode,
      currency: rental.totalPrice!.currency,
      ratePlanCode: this.info.ratePlanCode,
      dropOffDateTime: rental.endDateTime,
      pickupDateTime: rental.startDateTime,
      dropOffDepotCode: endDepot.code,
      dropOffDepotName: endDepot.name || '',
      pickupDepotCode: startDepot.code,
      pickupDepotName: startDepot.name || '',
      charges: getDailyChargesFor(result),
      total: item.total,
    }
  }
}
