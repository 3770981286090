import {observer} from 'mobx-react-lite'
import * as React from 'react'
import './LoadingPanel.scss'
import {WorkTracker} from './work-tracker'
import {useIntl} from 'react-intl'

export interface ILoadingPanelProps {
  small?:boolean
  workTracker: WorkTracker
  children?: React.ReactNode
  color?: 'light' | 'dark'
}

const LoadingPanel = observer((props: ILoadingPanelProps) => {
  const intl = useIntl()
  const message = props.workTracker.message ? intl.formatMessage(props.workTracker.message) : ''

  return (
    <div
      className={`${props.small?'small-loading-pane':'loading-pane'} ${props.workTracker.isComplete ? 'lp-loaded' : 'lp-loading'}
    lp-${props.color || 'dark'}
    ${!props.children ? 'lp-fill' : ''}
    `}
    >
      <div className='lp-spinner'>
        <div className='lp-spinner-icon' />
      </div>
      <div className='lp-message'>{message}</div>
      <div className='lp-overlay' />
      <div className='lp-content'>{props.children}</div>
    </div>
  )
})

export default LoadingPanel
