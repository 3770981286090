import {Slider, useMediaQuery, useTheme} from '@mui/material'
import {observer} from 'mobx-react-lite'
import React, {useContext, useState} from 'react'
import {SearchStoreContext} from '../../App'
import human from '../../assets/img/human.svg'
import {formatVendor} from '../../domain/helpers/enum-formatters'
import './BookingSearchFilter.scss'
import { FormattedMessage } from 'react-intl'
import { trackEvent } from '../../utils/analytics'

const sequentialDropdownOptions = (max: number) => {
  const results = []

  for (var counter = 0; counter <= max; counter++) {
    results.push(<option key={counter} value={counter}>{counter}</option>)
  }

  return results
}

const BookingSearchFilter = () => {
  const isMd = useMediaQuery(useTheme().breakpoints.down('md'), {noSsr: true})
  const [contentHidden, setContentHidden] = useState(isMd)

  const store = useContext(SearchStoreContext)

  const togglePanelExpansion = () => {
    const shouldExpand = contentHidden
    setContentHidden(!shouldExpand)

    if (shouldExpand) trackEvent("filter_results_expanded")
  }

  return (
    <div className='booking-search-filter'>
      <div
        className='booking-search-filter__title'
        onClick={() => {
          if (isMd || contentHidden) togglePanelExpansion()
        }}
      >
        <FormattedMessage id={`headers.search_filter.header`} />
      </div>
      {!contentHidden && (
        <>
          <div id='vehicle-feature-filters'>
            <div className='booking-search-filter__group'></div>
            <div className='select-apollo'>
              <div className='select-apollo__img'>
                <img alt='human' src={human} />
              </div>
              <div>
                <div className='select-apollo__sleeps'>
                  <FormattedMessage id={`headers.search_filter.min_adults`} />
                </div>
                <select
                  className='select-apollo__options'
                  value={store.selectedSleepsAdults || 1}
                  id='sleepFilterSelect'
                  onChange={e => store.updateSleepsAdultsFilter(e.target.value)}
                >
                  {
                    [1, 2, 3, 4, 5, 6].map(i => (
                      <option key={`adults-${i}`} value={i}>{i}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            {
              store.maximumPossibleChildren > 0 && (
                <div className='select-apollo'>
                  <div className='select-apollo__img' style={{textAlign: "center"}}>
                    <img alt='human' src={human} style={{width: "80%"}} />
                  </div>
                  <div>
                    <div className='select-apollo__sleeps'>
                      <FormattedMessage id={`headers.search_filter.min_children`} />
                    </div>
                    <select
                      className='select-apollo__options'
                      value={store.selectedSleepsChildren || '0'}
                      id='childrenSleepFilterSelect'
                      onChange={e => store.updateSleepsChildrenFilter(e.target.value)}
                    >
                      {sequentialDropdownOptions(store.maximumPossibleChildren)}
                    </select>
                  </div>
                </div>
              )
            }

            {
              store.maximumPossibleInfants > 0 && (
                <div className='select-apollo'>
                  <div className='select-apollo__img' style={{textAlign: "center"}}>
                    <img alt='human' src={human} style={{width: "50%"}} />
                  </div>
                  <div>
                    <div className='select-apollo__sleeps'>
                      <FormattedMessage id={`headers.search_filter.min_infants`} />
                    </div>
                    <select
                      className='select-apollo__options'
                      value={store.selectedSleepsInfants || '0'}
                      id='infantsSleepFilterSelect'
                      onChange={e => store.updateSleepsInfantsFilter(e.target.value)}
                    >
                      {sequentialDropdownOptions(store.maximumPossibleInfants)}
                    </select>
                  </div>
                </div>
              )
            }
          </div>

          <div id='vehicle-feature-filters'>
            <div className='booking-search-filter__group'>
              <div className='checkbox u-margin-top-med'>
                <input
                  className='booking-search-filter__checkbox'
                  type='checkbox'
                  id='hideUnavailableFilter'
                  value='Hide'
                  checked={store.hideUnavailableFilter}
                  onChange={() => store.toggleHideUnavailableVehiclesFilter()}
                />
                <label htmlFor='hideUnavailableFilter' className='booking-search-filter__label'>
                  <FormattedMessage id={`headers.search_filter.available_vehicles_only`} />
                </label>
              </div>
            </div>
          </div>

          <div id='vehicle-feature-filters'>
            <div className='booking-search-filter__group'>
              <FormattedMessage id={`headers.search_filter.price_range`} />
            </div>
            {
              <Slider
                style={{color: '#f5812a'}}
                min={store.filterPrice?.[0]}
                max={store.filterPrice?.[1]}
                value={store.selectedPriceRangeFilter?.slice() || [0, 1]}
                valueLabelDisplay='auto'
                onChange={(_e, v) => store.updatePriceRangeFilter(v)}
              />
            }
          </div>

          <div id='vehicle-feature-filters'>
            <div className='booking-search-filter__group'>
              <FormattedMessage id={`headers.search_filter.features`} />
            </div>
            <div className='booking-search-filter__checkbox-group'>
              {store.searchFilters.map(c => (
                <div key={c} className='checkbox u-margin-top-med'>
                  <input
                    className='booking-search-filter__checkbox'
                    type='checkbox'
                    id={c}
                    checked={store.getFilterState(c)}
                    value={c}
                    onChange={_e => {
                      store.updateSearchFilter(c, !store.getFilterState(c))
                    }}
                  />
                  <FormattedMessage id={`vehicle.features.${c}`}>
                    {(translatedFeatureLabel) => (
                      <label htmlFor={c} className='booking-search-filter__label'>
                        {translatedFeatureLabel}
                      </label>
                    )}
                  </FormattedMessage>
                </div>
              ))}
            </div>
          </div>

          <div id='vehicle-feature-filters'>
            <div className='booking-search-filter__group'>
              <FormattedMessage id={`headers.search_filter.brands`} />
            </div>
            {store.availableVendorCodes.map(v => (
              <div key={v} className='checkbox u-margin-top-med'>
                <input
                  className='booking-search-filter__checkbox'
                  type='checkbox'
                  checked={store.getFilterBrandState(v)}
                  id={v.toLowerCase()}
                  value={formatVendor(v)}
                  onChange={e => {
                    store.updateSearchBrandFilter(v, e.target.checked)
                  }}
                />
                <label htmlFor={v.toLowerCase()} className='booking-search-filter__label'>
                  {formatVendor(v)}
                </label>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default observer(BookingSearchFilter)
