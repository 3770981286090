import { Grid } from '@mui/material'
import { useObserver } from 'mobx-react-lite'
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { BookingStoreContext, EditSearchStoreContext } from '../../App'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BookingParameters } from '../../domain/models/search-models'
import { VehicleLogo } from '../VehicleLogo/VehicleLogo'
import { BookingDetails } from './BookingDetails'
import './CoverAndExtras.scss'
import {CoverAndExtrasTabs} from './CoverAndExtrasTabs'
import {Covers} from './Covers'
import {ImportantInformationTab} from './ImportantInformationTab'
import {ChangeSearchParameterButton, PickupDropoffInfo} from './PickupDropoffInfo'
import {RentalDetailsTab} from './RentalDetailsTab'
import {Sections} from './Sections'
import {Tab} from './Tab'
import { FormattedMessage } from 'react-intl'
import { trackEvent } from '../../utils/analytics'
import { Hidden } from '../Hidden'

interface ICoverAndExtrasProps extends RouteComponentProps<BookingParameters> { }

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null
}

export const CoverAndExtras: FunctionComponent<ICoverAndExtrasProps> = props => {
  const location = useLocation()
  const store = useContext(BookingStoreContext)
  const editSearchStore = useContext(EditSearchStoreContext)

  useEffect(() => {
    const searchParams = props.match.params
    const queryParameters = new URLSearchParams(location.search)
    searchParams.relocation_id = queryParameters.get("relocation_id") || searchParams.relocation_id
    searchParams.hotdeal_id = queryParameters.get("deal_id") || searchParams.hotdeal_id
    searchParams.membership_number = queryParameters.get("membership_number") || searchParams.membership_number

    if (searchParams.relocation_id) trackEvent("viewed_product:relocation")
    else if (searchParams.hotdeal_id) trackEvent("viewed_product:hotdeal")
    else trackEvent("viewed_product")

    store.updateSearchParameters(searchParams).then(() => store.getAncillaryOptions())
  }, [store, props.match.params, location.search])

  const tabState = useState({
    index: 0,
    selected: 'cover-and-extras--bold',
    hidden: 'cover-and-extras--hidden',
  })

  const canEmailQuote = !!store.searchParameters
    && !store.searchParameters.relocation_id
    && !store.searchParameters.hotdeal_id
    && store.searchParameters.vehicle_type !== "CAR"

  return useObserver(() => {
    return (
      <div className={`page-content vendor_${store.selectedVendorCode} partner_${store.partner?.code}`}>
        <ScrollToTopOnMount />
        <BookingDetails
          tracker={store.tracker}
          bookingSummary={store.bookingSummary}
          pickupDropoffInfo={store.pickupDropoffInfo}
          showGoBackButton={true}
          showProceedAndEmailQuote={true}
          showEmailQuote={canEmailQuote}
          step={1}
          afterInfoPane={
            <Grid item xs={12}>
              {
                store.anyWithLoyaltyPoints && (
                  <Grid item>
                    <p className="loyaltyProgramDisclaimerText">
                      <sup>&dagger;</sup>
                      <FormattedMessage id="messages.possible_loyalty_earn.disclaimer" />
                    </p>
                  </Grid>
                )
              }
            </Grid>
          }
        >
          {store.selectedSearchResult && (
            <>
              <Grid container alignItems='center' item xs={12} className='cover-and-extras__header'>
                <Tab header index={0} tabState={tabState}>
                  <Grid item xs={6} sm={3}>
                    <div className='cover-and-extras__rental-details'>
                      <FormattedMessage id='booking.summary.rental_detail' />
                      <ExpandMoreIcon />
                    </div>
                  </Grid>
                </Tab>
                <Tab header index={1} tabState={tabState}>
                  <Grid item xs={6} sm={3}>
                    <div className='cover-and-extras__important-info'>
                      <FormattedMessage id='booking.summary.important_information' />
                      <ExpandMoreIcon />
                    </div>
                  </Grid>
                </Tab>
              </Grid>
              <Hidden smDown>
                {store.pickupDropoffInfo && (
                  <Grid container item xs={12} className='cover-and-extras__pd-container'>
                    <PickupDropoffInfo {...store.pickupDropoffInfo} />
                    <ChangeSearchParameterButton bookingStore={store} editStore={editSearchStore} />
                  </Grid>
                )}
              </Hidden>
              <Grid container item xs={12} className='cover-and-extras__content'>
                <Grid item xs={12}>
                  <VehicleLogo vendorCode={store.selectedVendorCode} />
                </Grid>
                <Tab content index={0} tabState={tabState}>
                  <Grid item xs={12}>
                    <RentalDetailsTab {...store.rentalDetailsTab} />
                  </Grid>
                </Tab>
                <Tab content index={1} tabState={tabState}>
                  <Grid item xs={12}>
                    <ImportantInformationTab {...store.importantInfo} />
                  </Grid>
                </Tab>
                <Grid item xs={12}>
                  <CoverAndExtrasTabs
                    covers={<Covers coverOptions={store.coverOptions} />}
                    extras={<Sections extrasOptions={store.extras} />}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </BookingDetails>
      </div>
    )
  })
}
