import {Grid} from '@mui/material'
import React, {FunctionComponent} from 'react'
import './NameAndBerth.scss'
import {ReactComponent as Human} from '../../assets/img/human.svg'
import {VendorCode} from '../../domain/models/search-models'

interface INameAndBerthProps {
  name: string
  berth: number
  vendorCode: VendorCode
}
const ShowBerth: React.FunctionComponent<{n: number; vendor: string}> = props => {
  let rows = []
  for (var i = 0; i < props.n; i++) {
    rows.push(
      <Human key={i} className={`name-berth__human name-berth--icon-color--${props.vendor}`} />
    )
  }
  return <div className='name-berth__customer'>{rows}</div>
}
export const NameAndBerth: FunctionComponent<INameAndBerthProps> = props => {
  return (
    <Grid container item>
      <Grid item xs={12}>
        <div className='name-berth__name'>{props.name}</div>
      </Grid>
      <Grid item xs={12}>
        <ShowBerth n={props.berth} vendor={props.vendorCode} />
      </Grid>
    </Grid>
  )
}
