import Grid from '@mui/material/Grid'
import React, {FunctionComponent} from 'react'
import {VendorCode} from '../../domain/models/search-models'
import Image from '../Image/Image'
import * as VendorHelper from '../../domain/helpers/vendor-helpers';
import { FormattedMessage } from 'react-intl';

interface IVehicleLogoProps {
  vendorCode: VendorCode | undefined
  className?: string
  onClick?: (eevnt: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  header?: boolean
}

const VehicleBanner: React.FunctionComponent<{
  vendorCode: VendorCode
}> = props => {
  if (VendorHelper.isApolloEU(props.vendorCode)) return <FormattedMessage id="search-result.brand-banner.apollo_eu" />
  if (VendorHelper.isApollo(props.vendorCode)) return <FormattedMessage id="search-result.brand-banner.apollo" />
  if (VendorHelper.isCheapa(props.vendorCode)) return <FormattedMessage id="search-result.brand-banner.cheapa" />
  if (VendorHelper.isHippie(props.vendorCode)) return <FormattedMessage id="search-result.brand-banner.hippie" />
  if (VendorHelper.isStar(props.vendorCode)) return <FormattedMessage id="search-result.brand-banner.star" />
  if (VendorHelper.isBunk(props.vendorCode)) return <FormattedMessage id="search-result.brand-banner.bunk" />
  if (VendorHelper.isApolloCars(props.vendorCode)) return <FormattedMessage id="search-result.brand-banner.apollo_cars" />

  return <></>
}
export const VehicleLogo: FunctionComponent<IVehicleLogoProps> = props => {
  const apolloLogo = () => {
    return props.header
      ? '/images/brands/apollo-white.svg'
      : '/images/brands/apollo-color.svg'
  }
  const starLogo = () => {
    return props.header
      ? '/images/brands/star-rv-white.svg'
      : '/images/brands/star-rv-black.svg'
  }
  const cheapaLogo = () => {
    return '/images/brands/cheapa-campa.svg'
  }
  const hippieLogo = () => {
    return '/images/brands/hippie-camper.svg'
  }
  const bunkLogo = () => {
    return '/images/brands/bunk-campers.svg'
  }
  const vehicleLogo = () => {
    if (!props.vendorCode) return undefined

    if (VendorHelper.isApollo(props.vendorCode)) return apolloLogo()
    if (VendorHelper.isCheapa(props.vendorCode)) return cheapaLogo()
    if (VendorHelper.isHippie(props.vendorCode)) return hippieLogo()
    if (VendorHelper.isStar(props.vendorCode)) return starLogo()
    if (VendorHelper.isBunk(props.vendorCode)) return bunkLogo()
    if (VendorHelper.isApolloCars(props.vendorCode)) return apolloLogo()

    return undefined
  }

  const logoUrl = vehicleLogo()
  if (!logoUrl) return <></>

  return (
    <Grid container className='logo-brand'>
      <Grid item xs={12} sm={2} className='logo-brand__img'>
        <Image
          url={logoUrl}
          description={props.vendorCode?.toString()}
          defaultImage=''
          className={props.className}
          onClick={props.onClick}
        />
      </Grid>
      {props.header && props.vendorCode && (
        <Grid item xs={12} sm={9} className='logo-brand__banner'>
          <VehicleBanner vendorCode={props.vendorCode} />
        </Grid>
      )}
    </Grid>
  )
}
