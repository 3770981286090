import React from 'react'
import {config} from '../../domain/lib/config'

export interface IImageProps {
  url: string
  defaultImage: string
  description?: string
  className?: string
  onClick?: (eevnt: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
}

const Image = ({url, defaultImage, description, className, onClick}: IImageProps) => (
  <img
    src={`${config.imageServer}${url}`}
    onError={e => (e.currentTarget.src = defaultImage)}
    alt={description}
    title={description}
    onClick={onClick}
    className={className}
  />
)

export default Image
