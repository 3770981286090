export interface IBookingConfig {
  searchServiceUrl: string
  bookingServiceUrl: string
  vendorServiceUrl: string
  campStayUrl: string
  isDevelopment?: boolean
  hasOrange?: boolean
  imageServer: string
  qantasPointRedemptionApiKey?: string
  qantasPointRedemptionApiClientId?: string
}

export const config = (window as any).BookingConfig as IBookingConfig
