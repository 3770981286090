import { formatForUrl } from '../helpers/date-formatters'
import { configureUrlWithQueryFrom } from '../helpers/url-helpers'
import { SearchResultMatchDetail } from '../services/Api'
import { Product } from '../stores/product'
import { LocationPath } from '../stores/search-store'
import { SearchParameters } from './search-models'

export class SpecialSearchResultCandidate {
  private revisedSearchParameters: SearchParameters

  constructor(private searchParameters: SearchParameters, public specialDetail: SearchResultMatchDetail, public product: Product) {
    this.revisedSearchParameters = {
      ...this.searchParameters,
      pick_up_location: specialDetail.revisedStartLocationCode,
      pick_up_date: formatForUrl(specialDetail.revisedStartDateTime),
      drop_off_location: specialDetail.revisedEndLocationCode,
      drop_off_date: formatForUrl(specialDetail.revisedEndDateTime)
    }
  }

  public continuationUrl(): LocationPath {
    const deal = this.specialDetail.deal
    return configureUrlWithQueryFrom(this.revisedSearchParameters, deal.product.code, deal.ratePlan.code, {
      deal_identifier: `${deal.inventoryKey}:${deal.inventoryControlNumber}`
    })
  }
}