import ancillaryProductMetadata from '../../assets/ancillary-meta.json'
import defaultCoversMetadata from '../../assets/covers-meta-default.json'
import coversMetadataEn from '../../assets/covers-meta-en.json'
import coversMetadataDe from '../../assets/covers-meta-de.json'
import vehiclesMetadata from '../../assets/vehicles-meta.json'
import externalLinks from '../../assets/external-links.json'
import partnerVendorSortOrder from '../../assets/partner-vendor-sort-order.json'
import paymentOptions from '../../assets/payment-options.json'
import paymentDisclaimersMetadata from '../../assets/payment-disclaimers.json'
import countryTranslations from '../../assets/countries.json'
import applicableLoyaltySchemeMetadata from '../../assets/loyalty-programs.json'
import membershipNumberFormatMetadata from '../../assets/member-number-validations.json'
import preRegistrationRestrictionsMetadata from '../../assets/preregistration-requirements.json'

import {toDate} from '../helpers/date-formatters'

import moment from 'moment'
import 'moment/locale/de'

import {
  CoversMeta,
  Feature,
  VehicleMeta,
  SupportedLocale,
  CoverOptionMeta,
  LocalisedVehicleMeta,
  VendorCode
} from '../models/search-models'
import { CountryFilterableCandidate, PartnerFilterableCandidate, filterCandidatesByCountry, filterCandidatesByPartnerCode, filterCandidatesByLocale, DateFilterableCandidate, filterCandidatesByDateRange, LocaleFilterableCandidate, filterCandidatesByVendorCode, VendorFilterableCandidate, EnabledCandidate, filterCandidatesByVendorGroup, VendorGroupFilterableCandidate } from '../helpers/metadata-filtering-helpers'
import { LanguageIdentifier } from '../models/api-models'

let _explicitlySetLocale: string | undefined
export const explicitlySetLocale = (): string | undefined => _explicitlySetLocale
export const setCurrentLocale = (locale: string | undefined) => {
  moment.locale(locale || 'en-GB')
  _explicitlySetLocale = locale
}
export const currentLocale = (): SupportedLocale => {
  if (!_explicitlySetLocale) return 'en-GB'
  if (_explicitlySetLocale.toLowerCase() === 'en-gb') return 'en-GB'
  if (_explicitlySetLocale.toLowerCase() === 'de-de') return 'de-DE'
  return 'en-GB'
}
export const currentLanguagePreference = (): LanguageIdentifier => {
  const locale = currentLocale()
  if (locale === 'de-DE') return LanguageIdentifier.De

  return LanguageIdentifier.En
}

export const vehicleMetadataFor = (vehicleCode: string): LocalisedVehicleMeta => {
  const result = {
    code: vehicleCode,
    name: vehicleCode,
    moreDetails: 'https://www.apollocamper.com/vehicles',
    importantInfo: ['ChildRestraintPolicy', 'PetFriendlyPolicy', 'TravelRestrictions'],
    features: [] as Feature[],
  }

  const vehicle = vehiclesMetadata
    .find(v => v.code.toUpperCase() === vehicleCode.toUpperCase()) as VehicleMeta | undefined

  if (vehicle) {
    result.code = vehicle.code
    result.name = vehicle.name
    result.features = vehicle.features
    result.importantInfo = vehicle.importantInfo

    const moreDetailsLink = vehicle.moreDetails[currentLocale().toLowerCase()] || vehicle.moreDetails['en-gb']
    if (moreDetailsLink) result.moreDetails = moreDetailsLink
  }

  return result
}

const _partnerVendorSortOrder: {[key: string]: {sortOrder: string[]}} = partnerVendorSortOrder
export const partnerVendorSortOrderFor = (partnerCode: string | undefined): string[] => {
  if (partnerCode) {
    const byPartnerCode = _partnerVendorSortOrder[partnerCode.toUpperCase()]
    if (byPartnerCode) return byPartnerCode.sortOrder
  }

  return _partnerVendorSortOrder['_default'].sortOrder
}

export const coverMetadataFor = (countryCode: string, vendorCode: string, date: Date): CoverOptionMeta => {
  const coversMeta: CoversMeta[] = currentLocale() === "en-GB" ? coversMetadataEn : coversMetadataDe
  const fallback: CoverOptionMeta = {
    bondLabel: 'Refundable Bond',
    bondDisclaimer: defaultFallbackBondDisclaimer,
    coverOptions: defaultCoversMetadata
  }

  var country = coversMeta.find(data => data.country.toUpperCase() === countryCode.toUpperCase())
  if (!country || !country.vendors) return fallback
  fallback.bondLabel = country.bondLabel

  const vendor = country.vendors
    .find(data => data.vendorCode.toUpperCase() === vendorCode.toUpperCase())
  if (!vendor || !vendor.seasons) return fallback

  const season = vendor.seasons
    .find(data => date >= toDate(data.startDate) && (!data.endDate || date <= toDate(data.endDate)))
  if (!season) return fallback

  const coverOptions = season.coverOptions
  coverOptions.push(...defaultCoversMetadata.filter(m => !coverOptions.find(co => co.code === m.code)))

  return {
    bondLabel: fallback.bondLabel,
    bondDisclaimer: bondDisclaimerFrom(season.bondDisclaimer, country.bondDisclaimer),
    coverOptions: coverOptions.map(data => {
      return {
        ...data,
        bondLabel: data.bondLabel || fallback.bondLabel,
        bondDisclaimer: bondDisclaimerFrom(data.bondDisclaimer, season.bondDisclaimer, country!.bondDisclaimer),
      }
    })
  }
}

export enum LoyaltyProgramType {
  "QFF" = "QFF"
}

export interface ApplicableLoyaltyScheme extends DateFilterableCandidate, VendorFilterableCandidate {
  schemes: string[]
}

const applicableLoyaltySchemes: ApplicableLoyaltyScheme[] = applicableLoyaltySchemeMetadata
export const loyaltySchemesFor = (vendorCode: VendorCode | undefined, date: Date | undefined): LoyaltyProgramType[] => {
  if (!vendorCode) return []
  
  date = (date && toDate(date.toString())) || new Date()
  let candidates = filterCandidatesByDateRange(applicableLoyaltySchemes, date)
  candidates = filterCandidatesByVendorCode(candidates, vendorCode)
  return candidates.flatMap(c => c.schemes.map(s => s as LoyaltyProgramType))
}

export const defaultFallbackBondDisclaimer = "You have chosen {product}, therefore your {bondLabel} of {currency_amount} will be payable on pickup."
const bondDisclaimerFrom = (...candidates: (string | boolean | undefined)[]) => {
  const candidate = candidates.find((value) => value || value === false)
  if (candidate === false) return false
  if (candidate === true) return defaultFallbackBondDisclaimer
  return candidate || defaultFallbackBondDisclaimer
}

const _categoryOrder = [
  "camping_equipment",
  "safety_navigation",
  "transfers",
  "peace_of_mind",
  "baby_children",
  "essentials",
  "extras",
  "services"
]
export const categoryOrderFor = (category: string) => {
  return _categoryOrder.indexOf(category)
}

interface ProductTranslation extends CountryFilterableCandidate {
  displayName: string
  description: string
}

const _defaultProductCategory = ancillaryProductMetadata.categories.defaultProductCategory
const _productMapping: {[code: string]: any} = ancillaryProductMetadata.products
const _categoryTranslations: {[locale: string]: any} = ancillaryProductMetadata.categories.translations
export const ancillaryMetadataFor = (productCode: string, travelCountry: string | undefined) => {
  const locale = currentLocale()
  const product = _productMapping[productCode]
  const categoryKey = product?.category || _defaultProductCategory
  const categoryTranslation = _categoryTranslations[locale]
  const categoryLabel = categoryTranslation[categoryKey]

  const possibleProductTranslations: ProductTranslation[] | undefined = product?.translations && product.translations[locale]
  let productTranslation: ProductTranslation | undefined = undefined;

  if (possibleProductTranslations !== undefined) {
    if (travelCountry) {
      productTranslation = filterCandidatesByCountry(possibleProductTranslations, travelCountry)[0]
    }

    if (!productTranslation) {
      productTranslation = possibleProductTranslations.filter((item) => {
        return item.countries === undefined
      })[0]
    }
  }

  return {
    category: categoryLabel,
    displayName: productTranslation?.displayName,
    description: productTranslation?.description
  }
}

interface ExternalLinkDictionary {
  contact_us: ExternalLinkCandidate[],
  hotdeals: ExternalLinkCandidate[],
  terms_conditions: ExternalLinkCandidate[],
  child_restraints: ExternalLinkCandidate[],
  pet_friendly_policy: ExternalLinkCandidate[],
  wa_4wd_restrictions: ExternalLinkCandidate[],
  nt_4wd_restrictions: ExternalLinkCandidate[],
  preregistration: ExternalLinkCandidate[],
  enabledcandidate: ExternalLinkCandidate[]
}
interface ExternalLinkCandidate extends CountryFilterableCandidate, EnabledCandidate, PartnerFilterableCandidate, VendorGroupFilterableCandidate, LocaleFilterableCandidate {
  url: string
}

const externalLinkMetadata: ExternalLinkDictionary = externalLinks as ExternalLinkDictionary

export const viewHotDealsLinkFor = (partnerCode: string, country?: string): string => {
  return urlFrom(externalLinkMetadata.hotdeals, partnerCode, country)
}
export const contactUsLinkFor = (partnerCode: string, country?: string): string => {
  return urlFrom(externalLinkMetadata.contact_us, partnerCode, country)
}
export const termsConditionsLinkFor = (partnerCode: string, country?: string, vendorCode?: VendorCode): string => {
  return urlFrom(externalLinkMetadata.terms_conditions, partnerCode, country, vendorCode)
}
export const childRestraintsLinkFor = (partnerCode: string, country?: string, vendorCode?: VendorCode): string => {
  return urlFrom(externalLinkMetadata.child_restraints, partnerCode, country, vendorCode)
}
export const petFriendlyPolicyLinkFor = (partnerCode: string, country?: string, vendorCode?: VendorCode): string => {
  return urlFrom(externalLinkMetadata.pet_friendly_policy, partnerCode, country, vendorCode)
}
export const wa4WDRestrictionsLinkFor = (partnerCode: string, country?: string): string => {
  return urlFrom(externalLinkMetadata.wa_4wd_restrictions, partnerCode, country)
}
export const nt4WDRestrictionsLinkFor = (partnerCode: string, country?: string): string => {
  return urlFrom(externalLinkMetadata.nt_4wd_restrictions, partnerCode, country)
}
export const preRegistrationLinkTemplateFor = (partnerCode: string, country?: string): string => {
  return urlFrom(externalLinkMetadata.preregistration, partnerCode, country)
}

const urlFrom = (candidates: ExternalLinkCandidate[], partnerCode: string, country?: string, vendor?: VendorCode): string => {
  const initialCandidates = candidates

  candidates = filterCandidatesByPartnerCode(candidates, partnerCode)
  if (vendor) candidates = filterCandidatesByVendorGroup(candidates, vendor)
  if (country) candidates = filterCandidatesByCountry(candidates, country.toLowerCase())
  candidates = filterCandidatesByLocale(candidates, currentLocale())
  candidates = candidates.filter(c => c.enabled !== false)

  const candidate = candidates[0]
  if (candidate) return candidate.url

  console.log(`WARNING: No URL found for '${partnerCode}', '${country}', and '${vendor}'`)
  console.log("Initial collection:", initialCandidates)
  return ""
}

export interface IPaymentSectionMetadata {
  header: string
  upsells: string[]
  payLaterLines?: string[]
}
export interface ISurchargeRate {
  [cardType: string]: number
}
interface IPaymentOptionMessagesMetadata {
  bond: IPaymentSectionMetadata
  full: IPaymentSectionMetadata
}
interface IPaymentOptionsMetadata extends DateFilterableCandidate, CountryFilterableCandidate {
  surcharges?: ISurchargeRate
  messages: {[locale in SupportedLocale]: IPaymentOptionMessagesMetadata}
}
export interface IPaymentOptionMetadata {
  surcharges?: ISurchargeRate
  bond: IPaymentSectionMetadata
  full: IPaymentSectionMetadata
}

const paymentOptionsMetadata: IPaymentOptionsMetadata[] = paymentOptions as IPaymentOptionsMetadata[]
export const paymentOptionsMetadataFor = (country: string, travelDate: Date): IPaymentOptionMetadata => {
  let candidates = filterCandidatesByCountry(paymentOptionsMetadata, country)
  candidates = filterCandidatesByDateRange(candidates, toDate(travelDate.toString()))
  const candidate = candidates[0]

  return {
    surcharges: candidate.surcharges,
    ...candidate.messages[currentLocale()]
  }
}

const _countryNameTranslations: {[locale: string]: {[countryCode: string]: string}} = countryTranslations
export const countryNameFrom = (countryCode: string, locale: SupportedLocale): string | undefined => {
  return _countryNameTranslations[locale][countryCode]
}

interface IPaymentDisclaimerCandidate extends CountryFilterableCandidate, PartnerFilterableCandidate{
  key: string
}
const paymentDisclaimers: IPaymentDisclaimerCandidate[] = paymentDisclaimersMetadata as IPaymentDisclaimerCandidate[]
export const paymentDisclaimerKeysFor = (country: string, partnerCode: string): string[] => {
  let candidates = filterCandidatesByCountry(paymentDisclaimers, country)
  candidates = filterCandidatesByPartnerCode(candidates, partnerCode)
  
  return candidates.map(c => c.key)
}

export interface MembershipNumberValidationFormats extends PartnerFilterableCandidate {
  formats: string[]
}

const membershipNumberFormats: MembershipNumberValidationFormats[] = membershipNumberFormatMetadata
export const membershipNumberValidationFormatsFor = (partnerCode: string): RegExp[] => {
  let candidates = filterCandidatesByPartnerCode(membershipNumberFormats, partnerCode)
  return candidates.flatMap(c => c.formats.map(s => new RegExp(s)))
}

export interface PreRegistrationRestriction extends CountryFilterableCandidate {
  mandatory?: boolean
  visible?: boolean
}

const preRegistrationRestrictions: PreRegistrationRestriction[] = preRegistrationRestrictionsMetadata
export const requiresPreRegistration = (countryCode: string): boolean => {
  let candidates = filterCandidatesByCountry(preRegistrationRestrictions, countryCode)
  return candidates.some(c => !!c.mandatory)
}

export const canPreRegister = (countryCode: string): boolean => {
  let candidates = filterCandidatesByCountry(preRegistrationRestrictions, countryCode)
  return candidates.some(c => !!c.mandatory || !!c.visible)
}

