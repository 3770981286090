import { BookingStore } from "../stores/booking-store";

const payInFullProductCode = "PIF"

export const isApplied = (bookingStore: BookingStore): boolean => {
  return bookingStore.hasExtraApplied(payInFullProductCode)
}

export const canApply = (bookingStore: BookingStore): boolean => {
  return bookingStore.hasExtraAvailable(payInFullProductCode)
}

export const applyDiscount = async (bookingStore: BookingStore) => {
  await bookingStore.updateExtraCount(payInFullProductCode, 1)
}

export const removeDiscount = async (bookingStore: BookingStore) => {
  await bookingStore.updateExtraCount(payInFullProductCode, 0)
}

export const evaluatePayInFullDiscountPromotion = async (bookingStore: BookingStore) => {
  const shouldApply = canApply(bookingStore)
  const alreadyApplied = isApplied(bookingStore)

  if (shouldApply && !alreadyApplied) {
    await applyDiscount(bookingStore)
  } else if (alreadyApplied) {
    await removeDiscount(bookingStore)
  }
}
