import { Grid } from '@mui/material'
import React from "react"
import { FormattedMessage } from "react-intl"

interface IConfirmationPageSectionProps {
  headerKey: string
  children: React.ReactNode
}

export const ConfirmationPageSection = ({ headerKey, children }: IConfirmationPageSectionProps) => {
  return (
    <div className='booking-payment-option'>
      <Grid container spacing={1}>
        <Grid className='booking-payment-option__title' item>
          <FormattedMessage id={headerKey} />
        </Grid>

        <Grid container item spacing={3} xs={12} className='booking-payment-option__text'>
          {children}
        </Grid>
      </Grid>
    </div>
  )
}