import Grid from '@mui/material/Grid'
import React from 'react'
import './BookingPaymentOption.scss'
import CardCharges from './CardCharges'
import { formatMoney } from '../../domain/helpers/number-formatters'
import { paymentOptionsMetadataFor, IPaymentSectionMetadata, IPaymentOptionMetadata } from '../../domain/services/MetadataService'
import { formatDate } from '../../domain/helpers/date-formatters'

export type PaymentOptionType = FullPaymentOptionType | BondPaymentOptionType

export interface FullPaymentOptionType {
  type: 'full'
  totalRental: number
  payNow: number
  refundableBond: number
  currency: string
  payableAtPickup: number | undefined
}

export interface BondPaymentOptionType {
  type: 'bond'
  totalRental: number
  payNow: number
  balance: number
  balanceSurcharge?: number
  payLater: number
  refundableBond: number
  currency: string
  totalDueAt: Date | undefined
  payableAtPickup: number | undefined
}

interface IPaymentOptionProps {
  hideTitle?: boolean
  paymentOptionType: PaymentOptionType
  selected: boolean
  onPaymentSelected: (paymentOptionType: PaymentOptionType) => void
  country: string
  date: Date
  children?: any
}

const BookingPaymentOption = (props: IPaymentOptionProps) => {
  const paymentOptionMetadata = paymentOptionsMetadataFor(props.country, props.date)
  const sectionMessages: IPaymentSectionMetadata | undefined = paymentOptionMetadata && paymentOptionMetadata[props.paymentOptionType.type]

  return (
    <>
      <div className='booking-payment-option'>
        <Grid container>
          {props.children}
          <Grid className='booking-payment-option__title' item xs={11}>
            {sectionMessages?.header} <span className='astrics'>&#42;</span>
          </Grid>
          {props.hideTitle || (
            <Grid className='booking-payment-option__radio' xs={1} item>
              <input
                type='radio'
                value={props.paymentOptionType.type}
                name='paymentOption'
                checked={props.selected}
                onChange={() => props.onPaymentSelected(props.paymentOptionType)}
              />
            </Grid>
          )}
          <Grid container item xs={12} className='booking-payment-option__body'>
            {props.paymentOptionType.type === 'bond'
              ? renderBond(props.paymentOptionType, paymentOptionMetadata)
              : renderFullPayment(props.paymentOptionType, paymentOptionMetadata)}
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const renderFullPayment = (paymentOption: FullPaymentOptionType, metadata: IPaymentOptionMetadata) => (
  <>
    {renderLineItem(
      <ul className="booking-payment-option__option-upsell-list">
        {metadata.full.upsells.map(item => <li key={item} className="booking-payment-option__option-upsell">{item}</li>)}
      </ul>,    
      paymentOption.currency,
      paymentOption.payNow
    )}
  </>
)

const renderBond = (paymentOption: BondPaymentOptionType, metadata: IPaymentOptionMetadata) => (
  <>
    {renderLineItem(
      <ul className="booking-payment-option__option-upsell-list">
        {metadata.bond.upsells.map(item => <li key={item} className="booking-payment-option__option-upsell">{item}</li>)}
      </ul>,
      paymentOption.currency,
      paymentOption.payNow
    )}
    {paymentOption.balance > 0 && (
      <>
        <Grid item xs={12} className='booking-payment-option__group' />
        {renderLineItem(
          <ul className="booking-payment-option__option-payLater-list">
            {metadata.bond.payLaterLines!.map(item => (
              <li key={item} className="booking-payment-option__option-payLater">
                {formatPayLaterMessage(item, paymentOption, metadata)}
              </li>
            ))}
          </ul>,
          paymentOption.currency,
          paymentOption.payLater
        )}
      </>
    )}
    {metadata.surcharges && (
      <CardCharges paymentOptionMetadata={metadata} />
    )}
  </>
)

const formatPayLaterMessage = (line: string, paymentOption: BondPaymentOptionType, _metadata: IPaymentOptionMetadata): string => {
  let result = line;

  if (paymentOption.totalDueAt)
    result = result.replace("{dueDate}", formatDate(paymentOption.totalDueAt))

  return result
}

export const renderLineItem = (title: any, currency: string, amount?: number) => (
  <Grid container item xs={12} className='booking-payment-option__item' justifyContent='space-between'>
    <Grid className='booking-payment-option__item-title' item xs={9}>
      {title}
    </Grid>
    {amount && (
      <Grid className='booking-payment-option__item-amount' item xs={3}>
        <span className='currency'>{currency} </span>
        {formatMoney(amount)}
      </Grid>
    )}
  </Grid>
)
export default BookingPaymentOption
