import React from 'react'
import {FormattedMessage} from 'react-intl'
import { formatMoney } from '../../domain/helpers/number-formatters'

import styles from './ResultPrice.module.scss'

export interface IResultPriceProps {
  currency: string
  amount: number
  vendorCode: string
}

const ResultPrice = ({currency, amount, vendorCode}: IResultPriceProps) => (
  <div className={styles.price_text}>
    <div className={styles[vendorCode]}>
      <strong>
        <span className={styles.currency}>
          {currency}
        </span>
        <span className={styles.amount}>
          {formatMoney(amount)}
        </span>
      </strong>
    </div>
    <div className={styles.rental_only_charge}>
      <FormattedMessage id='search-result.rental-charge-only' />
    </div>
  </div>
)

export default ResultPrice
