import { Button, Grid } from '@mui/material'
import { useObserver } from 'mobx-react-lite'
import React, { useContext, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Redirect, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BookingStoreContext, PaymentStoreContext } from '../../App'
import { BookingDetails } from '../BookingDetails/BookingDetails'
import { PickupDropoffInfo } from '../BookingDetails/PickupDropoffInfo'
import Container from '../BookingPayment/Container'
import TermsAndConditions from '../BookingPayment/TermsAndConditions'
import EmailSubscription from '../BookingPayment/EmailSubscription'
import ContactDetails from './ContactDetails'
import './GuestDetails.scss'
import { BondPayableNotice } from '../BookingPayment/partner-components/BondPayableNotice'
import { paymentContinuationUrlFrom } from '../../domain/helpers/url-helpers'
import * as PayInFullPromotion from '../../domain/promotions/PayInFullPromotion'
import { PartnerMarketingKey } from '../../domain/helpers/partner-helpers'
import { AdditionalPaymentDisclaimers } from '../BookingPayment/AdditionalPaymentDisclaimers'
import { LoyaltySchemeDetails } from './LoyaltySchemeDetails'
import { GuestRegistrationDetail, ILoyaltyProgramMembershipDetail, VendorCode } from '../../domain/models/search-models'
import { useCallback } from 'react'
import { calculatePossibleEarnRatesFrom } from '../../domain/services/LoyaltyPointsService'
import EmailMeQuoteCheckbox from '../BookingPayment/EmailMeQuoteCheckbox'
import { BasicPreRegistrationSection } from '../BookingPayment/partner-components/BasicPreregistrationDialog'
import { canPreRegister, requiresPreRegistration } from '../../domain/services/MetadataService'
import { Hidden } from '../Hidden'

const ScrollToTopOnMount = () => {
  useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])
  return null
}

const GuestDetails = () => {
  const history = useHistory()
  const store = useContext(BookingStoreContext)
  const paymentStore = useContext(PaymentStoreContext)
  const intl = useIntl()

  const country = paymentStore.quotation?.rental.startDepot?.country?.code || store.rental?.startDepot?.country?.code
  const showRonaRegistrationSection = !!country && canPreRegister(country)
  const ronaRegistrationSectionMandatory = !!country && requiresPreRegistration(country)

  const [subscribedNewsletterKeys, setSubscribedNewsletterKeys] = useState<{[key: string]: boolean}>({})
  const [loyaltyNumberFormState, setLoyaltyNumberFormState] = useState<ILoyaltyProgramMembershipDetail>({})
  const [ronaRegistrationFormState, setRonaRegistrationFormState] = useState({} as GuestRegistrationDetail)
  const [errorMessage, setErrorMessage] = useState(' ')

  const handleChange = (key: string, value: any) => {
    store.setGuestDetail(key, value)
  }

  const handleNewsletterSignup = (partnerCampaign: PartnerMarketingKey, selected: boolean) => {
    setSubscribedNewsletterKeys({...subscribedNewsletterKeys, [partnerCampaign.listId]: selected})
  }

  const handleEmailQuoteChange = (checked: boolean) => {
    store.setEmailMeQuote(checked)
  }

  const handleLoyaltyMembershipDetailsChanged = (surname: string, providerDetails: ILoyaltyProgramMembershipDetail) => {
    if (!!surname && surname !== "")
      handleChange('surname', surname)

    setLoyaltyNumberFormState(providerDetails)
  }

  const messageFor = useCallback((key: string): string => {
    return intl.formatMessage({id: key})
  }, [intl])

  const validateFormState = useCallback((): boolean => {
    const requiredFields = ['email', 'firstName', 'telephone', 'surname', 'title', 'confirmEmail']

    if (Object.entries(store.guestDetails).some(e => requiredFields.includes(e[0]) && e[1] === '')) {
      setErrorMessage(messageFor('guest-details.validations.all-fields-required'))
      return false
    }

    if (store.guestDetails.email !== store.guestDetails.confirmEmail) {
      setErrorMessage(messageFor('guest-details.validations.confirm-email-mismatch'))
      return false
    }

    if (ronaRegistrationSectionMandatory)
    {
      if (!ronaRegistrationFormState.postcode) {
        setErrorMessage(messageFor('guest-details.validations.rona-fields-required'))
        return false
      }
    }

    setErrorMessage('')
    return true
  }, [messageFor, store, setErrorMessage, ronaRegistrationSectionMandatory, ronaRegistrationFormState])

  const onRonaRegistrationDetailsChanged = useCallback((details: GuestRegistrationDetail) => {
    setRonaRegistrationFormState(details)
  }, [setRonaRegistrationFormState])

  const createQuotation = useCallback(() => {
    if (!validateFormState()) return

    const selectedNewsletterKeys = Object.entries(subscribedNewsletterKeys).filter(e => e[1]).map(e => e[0])
    const details = {
      ...store.guestDetails,
      loyaltyProgramMembershipDetail: loyaltyNumberFormState
    }

    store
      .createQuotation(details, selectedNewsletterKeys, store.emailMeQuote, ronaRegistrationFormState)
      .then(async (result) => {
        if (result.data) {
          const quotation = result.data
          await paymentStore.setQuotation(quotation)

          // replace the /guest path with the "view quote" path for times when the back-button is hit
          history.replace(paymentContinuationUrlFrom(quotation.reservationNo, quotation.customer.surname))
        } else {
          toast.error(messageFor('guest-details.errors.quote-creation-failed'))
        }
      }, () => toast.error(messageFor('guest-details.errors.quote-creation-failed')))
  }, [validateFormState, subscribedNewsletterKeys, store, paymentStore, loyaltyNumberFormState, ronaRegistrationFormState, messageFor, history])

  useEffect(() => {
    paymentStore.unsetSelectedPaymentOption()
  }, [paymentStore, store.selectedSearchResult])

  return useObserver(() => {
    const partner = paymentStore.quotation?.partner || store.partner
    const vendor: VendorCode = (paymentStore.quotation?.vendor.code as VendorCode) || store.selectedVendorCode
    const quotation = paymentStore.quotation || store.selectedSearchResult || store.quotation
    const earnRates = quotation && calculatePossibleEarnRatesFrom(quotation)
    const showLoyaltySection = !!earnRates && Object.values(earnRates).some(v => v > 0)
    const bookingSummary = store.bookingSummary

    return store.searchParameters === null ? (
      <Redirect to='' />
    ) : (
      <div className={`page-content partner_${partner?.code}`}>
        <ScrollToTopOnMount />
        <BookingDetails
          tracker={store.tracker}
          bookingSummary={bookingSummary}
          pickupDropoffInfo={store.pickupDropoffInfo}
          showProceedAndEmailQuote={false}
          showGoBackButton={false}
          step={2}
          afterInfoPane={
            <Grid item xs={12}>
              {
                store.anyWithLoyaltyPoints && (
                  <Grid item>
                    <p className="loyaltyProgramDisclaimerText">
                      <sup>&dagger;</sup>
                      <FormattedMessage id="messages.possible_loyalty_earn.disclaimer" />
                    </p>
                  </Grid>
                )
              }
            </Grid>
          }
        >
          <Hidden smDown>
            {store.pickupDropoffInfo && <PickupDropoffInfo {...store.pickupDropoffInfo} />}
          </Hidden>
          <div className='booking-guest-details'>
            <Container titleKey='headers.dialogs.contact_details'>
              <Grid container>
                <Grid container item md={12}>
                  <ContactDetails onChange={handleChange} formState={store.guestDetails} partnerCode={partner?.code} />
                </Grid>
              </Grid>
            </Container>

            {
              showLoyaltySection && earnRates && (
                <Grid container className="container">
                  <Grid container item md={12}>
                    <LoyaltySchemeDetails
                      onChange={handleLoyaltyMembershipDetailsChanged}
                      surname={store.guestDetails.surname}
                      possiblePointEarnRates={earnRates} />
                  </Grid>
                </Grid>
              )
            }

            {
              showRonaRegistrationSection && (
                <Grid container className="container">
                  <Grid container item md={12}>
                    <BasicPreRegistrationSection
                      country={country}
                      onChange={onRonaRegistrationDetailsChanged} />
                  </Grid>
                </Grid>
              )
            }

            {
              PayInFullPromotion.canApply(store) && (
                <PayInFullDiscountBlurb />
              )
            }

            <Grid container className="container">
              <Grid item xs={12}>
                <BondPayableNotice store={store} />
              </Grid>
              <Grid item xs={12}>
                <TermsAndConditions
                  partnerCode={partner?.code}
                  vendorCode={vendor}
                  country={country}
                  checked={paymentStore.termsAccepted}
                  onChecked={() => paymentStore.setTermsAccepted()}
                />
              </Grid>
              <Grid item xs={12}>
                <EmailMeQuoteCheckbox onChange={handleEmailQuoteChange}/>
              </Grid>
              <Grid item xs={12}>
                <EmailSubscription 
                    onChecked={handleNewsletterSignup}
                    partnerCode={partner?.code} 
                />
              </Grid>
              <Grid item xs={12}>
                {partner?.code && country && (
                  <div style={{marginTop: "20px", marginLeft: "20px", fontSize: "small"}}>
                    <AdditionalPaymentDisclaimers partnerCode={partner.code} country={country} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormattedMessage id="action-buttons.continue_to_payment">
                  {t => (
                    <input
                      className='button-primary'
                      disabled={!paymentStore.termsAccepted}
                      type='button'
                      onClick={createQuotation}
                      value={t as string}
                    />
                  )}
                </FormattedMessage>

                <div className='u-error'>
                  {errorMessage && errorMessage.length > 0 && errorMessage}
                </div>
              </Grid>
            </Grid>
          </div>
        </BookingDetails>
      </div>
    )
  })
}

const PayInFullDiscountBlurb = () => {
  const store = useContext(BookingStoreContext)
  const paymentStore = useContext(PaymentStoreContext)

  const applyPayInFullDiscount = async () => {
    await PayInFullPromotion.applyDiscount(store)
    paymentStore.setPaymentOptionsSource(store.paymentOptionsSource)
  }

  const removePayInFullDiscount = async () => {
    await PayInFullPromotion.removeDiscount(store)
    paymentStore.setPaymentOptionsSource(store.paymentOptionsSource)
  }

  const applyPromotionButton = (
    <Button variant="contained" onClick={applyPayInFullDiscount}>
      <FormattedMessage id="discounts.buttons.apply" />
    </Button>
  )

  const removePromotionButton = (
    <Button variant="contained" onClick={removePayInFullDiscount}>
      <FormattedMessage id="discounts.buttons.remove" />
    </Button>
  )

  const applyPromotionBlurb = (
    <>
      <h4><FormattedMessage id="discounts.pay_in_full.marketing_heading" /></h4>
      <FormattedMessage id="discounts.pay_in_full.marketing_blurb" />
    </>
  )

  const isApplied = PayInFullPromotion.isApplied(store)

  return useObserver(() => (
    <div className="booking-payment-option">
      <Grid container className="container" alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={9}>
          {
            isApplied
              ? <FormattedMessage id="discounts.pay_in_full.discount_applied_text" />
              : applyPromotionBlurb
          }
        </Grid>

        <Grid item xs={12} sm={2} style={{textAlign: "center"}}>
          {
            isApplied
              ? removePromotionButton
              : applyPromotionButton
          }
        </Grid>
      </Grid>
    </div>
  ))
}

export default GuestDetails
