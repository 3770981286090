import {Grid} from '@mui/material'
import {useObserver} from 'mobx-react-lite'
import React, {FunctionComponent, useState} from 'react'
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl'
import {useHistory} from 'react-router-dom'
import defaultImage from '../../assets/img/default-vehicle.png'
import {ReactComponent as DownArrowHead} from '../../assets/img/down-arrow-head.svg'
import {SelectableProduct} from '../../domain/stores/product'
import Image from '../Image/Image'
import {VehicleLogo} from '../VehicleLogo/VehicleLogo'
import AlternativeResults from './AlternativeResults'
import './BookingSearchResultItem.scss'
import DailyRates from './DailyRates'
import {FeaturesTable} from './FeaturesTable'
import {NameAndBerth} from './NameAndBerth'
import ResultPrice from './ResultPrice'
import {SpecsLink} from './SpecsLink'
import * as Analytics from '../../utils/analytics'
import { LocationPath } from '../../domain/stores/search-store'
import { LoyaltyPointsEarnBadge } from '../LoyaltyPrograms/LoyaltyPointsEarnBadge'
import { Hidden } from '../Hidden'

interface IBookingSearchResultItem {
  selectableProduct: SelectableProduct
  bookingContinuationUrl: LocationPath
}

export const BookingSearchResultItem: FunctionComponent<IBookingSearchResultItem> = ({
  selectableProduct,
  bookingContinuationUrl
}) => {
  const [showDailyRates, setShowDailyRates] = useState(false)
  const [showSearchAlternatives, setShowSearchAlternatives] = useState(false)
  const history = useHistory()
  const product = selectableProduct.product
  const loyaltyProgramEarnValues = product.loyaltyProgramEarnValues()
  const vehicle = product.info
  return useObserver(() => {
    const nameAndBerth = (
      <NameAndBerth name={vehicle.meta.name} berth={vehicle.berth} vendorCode={vehicle.vendorCode} />
    )

    const vehicleImage = (
      <Image
        url={`/images/${vehicle.code}.png`}
        defaultImage={defaultImage}
        description={`${vehicle.name} | ${vehicle.code} | ${vehicle.vendorName}`}
        className='search-result-item__image'
      />
    )

    const includedFeatures = (
      <>
        <div className='search-result-item__include-features search-result-item--col-padding'>
          <FormattedMessage id='search-result.included-features' />
        </div>
        <div className='search-result-item--col-padding'>
          <FeaturesTable features={vehicle.meta.features} vendorCode={vehicle.vendorCode} />
        </div>
      </>
    )

    const vehicleLogo = (
      <VehicleLogo vendorCode={vehicle.vendorCode} className='search-result-item__logo' />
    )

    const priceOrUnavilable = vehicle.isAvailable ? (
      <Grid item className='search-result-item__price-text '>
        <div style={{marginBottom: "1em"}}>
          <ResultPrice currency={vehicle.currency} amount={vehicle.total} vendorCode={vehicle.vendorCode} />
        </div>
      </Grid>
    ) : (
      <Grid item className='u-error not-available'>
        <FormattedMessage id='search-result.vehicle-unavailable' />
      </Grid>
    )

    const spec = (
      <SpecsLink vendorCode={vehicle.vendorCode} moreDetails={vehicle.meta.moreDetails} />
    )

    const dailyRatesButton = vehicle.isAvailable ? (
      <button
        className='search-result-item__select-vehicle__see-daily-rates'
        onClick={e => {
          if (!showDailyRates) {
            Analytics.trackEvent('show_daily_rates')
          }

          setShowDailyRates(!showDailyRates)
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <FormattedMessage id='search-result.see-daily-rates' />{' '}
        <DownArrowHead />
      </button>
    ) : (
      ''
    )

    const selectVehicleButton = (
      <button
        className='search-result-item__select-vehicle__select-vehicle-button'
        onClick={async e => {
          if (vehicle.isAvailable) {
            Analytics.selectProduct(selectableProduct.toAnalyticsProduct())
            history.push(bookingContinuationUrl)
          } else {
            if (!showSearchAlternatives) {
              Analytics.trackEvent('search_alternatives')
            }

            setShowSearchAlternatives(!showSearchAlternatives)
            product.getAlternatives()
            e.stopPropagation()
            e.preventDefault()
          }
        }}
      >
        {
          vehicle.isAvailable
          ? <FormattedMessage id='search-result.select-vehicle' />
          : <FormattedMessage id='search-result.select-alternatives' />
        }
      </button>
    )

    return (
      <div className={`search-result-item--${vehicle.vendorCode}`}>
        <Grid item container className={`search-result-item`}>
          <Grid container justifyContent='space-between'>
            <Grid container item xs={12} md={7}>
              <Grid container alignItems='center' item>
                {nameAndBerth}
              </Grid>
              <Grid
                item
                className='search-result-item__description search-result-item--col-padding'
              >
                <FormattedHTMLMessage id={`vehicle.descriptions.${vehicle.meta.code.toUpperCase()}`} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              className='search-result-item__image-wrapper'
            >
              {vehicleImage}
            </Grid>
          </Grid>
          <Grid container alignItems='flex-end'>
            <Grid item xs={12} md={9}>
              {includedFeatures}
            </Grid>
            <Hidden mdDown>
              <Grid container justifyContent='flex-end' item md={3}>
                {priceOrUnavilable}
              </Grid>
            </Hidden>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item container justifyContent='space-around' alignItems='center' wrap='nowrap'>
              <div>{vehicleLogo}</div>
              <Hidden smDown>{spec}</Hidden>
              <Hidden mdUp>{priceOrUnavilable}</Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction='row'
              item
              className='search-result-item__select-vehicle'
              justifyContent='space-between'
            >
              <Grid item xs={12} md={6}>
                <div className='search-result-item__select-vehicle--button'>{dailyRatesButton}</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className='search-result-item__select-vehicle--button'>
                  {selectVehicleButton}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {product.canEarnLoyaltyPoints() && loyaltyProgramEarnValues && (
            <Grid item xs={12}>
              <Grid container item direction="column" className='loyaltyProgramLogoList'>
                {Object.entries(loyaltyProgramEarnValues).filter(v => v[1] > 0).map(i => {
                  const provider = i[0].toLowerCase()

                  return (
                    <Grid key={provider} container item xs={12} alignItems="center">
                      <LoyaltyPointsEarnBadge provider={provider} amount={i[1]} />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
        {showDailyRates && vehicle.isAvailable && (
          <div className='search-result-item__rates'>
            <DailyRates charges={product.getDailyCharges()} />
          </div>
        )}
        {!vehicle.isAvailable && showSearchAlternatives && product.alternatives && (
          <div className='search-result-item__rates'>
            <AlternativeResults alternatives={product.alternatives} />
          </div>
        )}
      </div>
    )
  })
}
