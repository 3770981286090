import React, {FunctionComponent, useContext} from 'react'
import {Grid} from '@mui/material'
import {EmailQuotation} from './EmailQuotation'
import './ActionButtons.scss'
import {useHistory} from 'react-router-dom'
import {config} from '../../domain/lib/config'
import { FormattedMessage } from 'react-intl'
import { BookingStoreContext } from '../../App'

interface IActionButtonsProps {
  showProceedAndEmailQuote: boolean
  showEmailQuote?: boolean
}
export const ActionButtons: FunctionComponent<IActionButtonsProps> = ({
  showProceedAndEmailQuote,
  showEmailQuote,
}) => {
  const store = useContext(BookingStoreContext)
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {config.hasOrange && (
        <Grid item xs={12}>
          <button className='action-buttons__join'>
            <FormattedMessage id='action-buttons.join' />
          </button>
        </Grid>
      )}
      {showProceedAndEmailQuote && (
        <>
          <Grid item xs={12}>
            <button className='action-buttons__primary-fullwidth' onClick={() => history.push(store.guestUrl)}>
              <FormattedMessage id='action-buttons.proceed' />
            </button>
          </Grid>
          {(showEmailQuote || showEmailQuote === undefined) && (
            <>
              <Grid item xs={12}>
                <button className='action-buttons__join' onClick={handleOpen}>
                  <FormattedMessage id='action-buttons.email-quote' />
                </button>
              </Grid>
              <EmailQuotation open={open} handleClose={handleClose} />
            </>
          )}
        </>
      )}
    </>
  )
}
