import {VendorCode} from '../models/search-models'
import * as VendorHelper from './vendor-helpers'

export const formatVendor = (code: VendorCode) => {
  if (VendorHelper.isApollo(code)) return 'Apollo'
  if (VendorHelper.isCheapa(code)) return 'Cheapa Campa'
  if (VendorHelper.isHippie(code)) return 'Hippie'
  if (VendorHelper.isStar(code)) return 'Star RV'
  if (VendorHelper.isBunk(code)) return 'Bunk Campers'
  if (VendorHelper.isApolloCars(code)) return 'Apollo Cars'

  return code
}
