import { InvoiceOutstanding, AmountOwing } from "../models/api-models";
import { PaymentOptionType, BondPaymentOptionType, FullPaymentOptionType } from "../../components/BookingPayment/BookingPaymentOption";

export interface InvoiceDetail {
  charge?: InvoiceOutstanding | AmountOwing
  guarantee?: InvoiceOutstanding | AmountOwing
  reserve?: InvoiceOutstanding | AmountOwing
}

export interface IPaymentOptionsArgs {
  invoice: InvoiceDetail | null
  bookingConfirmed: boolean
  amountPaid: number
  pointsRedeemed: number
}

export const paymentOptionsFrom = ({invoice, bookingConfirmed, amountPaid, pointsRedeemed}: IPaymentOptionsArgs): PaymentOptionType[] => {
  const paymentOptions = [] as PaymentOptionType[]
  const charge = invoice?.charge
  const refundBond = invoice?.guarantee?.amount.amount || invoice?.reserve?.amount.amount
  if (!refundBond || !charge || !charge.defaultAmount || !charge.amount) return paymentOptions

  const currency = charge.amount.currency || charge.defaultAmount.currency
  const totalRemaining = charge.amount.amount
  const deposit = charge.defaultAmount.amount
  const payableAtPickup = charge.externalAmount?.amount
  const totalDueAt = charge.amountDueDate && new Date(charge.amountDueDate)

  var minimumDepositPayable = undefined
  if (totalRemaining.toFixed(2) !== deposit.toFixed(2))
    minimumDepositPayable = deposit
  else if (pointsRedeemed > 0 && amountPaid === 0 && !bookingConfirmed && totalDueAt && totalDueAt > (new Date()))
    minimumDepositPayable = 1

  if (minimumDepositPayable) {
    const balance = totalRemaining - minimumDepositPayable
    const payLater = balance

    const bondPayment: BondPaymentOptionType = {
      type: 'bond',
      currency: currency,
      totalRental: totalRemaining,
      payNow: minimumDepositPayable,
      balance: balance,
      payLater: payLater,
      refundableBond: refundBond,
      totalDueAt: totalDueAt,
      payableAtPickup: payableAtPickup
    }
    paymentOptions.push(bondPayment)
  }

  if (totalRemaining >= deposit) {
    // No Full option if total is less than bond
    const fullPayment: FullPaymentOptionType = {
      type: 'full',
      currency: currency,
      totalRental: totalRemaining,
      payNow: totalRemaining,
      refundableBond: refundBond,
      payableAtPickup: payableAtPickup
    }

    paymentOptions.push(fullPayment)
  }

  return paymentOptions
}