import React, {FunctionComponent, useContext, useEffect} from 'react'
import {RouteComponentProps, useHistory} from 'react-router-dom'
import {PaymentStoreContext} from '../../App'
import {Page} from '../Page/Page'
import * as Analytics from '../../utils/analytics'
import './TokenisationSuccess.scss'
import { failedPaymentUrlFrom, successfulPaymentUrlFrom } from '../../domain/helpers/url-helpers'

export const TokenisationSuccess: FunctionComponent<RouteComponentProps<{
  reservation_number: string
  surname: string
}>> = props => {
  const history = useHistory()
  const paymentStore = useContext(PaymentStoreContext)

  useEffect(() => {
    const onPaymentError = (reservationNumber: string, surname: string) => {
      Analytics.trackEvent('payment_error')

      // replace this path in history so we don't tell analytics about an error more than once (if the back button is hit)
      history.replace(failedPaymentUrlFrom(reservationNumber, surname))
    }

    const f = async () => {
      const rn = props.match.params.reservation_number
      const sn = props.match.params.surname

      if (rn && sn) {
        await paymentStore.setQuotationFrom(rn, sn)

        if (paymentStore.isConfirmed) {
          await paymentStore.loadInvoice()
          Analytics.registerPurchase(paymentStore)

          // replace this path in history so we don't tell analytics about a sale more than once (if the back button is hit)
          history.replace(successfulPaymentUrlFrom(rn, sn))
        } else {
          onPaymentError(rn, sn)
        }
      } else {
        onPaymentError(rn, sn)
      }
    }
    f()
  }, [
    paymentStore,
    props.match.params.reservation_number,
    props.match.params.surname,
    history,
  ])

  return (
    <div className="page-content">
      <Page tracker={paymentStore.tracker}></Page>
    </div>
  )
}
