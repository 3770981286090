import React, { FunctionComponent, FormEvent, useState, useContext } from 'react'
import { Dialog, DialogTitle, Grid, CircularProgress } from '@mui/material'
import clsx from 'clsx'
import ContactDetails from '../GuestDetails/ContactDetails'
import { BookingStoreContext } from '../../App'
import { toast } from 'react-toastify'
import { FormattedMessage, useIntl } from 'react-intl'
import { regxEmail } from '../../utils/regx'
import './EmailQuotation.scss'
import '../GuestDetails/GuestDetails.scss'
import { Quotation, BasicContactInformation, SearchParameters } from '../../domain/models/search-models'
import { contactNumberFor, PartnerMarketingKey } from '../../domain/helpers/partner-helpers'
import * as Analytics from '../../utils/analytics'
import EmailSubscription from '../BookingPayment/EmailSubscription'

interface IEmailQuatationProps {
  open: boolean
  handleClose: () => void
}

export const EmailQuotation: FunctionComponent<IEmailQuatationProps> = ({ open, handleClose }) => {
  const store = useContext(BookingStoreContext)
  const intl = useIntl()
  const [quote, setQuote] = React.useState<Quotation>()

  const messageFor = (key: string): string => {
    return intl.formatMessage({ id: key })
  }

  const handleSubmit = (details: BasicContactInformation, subscribedNewsletterKeys: string[], onComplete: CallbackFunction) => {
    store.createQuotation(details, subscribedNewsletterKeys, true)
      .then(res => {
        if (res.data) {
          toast.success(messageFor('messages.quote_email_sent'))
          Analytics.emailedQuote(res.data)
          setQuote(res.data)
        } else {
          toast.error(messageFor('messages.quote_email_send_failed'))  
        }
      })
      .catch(e => {
        toast.error(messageFor('messages.quote_email_send_failed'))
      })
      .finally(() => {
        onComplete()
      })
  }

  const resetFormAndClose = () => {
    setQuote(undefined)
    handleClose()
  }

  return (
    <>
      <Dialog aria-labelledby='email-quote' open={open} onClose={resetFormAndClose} className={`partner_${store.partner?.code}`}>
        {
          !quote
            ? <EmailQuoteForm onSubmit={handleSubmit} bookingParameters={store.searchParameters!} />
            : <QuoteEmailedNotice quote={quote} />
        }
      </Dialog>
    </>
  )
}

type CallbackFunction = () => void
interface QuoteFormParameters {
  onSubmit: (details: BasicContactInformation, subscribedNewsletterKeys: string[], onComplete: CallbackFunction) => void
  bookingParameters: SearchParameters 
}

const EmailQuoteForm = ({ onSubmit, bookingParameters }: QuoteFormParameters) => {
  const [pending, setPending] = React.useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const intl = useIntl()
  const [subscribedNewsletterKeys, setSubscribedNewsletterKeys] = useState<{[key: string]: boolean}>({})
  const [formState, setFormState] = useState<BasicContactInformation>({
    email: '',
    firstName: '',
    telephone: '',
    surname: '',
    title: 'Mr',
    confirmEmail: '',
    membershipNumber: bookingParameters.membership_number
  })

  const buttonClassname = clsx({
    'button-loading': pending,
    'button-primary': !pending,
  })

  const handleChange = (key: string, value: string) => {
    setFormState({ ...formState, [key]: value })
  }

  const handleNewsletterSignup = (partnerCampaign: PartnerMarketingKey, selected: boolean) => {
    setSubscribedNewsletterKeys({...subscribedNewsletterKeys, [partnerCampaign.listId]: selected})
  }

  const messageFor = (key: string): string => {
    return intl.formatMessage({ id: key })
  }

  const validateFormState = (): boolean => {
    setErrorMessage(null)

    if (formState.firstName.length <= 2) {
      setErrorMessage(messageFor('guest-details.validations.name-required'))
      return false
    }

    if (formState.surname.length <= 2) {
      setErrorMessage(messageFor('guest-details.validations.name-required'))
      return false
    }

    if (regxEmail.test(formState.email) === false) {
      setErrorMessage(messageFor('guest-details.validations.email-invalid'))
      return false
    }

    if (formState.email !== formState.confirmEmail) {
      setErrorMessage(messageFor('guest-details.validations.confirm-email-mismatch'))
      return false
    }

    return true
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (validateFormState()) {
      setPending(true)

      const selectedNewsletterKeys = Object.entries(subscribedNewsletterKeys).filter(e => e[1]).map(e => e[0])
      onSubmit(formState, selectedNewsletterKeys, () => setPending(false))
    }
  }

  return (
    <>
      <DialogTitle id='email-quote'>
        <FormattedMessage id="headers.dialogs.create_quote" />
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <div className='email-quote-contact-details'>
          <Grid container item md={12}>
            <ContactDetails onChange={handleChange} formState={formState} partnerCode={bookingParameters.partner_code} />

            <Grid item xs={12}>
              <div style={{marginTop: "15px"}}>
                <EmailSubscription
                  onChecked={handleNewsletterSignup}
                  partnerCode={bookingParameters.partner_code}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              {errorMessage && (
                <div className='validation-message'>
                  {errorMessage}
                </div>
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction='row-reverse'
              alignItems='center'
              className='email-quote-contact-details--btn'
            >
              <Grid item xs={12} md={6}>
                <div className='email-send-btn-wrapper'>
                  <FormattedMessage id="action-buttons.email-quote">
                    {t => (
                      <input
                        className={buttonClassname}
                        type='submit'
                        value={t as string}
                        disabled={pending}
                      />
                    )}
                  </FormattedMessage>
                  {pending && (
                    <CircularProgress size={30} className='email-send-btn-wrapper--loading' />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  )
}

interface EmailedQuoteDetails {
  quote: Quotation
}
const QuoteEmailedNotice = ({ quote }: EmailedQuoteDetails) => {
  const contactNumber = contactNumberFor(quote.rental.startDepot!.country!.code, quote.partner.code)

  return (
    <>
      <DialogTitle id='email-quote'>
        <h2><FormattedMessage id="headers.dialogs.quote_emailed" /></h2>
      </DialogTitle>

      <div className="emailed-quote-notice">
        <p className="header-text"><FormattedMessage id="headers.quote_number" /></p>
        <p className="quote-number">{quote.reservationNo}</p>
        <p className="subheader-text"><FormattedMessage id="messages.any_queries_contact" values={{ contactNumber: contactNumber }} /></p>
      </div>
    </>
  )
}