import {config} from '../domain/lib/config'
import {SelectableProduct} from '../domain/stores/product'
import {SearchStore} from '../domain/stores/search-store'
import {PaymentStore} from '../domain/stores/payment-store'
import { Quotation } from '../domain/models/search-models'

declare var gtag: any
declare var __insp: any
declare var fbq: any
declare var uetq: any
declare var google_ads_account: any
declare var google_ads_group: any

const googleAnalyticsEnabled = () => typeof gtag !== 'undefined';
const googleAdsEnabled = () => googleAnalyticsEnabled() && typeof google_ads_account !== 'undefined' && typeof google_ads_group !== 'undefined';
const inspectletEnabled = () => typeof __insp !== 'undefined';
const facebookPixelEnabled = () => typeof fbq !== 'undefined';
const microsoftUETEnabled = () => typeof uetq !== 'undefined';

const logDebugMessage = (action: string, payload: any) => {
  if (config.isDevelopment) console.log(action, payload)
}

const dateToString = (date: Date | undefined) => {
  if (!date) return ""
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

const maybeInformGoogleAnalytics = (payloadType: string, message: string, payload?: any) => {
  if (!googleAnalyticsEnabled()) return;

  gtag(payloadType, message, payload);

  logDebugMessage('EmitTrackingPayload:GoogleAnalytics', {
    payloadType: payloadType,
    message: message,
    payload: payload
  })
}
const maybeInformGoogleAds = (payloadType: string, message: string, payload?: any) => {
  if (!googleAdsEnabled()) return;

  gtag(payloadType, message, {
    ...(payload || {}),
    'send_to': `${google_ads_account}/${google_ads_group}`
  });

  logDebugMessage('EmitTrackingPayload:GoogleAds', {
    payloadType: payloadType,
    message: message,
    payload: payload
  })
}
const maybeInformInspectlet = (payloadType: string, message: string) => {
  if (!inspectletEnabled()) return;

  __insp.push([payloadType, message])

  logDebugMessage('EmitTrackingPayload:Inspectlet', {
    payloadType: payloadType,
    message: message
  })
}
const maybeInformFacebook = (payloadType: string, message: string, payload?: any) => {
  if (!facebookPixelEnabled()) return;

  fbq(payloadType, message, payload);

  logDebugMessage('EmitTrackingPayload:FacebookPixel', {
    payloadType: payloadType,
    message: message,
    payload: payload
  })
}
const maybeInformMicrosoftAnalytics = (payloadType: string, message: string, payload?: any) => {
  if (!microsoftUETEnabled()) return;

  uetq.push(payloadType, message, payload);

  logDebugMessage('EmitTrackingPayload:MicrosoftUET', {
    payloadType: payloadType,
    message: message,
    payload: payload
  })
}

export function registerTiming(timedEventKey: string, timeTaken: number) {
  const payload = {
    'name': timedEventKey,
    'value': timeTaken
  }

  logDebugMessage('registerTiming', payload)
  maybeInformGoogleAnalytics('event', 'timing_complete', payload);
}

export function trackEvent(event: string) {
  maybeInformGoogleAnalytics('event', event)
  maybeInformInspectlet('tagSession', event)

  logDebugMessage('trackEvent', event)
}

export function identifyUserAs(email: string) {
  maybeInformInspectlet('identify', email)
}

export function registerSearch(searchRequest: SearchStore) {
  const searchParameters = searchRequest.searchParameters
  const payload = {
    'pickupDate': dateToString(searchRequest.pickupDate),
    'dropoffDate': dateToString(searchRequest.dropOffDate),
    'pickupLocation': searchParameters?.pick_up_location.toUpperCase(),
    'dropoffLocation': searchParameters?.drop_off_location.toUpperCase(),
    'partner': searchParameters?.partner_code,
    'country': searchParameters?.country,
    'vehicleType': searchParameters?.vehicle_type,
    'driversLicence': searchParameters?.drivers_licence,
    'promotionCode': searchParameters?.promo_code
  }

  logDebugMessage('registerSearch', payload)
  maybeInformGoogleAnalytics('event', 'search', payload)
  maybeInformGoogleAnalytics('send', 'pageview', `/search_tracker?pud=${payload['pickupDate']}&pul=${payload['pickupLocation']}&dod=${payload['dropoffDate']}&dol=${payload['dropoffLocation']}&vt=${payload['vehicleType']}`)
}

export function displayedVehicleList(selectableProducts: SelectableProduct[], listName?: string) {
  const payload = {
    "items": selectableProducts.map(selectableProduct => {
      const vehicle = selectableProduct.product.info
      return {
        "id": vehicle.code,
        "name": vehicle.name,
        "list_name": listName || "search_results",
        "brand": vehicle.vendorCode,
        "list_position": selectableProduct.index,
        "price": vehicle.total,
        "is_available": vehicle.isAvailable
      }
    })
  }

  logDebugMessage('displayedVehicleList', payload)
  maybeInformGoogleAnalytics('event', 'view_item_list', payload);
}

export function selectProduct(analyticsProduct: IAnalyticsProduct, listName?: string) {
  const payload = {
    "content_type": "product",
    "items": [
      {
        "id": analyticsProduct.code,
        "name": analyticsProduct.name,
        "list_name": listName || "search_results",
        "brand": analyticsProduct.vendor,
        "list_position": analyticsProduct.list_position,
        "price": analyticsProduct.total
      }
    ]
  }

  logDebugMessage('selectProduct', payload)
  maybeInformGoogleAnalytics('event', 'select_content', payload);
}

export function addItem(productCode: string, totalQuantity: number, cost?: number) {
  const payload = {
    'items': {
      "id": productCode,
      "quantity": totalQuantity,
      "price": cost
    }
  }

  logDebugMessage('addItem', payload)
  maybeInformGoogleAnalytics('event', 'add_to_cart', payload);
}

export function removeItem(productCode: string, totalQuantity: number, cost?: number) {
  const payload = {
    'items': {
      "id": productCode,
      "quantity": totalQuantity,
      "price": cost
    }
  }

  logDebugMessage('removeItem', payload)
  maybeInformGoogleAnalytics('event', 'remove_from_cart', payload);
}

export function emailedQuote(quote: Quotation) {
  trackEvent('emailed_quote')

  maybeInformGoogleAnalytics('event', 'generate_lead', {
    "currency": quote.rental.totalPrice?.currency,
    "value": quote.rental.totalPrice?.amount
  });

  maybeInformFacebook('track', 'Lead', {
    "content_category": quote.rental.product?.typeIndicator,
    "content_name": quote.rental.product?.code,
    "currency": quote.rental.totalPrice?.currency,
    "value": quote.rental.totalPrice?.amount
  });
}

export function registerPurchase(purchaseResult: PaymentStore) {
  trackEvent('purchase')

  maybeRegisterPurchaseGoogleAnalytics(purchaseResult);
  maybeRegisterPurchaseFacebookPixel(purchaseResult);
  maybeRegisterPurchaseMicrosoftUET(purchaseResult);
}

function maybeRegisterPurchaseGoogleAnalytics(purchaseResult: PaymentStore) {
  const payment = purchaseResult.balancePayment
  const quotation = purchaseResult.quotation
  const ancillaries = quotation?.ancillaries
  const rental = quotation?.rental

  let items = []

  if (rental && rental.product) {
    items.push({
      "id": rental.product.code,
      "name": rental.product.name,
      "quantity": 1,
      "price": rental.totalPrice?.amount
    })
  }

  if (ancillaries) {
    if (ancillaries.equipmentAndServices) {
      items.push(...ancillaries.equipmentAndServices.map(equipment => {
        return {
          "id": equipment.product.code,
          "name": equipment.product.name,
          "quantity": 1,
          "price": equipment.price?.amount
        }
      }))
    }

    if (ancillaries.fees) {
      items.push(...ancillaries.fees.map(fee => {
        return {
          "id": fee.product.code,
          "name": fee.product.name,
          "quantity": 1,
          "price": fee.price?.amount
        }
      }))
    }

    if (ancillaries.coverage) {
      items.push({
        "id": ancillaries.coverage.product.code,
        "name": ancillaries.coverage.product.name,
        "quantity": 1,
        "price": ancillaries.coverage.price?.amount
      })
    }
  }

  maybeInformGoogleAnalytics('event', 'purchase', {
    "transaction_id": purchaseResult.reservationNumber,
    "affiliation": quotation?.partner.code,
    "value": (payment.payable || 0) + (payment.paid || 0),
    "currency": payment.currency,
    "tax": payment.adminFee,
    "items": items
  });

  maybeInformGoogleAds('event', 'conversion', {
    "value": (payment.payable || 0) + (payment.paid || 0),
    "currency": payment.currency,
    "transaction_id": purchaseResult.reservationNumber
  })
}

function maybeRegisterPurchaseFacebookPixel(purchaseResult: PaymentStore) {
  const payment = purchaseResult.balancePayment
  const quotation = purchaseResult.quotation
  const ancillaries = quotation?.ancillaries
  const rental = quotation?.rental

  let items = []

  if (rental && rental.product) {
    items.push({
      "id": rental.product.code,
      "quantity": 1
    })
  }

  if (ancillaries) {
    if (ancillaries.equipmentAndServices) {
      items.push(...ancillaries.equipmentAndServices.map(equipment => {
        return {
          "id": equipment.product.code,
          "quantity": 1
        }
      }))
    }

    if (ancillaries.fees) {
      items.push(...ancillaries.fees.map(fee => {
        return {
          "id": fee.product.code,
          "quantity": 1
        }
      }))
    }

    if (ancillaries.coverage) {
      items.push({
        "id": ancillaries.coverage.product.code,
        "quantity": 1
      })
    }
  }

  const payload = { 
    content_type: 'product',
    content_name: rental?.product?.name,
    contents: items,
    value: (payment.payable || 0) + (payment.paid || 0),
    currency: payment.currency
  }

  maybeInformFacebook('track', 'Purchase', payload);
}

function maybeRegisterPurchaseMicrosoftUET(purchaseResult: PaymentStore) {
  const payment = purchaseResult.balancePayment
  const quotation = purchaseResult.quotation
  const rental = quotation?.rental
  const payload = {  
    'event_category': 'Purchase',
    'event_label': rental?.product?.name, 
    'event_value':  (payment.payable || 0) + (payment.paid || 0), 
    'revenue_value': (payment.payable || 0) + (payment.paid || 0),
    'currency': payment.currency
  }

  maybeInformMicrosoftAnalytics('track', 'Purchase', payload);
}

export interface IAnalyticsProduct {
  code: string
  name: string
  vendor: string
  list_position: number
  total: number
}
