import { Grid } from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import defaultImage from '../../assets/img/default-vehicle.png'
import { formatDate, formatDay } from '../../domain/helpers/date-formatters'
import { BookingStore } from '../../domain/stores/booking-store'
import { EditSearchStore } from '../../domain/stores/edit-search-store'
import { trackEvent } from '../../utils/analytics'
import Image from '../Image/Image'
import { ConfigureRelocationParameters, ConfigureSearchParameters } from './EditBookingParameters'
import './PickupDropoffInfo.scss'
import { Hidden } from '../Hidden'

export interface IPickupDropoffInfoProps {
  startDepot: { code: string; name: string }
  startDateTime: Date
  endDepot: { code: string; name: string }
  endDateTime: Date
  vehicleCode: string
}

export interface IComparePickupDropoffInfoProps {
  before: IPickupDropoffInfoProps
  after?: IPickupDropoffInfoProps
}

interface IEditSearchModal {
  bookingStore: BookingStore
  editStore: EditSearchStore
}

const renderChangeLines = (before: string, after?: string) => {
  return after && before !== after ? (
    <>
      <div className='pickup-dropoff--changed-from'>{before}</div>
      <div className='pickup-dropoff--changed-to'>{after}</div>
    </>
  ) : <div className='pickup-dropoff--no-change'>{before}</div>
}

const PickupInfoSection: FunctionComponent<IComparePickupDropoffInfoProps> = ({ before, after }) => {
  return (
    <>
      <Grid item>
        <div className='pickup-dropoff--small'>
          <FormattedMessage id="headers.pickup" />
        </div>
        <div className='pickup-dropoff--big'>
          {renderChangeLines(before.startDepot.name, after && after.startDepot.name)}
        </div>
      </Grid>
      <Grid item>
        <div className='pickup-dropoff--small'>
          {renderChangeLines(formatDay(before.startDateTime), after && formatDay(after.startDateTime))}
        </div>
        <div className='pickup-dropoff--big'>
          {renderChangeLines(formatDate(before.startDateTime), after && formatDate(after.startDateTime))}
        </div>
      </Grid>
    </>
  )
}

const DropoffInfoSection: FunctionComponent<IComparePickupDropoffInfoProps> = ({ before, after }) => {
  return (
    <div className='pickup-dropoff--text-white'>
      <Grid item>
        <div className='pickup-dropoff--small'>
          <FormattedMessage id="headers.dropoff" />
        </div>
        <div className='pickup-dropoff--big'>
          {renderChangeLines(before.endDepot.name, after && after.endDepot.name)}
        </div>
      </Grid>
      <Grid item>
        <div className='pickup-dropoff--small'>
          {renderChangeLines(formatDay(before.endDateTime), after && formatDay(after.endDateTime))}
        </div>
        <div className='pickup-dropoff--big'>
          {renderChangeLines(formatDate(before.endDateTime), after && formatDate(after.endDateTime))}
        </div>
      </Grid>
    </div>
  )
}

const ChevronSeparator: FunctionComponent<IComparePickupDropoffInfoProps> = () => {
  return (
    <div className='pickup-dropoff__chevron'>
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none'>
        <polygon points='0,0 0,100 100,50' />
      </svg>
    </div>
  )
}

export const PickupDropoffComparisonDisplay: FunctionComponent<IComparePickupDropoffInfoProps> = (props) => {
  return (
    <Grid container item xs = {12} className = 'pickup-dropoff__container' >
      <Grid item xs={5} container direction='column' justifyContent='space-around'>
        <PickupInfoSection {...props} />
      </Grid>
      <Grid item xs={1} style={{ backgroundColor: '#58595b', padding: 0 }}>
        <ChevronSeparator {...props} />
      </Grid>
      <Grid item xs={6} className='pickup-dropoff--dark-bg' container direction='column' justifyContent='space-around'>
        <DropoffInfoSection {...props} />
      </Grid>
    </Grid >
  )
}

export const SimplePickupDropoffDisplay: FunctionComponent<IPickupDropoffInfoProps> = (props) => {
  return (
    <Grid container item xs = {12} className = 'pickup-dropoff__container' >
      <Grid item xs={5} sm={3} md={3} container direction='column' justifyContent='space-around'>
        <PickupInfoSection before={props} />
      </Grid>
      <Grid item xs={2} sm={1} style={{ backgroundColor: '#58595b' }}>
        <ChevronSeparator before={props} />
      </Grid>
      <Grid item xs={5} sm={8} md={3} className='pickup-dropoff--dark-bg' container direction='column' justifyContent='space-around'>
        <DropoffInfoSection before={props} />
      </Grid>
      <Grid container item xs={5} className='pickup-dropoff--dark-bg'>
      </Grid>
    </Grid >
  )
}

export const PickupDropoffInfo: FunctionComponent<IPickupDropoffInfoProps> = (props) => {
  const {vehicleCode} = props;

  return (
    <>
      <SimplePickupDropoffDisplay {...props} />
      <Hidden smDown>
        <Image
          url={`/images/${vehicleCode}.png`}
          defaultImage={defaultImage}
          description={vehicleCode}
          className='pickup-dropoff__vehicle-image'
        />
      </Hidden>
    </>
  )
}

const _canEditNormalSearches = false
export const ChangeSearchParameterButton: FunctionComponent<IEditSearchModal> = ({ bookingStore, editStore }) => {
  if (bookingStore.searchParameters?.relocation_id)
    return <EditRelocationParameters bookingStore={bookingStore} editStore={editStore} />
  else if (bookingStore.searchParameters?.hotdeal_id)
    return <EditHotDealParameters bookingStore={bookingStore} editStore={editStore} />
  else if (_canEditNormalSearches)
    return <EditSearchParameters bookingStore={bookingStore} editStore={editStore} />
  else
    return null
}

const EditRelocationParameters: FunctionComponent<IEditSearchModal> = ({bookingStore, editStore}) => {
  const [isModalOpen, setModalState] = React.useState(false)

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()

    editStore.resetWithBookingStore(bookingStore)

    trackEvent("tweaked_booking_parameters:relocation")
    setModalState(true)
  }
  const closeModal = () => {
    setModalState(false)
  }

  return (
    <>
      <button className='pickup-dropoff__edit' onClick={openModal}>
        <span style={{marginRight: "10px"}}>
          <Edit />
        </span>

        <FormattedMessage id="headers.booking_modify.relocation_title" />
      </button>
      <ConfigureRelocationParameters open={isModalOpen} handleClose={closeModal} />
    </>
  )
}

const EditHotDealParameters: FunctionComponent<IEditSearchModal> = ({bookingStore, editStore}) => {
  const [isModalOpen, setModalState] = React.useState(false)

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()

    editStore.resetWithBookingStore(bookingStore)

    trackEvent("tweaked_booking_parameters:hotdeal")
    setModalState(true)
  }
  const closeModal = () => {
    setModalState(false)
  }

  return (
    <>
      <button className='pickup-dropoff__edit' onClick={openModal}>
        <span style={{marginRight: "10px"}}>
          <Edit />
        </span>

        <FormattedMessage id="headers.booking_modify.hotdeal_title" />
      </button>
      <ConfigureRelocationParameters open={isModalOpen} handleClose={closeModal} />
    </>
  )
}

const EditSearchParameters: FunctionComponent<IEditSearchModal> = ({bookingStore, editStore}) => {
  const [isModalOpen, setModalState] = React.useState(false)

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()

    editStore.resetWithBookingStore(bookingStore)

    trackEvent("tweaked_booking_parameters:normal_booking")
    setModalState(true)
  }
  const closeModal = () => {
    setModalState(false)
  }

  return (
    <>
      <button className='pickup-dropoff__edit' onClick={openModal}>
        <FormattedMessage id="headers.booking_modify.configure_title" />
      </button>
      <ConfigureSearchParameters open={isModalOpen} handleClose={closeModal} />
    </>
  )
}