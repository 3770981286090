import { explicitlySetLocale } from '../services/MetadataService'
import { SearchParameters, BookingParameters } from '../models/search-models'
import { LocationPath } from '../stores/search-store'

const shouldDefaultDropoffLocation = (search: SearchParameters): boolean => {
  // Some search widgets use "XXX" as their "Please Select" key.
  // If we see this value, fallback to use the Pickup Location
  const selectedLocationCode = search.drop_off_location.toUpperCase()
  return selectedLocationCode.length === 0 || selectedLocationCode === "XXX"
}

const dropoffLocationFrom = (search: SearchParameters): string => {
  return shouldDefaultDropoffLocation(search) ? search.pick_up_location : search.drop_off_location
}

const childrenCountFrom = (search: SearchParameters): string => {
  return ((+search.infants || 0) === 0)
    ? search.children
    : `${search.children || 0}.${search.infants || 0}`
}

const patchSearchParameters = (parameters: SearchParameters | BookingParameters) => {
  parameters.drop_off_location = dropoffLocationFrom(parameters)

  const childrenSplit = parameters.children.split('.', 2)
  parameters.children = childrenSplit[0]
  parameters.infants = childrenSplit[1]
}

export const searchParametersFrom = (searchParameters: SearchParameters): SearchParameters => {
  const result = searchParameters
  patchSearchParameters(result)
  return result
}

export const bookingParametersFrom = (searchParameters: BookingParameters): BookingParameters => {
  const result = searchParameters
  patchSearchParameters(result)
  return result
}

export const searchUrlFrom = (search: SearchParameters): string => {
  return urlFrom('search', search.partner_code, search.country, search.vehicle_type, search.pick_up_location, dropoffLocationFrom(search), search.pick_up_date, search.drop_off_date, search.drivers_licence, search.adults, childrenCountFrom(search), search.driver_age, search.promo_code)
}

export const configureUrlFrom = (search: SearchParameters, vehicle_code: string, rate_plan_code: string): string => {
  return urlFrom('booking', search.partner_code, search.country, search.vehicle_type, search.pick_up_location, dropoffLocationFrom(search), search.pick_up_date, search.drop_off_date, search.drivers_licence, search.adults, childrenCountFrom(search), search.driver_age, vehicle_code, rate_plan_code)
}

interface AdditionalConfigureUrlInfo {
  membership_number?: string
  relocation_identifier?: string
  deal_identifier?: string
}

export const configureUrlWithQueryFrom = (search: SearchParameters, vehicle_code: string, rate_plan_code: string, additionalInfo: AdditionalConfigureUrlInfo): LocationPath => {
  const result: LocationPath = {
    pathname: configureUrlFrom(search, vehicle_code, rate_plan_code)
  }

  const queries: string[] = []
  if (additionalInfo.membership_number) queries.push(`membership_number=${additionalInfo.membership_number}`)
  if (additionalInfo.relocation_identifier) queries.push(`relocation_id=${additionalInfo.relocation_identifier}`)
  if (additionalInfo.deal_identifier) queries.push(`deal_id=${additionalInfo.deal_identifier}`)
  if (queries.length > 0) result.search = `?${queries.join('&')}`

  return result
}

export const guestRegistrationUrlFrom = (): string => {
  return urlFrom('guest')
}

export const paymentContinuationUrlFrom = (reservationNumber: string, surname: string): string => {
  return urlFrom('payment', reservationNumber, surname)
}

export const failedPaymentUrlFrom = (reservationNumber: string, surname: string): string => {
  return `${urlFrom('payment', reservationNumber, surname)}?paymentError=true`
}

export const successfulPaymentUrlFrom = (reservationNumber: string, surname: string): string => {
  return urlFrom('payment', 'confirmation', reservationNumber, surname)
}

const urlFrom = (...parts: (string | undefined)[]): string => {
  const basePath = [explicitlySetLocale(), ...parts].filter(part => part).join('/')
  return `/${basePath}`
}