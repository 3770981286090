import React from 'react'
import './ImportantInfo.scss'
import { isBunkPartner } from '../../domain/helpers/partner-helpers'
import { IImportantInfoSection } from './IImportantInfoSection'
import { childRestraintsLinkFor } from '../../domain/services/MetadataService'

const ChildRestraintPolicy = (params: IImportantInfoSection) => {
  if (isBunkPartner(params.partnerCode)) {
    if (params.locale === 'en-GB') {
      return BunkChildRestraintPolicyEN
    } else {
      return BunkChildRestraintPolicyDE
    }
  } else {
    if (params.locale === 'en-GB') {
      return DefaultChildRestraintPolicyEN(params)
    } else {
      return DefaultChildRestraintPolicyDE(params)
    }
  }
}

const DefaultChildRestraintPolicyEN = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Child Restraint Policy</h3>
    <p>
      To keep your children safe and secure, each child must be fastened in the correct child car seat for their age, and according to the law in your country of travel.
      
      Please read our <strong>Child Restraint Policy</strong> carefully prior to booking, which you will find on our <a href={childRestraintsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        Terms &amp; Conditions
      </a> page.
    </p>
  </div>
)

const DefaultChildRestraintPolicyDE = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Kindersitzvorschriften</h3>
    <p>
      Damit Ihre Kinder sicher und geborgen reisen können, muss jedes Kind in dem seinem Alter entsprechenden
      Kindersitz angeschnallt sein. Es gelten die gesetzlichen Bestimmungen in Ihrem Reiseland.
      Bitte lesen Sie unsere Richtlinien für <a href={childRestraintsLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        Rückhaltesysteme für Kinder sorgfältig
      </a> durch, bevor Sie buchen.
    </p>
  </div>
)

const BunkChildRestraintPolicyEN = (
  <div className='important-info'>
    <h3>Child Restraint Policy</h3>
    <p>
      To keep your children safe and secure, each child must be fastened in the correct child car
      seat for their age, and according to the law in your country of travel. All Bunk Campers vehicles
      have a minimum of 2 forward facing seats in the habitation area with 3-point seat belts.
      You can hire a child seat from us or can bring along your own if you prefer.
    </p>
  </div>
)

const BunkChildRestraintPolicyDE = (
  <div className='important-info'>
    <h3>Kindersitzvorschriften</h3>
    <p>
      Damit Ihre Kinder sicher und geborgen reisen können, muss jedes Kind in dem seinem Alter entsprechenden
      Kindersitz angeschnallt sein. Es gelten die gesetzlichen Bestimmungen in Ihrem Reiseland. Bei allen
      Bunk-Camper-Fahrzeugen können im Wohnbereich mindestens 2 nach vorne gerichtete 3-Punkt-Gurten Sitze
      befestigt werden. Sie können bei uns einen Kindersitz mieten oder wenn Sie dies bevorzugen, Ihren eigenen mitbringen.
    </p>
  </div>
)

export default ChildRestraintPolicy
