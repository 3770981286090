import Grid from '@mui/material/Grid'
import React, {ReactNode} from 'react'
import {FormattedMessage} from 'react-intl'
import {config} from '../../domain/lib/config'
import {VendorCode} from '../../domain/models/search-models'
import {VehicleLogo} from '../VehicleLogo/VehicleLogo'
import './BookingSearchResultGroup.scss'

export interface IBookingSearcResultGroupProps {
  vehicleCount: number
  vendorCode: VendorCode
  children: ReactNode
}

const BookingSearchResultGroup = ({
  vehicleCount,
  vendorCode,
  children,
}: IBookingSearcResultGroupProps) => {
  return (
    <Grid container item className={`search-result-group--${vendorCode}`} id={vendorCode}>
      <Grid container item justifyContent='space-between' alignItems='center' className='brand-header'>
        <Grid item className='brand-header__left'>
          <VehicleLogo vendorCode={vendorCode} className='brand-header__left__logo' header />
        </Grid>
        {config.hasOrange && (
          <Grid item className='brand-header__right'>
            <span className='brand-header__right__join'>
              <FormattedMessage id='search-result.join-club-message' />
            </span>
            <div className='brand-header__right__club'>orange club</div>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <div className='result-count'>
          {
            vehicleCount === 1
              ? <FormattedMessage id="headers.search_filter.results_found.one" values={{resultCount: vehicleCount}} />
              : <FormattedMessage id="headers.search_filter.results_found.plural" values={{resultCount: vehicleCount}} />
          }
        </div>
      </Grid>
      {children}
    </Grid>
  )
}

export default BookingSearchResultGroup
