import campStayResponse from '../../assets/camp-stay.json'
import { toDateAsUtc } from '../helpers/date-formatters'
import { config } from '../lib/config'
import { createService, createAuthenticatedService } from '../lib/http'
import {
  BondPaymentOptionType,
  FullPaymentOptionType,
} from '../../components/BookingPayment/BookingPaymentOption'
import {
  RentalAncillaryOptions,
  GetRentalPriceAvailability,
  InvoicePayment,
  RentalActivityBase,
  RentalActivityPriced,
  SearchRentalPriceAvailability,
  PendingReservation
} from '../models/api-models'
import { CampstayData } from '../models/campstay-models'
import {
  BookingParameters,
  Depots,
  DepotSchedulesModel,
  GuestRegistrationDetail,
  ILoyaltyProgramMembershipDetail,
  InvoiceWithRedemptions,
  PointsRedemptionRequest,
  PointsRedemptionResult,
  Quotation,
  Relocation,
  SearchParameters,
  SupportedLocale,
} from '../models/search-models'
import { BookingStore } from '../stores/booking-store'
import { PaymentStore } from '../stores/payment-store'
import { WorkTracker } from '../../components/LoadingPanel/work-tracker'
import { requiresMembershipNumber } from '../helpers/partner-helpers'
import { currentLanguagePreference, currentLocale } from './MetadataService'
import { CreateRentalQuotationWithMeta } from '../models/createRentalQuotationWithMeta'
import { ApiResult, IApiResult } from '../models/apiResult'

export interface SearchResultChallenge {
  signedRetryToken: string
}

export interface SearchResultChallengeResponse {
  signedRetryToken: string
  captchaResponse: string
}

export interface BookingSearchResult {
  results: BookingSearchResultItem[]
  specialCandidates: SearchResultMatchDetail[]
  metadata: SearchMetadata
  challenge?: SearchResultChallenge
}

export interface BookingSearchResultItem {
  result: RentalActivityBase
  total: number
}

export interface ScoredAndRevisedSearchResult {
  score: number

  startDaysDelta: number
  endDaysDelta: number

  matchesOrigin: boolean
  matchesDestination: boolean
  touchesPossibleOrigin: boolean
  touchesPossibleDestination: boolean

  requestedStartDateTime: string
  requestedStartLocationCode: string
  requestedEndDateTime: string
  requestedEndLocationCode: string

  revisedStartDateTime: string
  revisedStartLocationCode: string
  revisedEndDateTime: string
  revisedEndLocationCode: string
}
export interface SearchResultMatchDetail extends ScoredAndRevisedSearchResult {
  deal: Relocation
}

export interface SearchMetadata {
  priceAvailabilityHeatmap: PriceAvailabilityHeatmapData
}

export interface PriceAvailabilityHeatmapData {
  priceData: HeatmapDataMap
  availabilityData: HeatmapDataMap
}

export interface HeatmapDataMap {
  name: string
  data: XYDataPoint[]
}

export interface XYDataPoint {
  x: Date | string
  y: any
}

export interface ILoyaltyNumberValidationDetail {
  loyaltyProgramType: string
  loyaltyProgramNumber: string
  surname: string
}

export interface ILoyaltyNumberValidationResult {
  status: string
  message: string
}

const searchService = createAuthenticatedService(config.searchServiceUrl)
const bookingService = createAuthenticatedService(config.bookingServiceUrl)
const vendorService = createAuthenticatedService(config.vendorServiceUrl)
const campStayService = createService(config.campStayUrl)

const FAKE_MEMBERSHIP_NUMBER: string = 'FAKED_BY_B2C'

const product = (vehicleCode: string) => ({ product: { code: vehicleCode } })
const journey = (startDate: Date, endDate: Date, startDepotCode: string, endDepotCode: string) => ({
  journey: {
    startDateTime: startDate,
    endDateTime: endDate,
    startDepot: { code: startDepotCode },
    endDepot: { code: endDepotCode },
  },
})
const partner = (partnerCode: string) => ({ partner: { code: partnerCode } })
const driverLicenceCountry = (driverLicenceCountry: string) => {
  // Force UK to GB as UK is not a valid option in Vibe
  if (driverLicenceCountry.toUpperCase() === "UK")
    driverLicenceCountry = "GB"

  return {
    driverLicenceCountry: { code: driverLicenceCountry },
  }
}
const productType = (productType: string) => ({ productType: { code: productType } })
const pax = (adults: number, children: number, infants: number) => {
  const ages = []
  const sixtyDaysAgo = new Date(); sixtyDaysAgo.setDate(sixtyDaysAgo.getUTCDate() - 60);
  const fourHunderedDaysAgo = new Date(); fourHunderedDaysAgo.setDate(fourHunderedDaysAgo.getUTCDate() - 400);

  for (var infantIndex = 0; infantIndex < infants; infantIndex++) { ages.push(sixtyDaysAgo) }
  for (var childIndex = 0; childIndex < children; childIndex++) { ages.push(fourHunderedDaysAgo) }

  return {
    pax: {
      adult: adults,
      childrenDOB: ages,
      total: (adults || 1) + (children || 0) + (infants || 0)
    }
  }
}
const ratePlan = (ratePlanCode: string) => ({ ratePlan: { code: ratePlanCode } })
const anc = (coverCode: string, extras: { quantity: number; product: { code: string } }[]) => ({
  ancillaries: { equipmentAndServices: extras, coverage: { product: { code: coverCode } } },
})

const createSearchRequest = (params: SearchParameters) => {
  let request: SearchRentalPriceAvailability = {
    journey: {
      startDateTime: toDateAsUtc(params.pick_up_date),
      endDateTime: toDateAsUtc(params.drop_off_date),
      startDepot: { code: params.pick_up_location },
      endDepot: { code: params.drop_off_location },
    },
    ...driverLicenceCountry(params.drivers_licence),
    ...productType(params.vehicle_type),
    ...partner(params.partner_code),
    youngestDriverAge: +params.driver_age,
    ...pax(+params.adults, +params.children, +params.infants),
    membershipNo: params.membership_number || FAKE_MEMBERSHIP_NUMBER,
    loyaltyProgramNo: FAKE_MEMBERSHIP_NUMBER,
    signedRetryToken: params.signedRetryToken,
    captchaResponse: params.captchaResponse
  }

  if (params.promo_code) request = { ...request, ...ratePlan(params.promo_code) }

  if (params.hotdeal_id) {
    if (params.hotdeal_id.includes(':'))
      request.hotDealControl = { number: params.hotdeal_id.split(':')[1] }
    else
      request.hotDealControl = { number: params.hotdeal_id }
  }

  if (params.relocation_id) {
    if (params.relocation_id.includes(':'))
      request.relocationControl = { number: params.relocation_id.split(':')[1] }
    else
      request.relocationControl = { number: params.relocation_id }
  }

  return request
}

const createRentalPriceAvailabilityRequest = (
  params: BookingParameters,
  coverCode?: string,
  extras?: { quantity: number; product: { code: string } }[]
) => {
  let request: GetRentalPriceAvailability = {
    ...createSearchRequest(params),
    ...ratePlan(params.rate_plan_code),
    ...product(params.vehicle_code),
    membershipNo: params.membership_number || FAKE_MEMBERSHIP_NUMBER
  }

  if (coverCode || extras) {
    let ancillaries = {}

    if (coverCode)
      ancillaries = {
        ...ancillaries,
        coverage: { product: { code: coverCode } }
      }

    if (extras)
      ancillaries = {
        ...ancillaries,
        equipmentAndServices: extras
      }

    request = {
      ...request,
      ancillaries: ancillaries
    }
  }

  return request
}

export const getDepots = async (partnerCode: string, country: string, vehicleType: string): Promise<Depots> => {
  return (await searchService.get<Depots>(`getDepots/${partnerCode}/${country}/${vehicleType}?locale=${currentLocale()}`)).data
}

export const getDepotSchedules = async (partnerCode: string, country: string, vehicleType: string, pickup: string) => {
  return (
    await searchService.get<DepotSchedulesModel>(`v2/getSchedules/${partnerCode}/${country}/${vehicleType}/${pickup}/`)
  ).data
}

export const search = async (params: SearchParameters, challengeResponse?: SearchResultChallengeResponse): Promise<BookingSearchResult> => {
  const request = createSearchRequest(params)  
  return await searchService.post<BookingSearchResult>('v2/getVehicles/', request)
    .then(r => r.data)
    .catch(error => {
      // if (!error.response || error.response.status === 403)
      //   window.location.reload()

      throw error
    })
}

export const getPriceAvailProduct = async (
  params: BookingParameters,
  coverCode?: string,
  extras?: { quantity: number; product: { code: string } }[]
): Promise<RentalActivityPriced> => {
  const request = createRentalPriceAvailabilityRequest(params, coverCode, extras)
  return (await searchService.post<RentalActivityPriced>('v2/priceAvail/product', request)).data
}

export const getProductAlternatives = async (
  params: BookingParameters
): Promise<BookingSearchResult> => {
  const request = createRentalPriceAvailabilityRequest(params)
  return (await searchService.post<BookingSearchResult>('v2/priceAvail/alternatives/', request))
    .data
}

export const getAncillaries = async (
  params: BookingParameters,
  coverCode?: string
): Promise<RentalAncillaryOptions> => {
  const request = createRentalPriceAvailabilityRequest(params, coverCode)
  return (await searchService.post('v2/getVehicleAncillaries/', request)).data
}

export const createQuotation = async (
  params: SearchParameters,
  vehicleCode: string,
  coverCode: string,
  ratePlanCode: string,
  extras: { quantity: number; product: { code: string } }[],
  customer: {
    title?: string | undefined
    firstName?: string | undefined
    surname: string
    email?: string | undefined
    telephone?: string | undefined
    membershipNumber: string | undefined
    dateOfBirth: Date
    loyaltyProgramMembershipDetail?: ILoyaltyProgramMembershipDetail
  },
  sendCustomerEmail?: boolean | undefined,
  subscribedNewsletterKeys?: string[] | undefined,
  preRegistrationDetails?: GuestRegistrationDetail
): Promise<IApiResult<Quotation>> => {

  const request: CreateRentalQuotationWithMeta = { 
    ...product(vehicleCode),
    ...journey(
      toDateAsUtc(params.pick_up_date),
      toDateAsUtc(params.drop_off_date),
      params.pick_up_location,
      params.drop_off_location
    ),
    ...partner(params.partner_code),
    ...ratePlan(ratePlanCode),
    ...anc(coverCode, extras),
    ...pax(+params.adults, +params.children, +params.infants),
    customer: {
      ...customer,
      ...driverLicenceCountry(params.drivers_licence),
      preferredLanguage: currentLanguagePreference(),
    },
    sendCustomerEmail: sendCustomerEmail,
    campaignKeys: subscribedNewsletterKeys,
    loyaltyProgramMembershipDetail: customer.loyaltyProgramMembershipDetail,
    preRegistrationDetails: preRegistrationDetails
  }

  request.membershipNo = customer.membershipNumber || params.membership_number || request.membershipNo
  if (requiresMembershipNumber(params.partner_code))
    request.membershipNo = request.membershipNo || FAKE_MEMBERSHIP_NUMBER

  if (params.relocation_id)
    request.relocationControl = { number: params.relocation_id.split(':')[1] }

  if (params.hotdeal_id)
    request.hotDealControl = { number: params.hotdeal_id.split(':')[1] }

  const result = await bookingService.post('v2/quotation', request)
  if (result.status === 200) return ApiResult<Quotation>({data: result.data})

  return ApiResult<Quotation>({error: result.data})
}

export const validateLoyaltyNumber = async (detail: ILoyaltyNumberValidationDetail) => {
  return (await bookingService.post<ILoyaltyNumberValidationResult>('v2/loyalty/validate', detail)).data
}

export const appendLoyaltyNumber = async (reservationNumber: string, detail: ILoyaltyProgramMembershipDetail) => {
  return (await bookingService.put<PendingReservation>(`v2/quotation/${reservationNumber}/appendLoyalty`, detail)).data
}

export const getQuotation = async (reservationNumber: string, surname: string) => {
  return await bookingService.get(`v2/quotation/${reservationNumber}/${surname}`)
    .then(result => ApiResult<Quotation>({data: result.data}))
    .catch(reason => ApiResult<Quotation>({error: reason}))
}

export const getInvoice = async (customerInvoiceId: string) => {
  return await bookingService.get(`v2/invoice/${customerInvoiceId}`)
    .then(result => ApiResult<InvoiceWithRedemptions>({data: result.data}))
    .catch(reason => ApiResult<InvoiceWithRedemptions>({error: reason}))
}

export const getInvoicePayments = async (customerInvoiceId: string) => {
  return (await bookingService.get<InvoicePayment[]>(`v2/invoice/${customerInvoiceId}/payment`))
    .data
}

export const getDelegatedPaymentUrl = async (
  customerInvoiceId: string,
  reservationNumber: string,
  registerCode: string,
  surname: string,
  paymentOption: FullPaymentOptionType | BondPaymentOptionType,
  locale?: SupportedLocale | undefined
): Promise<string> => {
  return (
    await bookingService.put(
      `v2/invoice/${customerInvoiceId}/delegated/?locale=${locale || ''}`,
      {
        reservationNumber: reservationNumber,
        code: registerCode,
        surname: surname,
        type: paymentOption.type,
        amount: paymentOption.payNow,
        currency: paymentOption.currency,
      }
    )
  ).data
}

export const registerPointsPayment = async (pointsProvider: string, payload: PointsRedemptionRequest) => {
  return (await bookingService.post<PointsRedemptionResult>(`v2/invoice/points/${pointsProvider}`, payload)).data
}

export const isCustomerAddressDetailKnown = async (reservationId: string, surname: string) => {
  return (await bookingService.get<boolean>(`v2/booking/${reservationId}/${surname}/hasAddressDetails`)).data
}

export const attachAddressDetailToCustomer = async (reservationId: string, surname: string, detail: GuestRegistrationDetail) => {
  return (await bookingService.post<boolean>(`v2/booking/${reservationId}/${surname}/attachAddressDetail`, detail)).data
}

export const postCampstay = async (
  element: CampstayData,
  location: string,
  id: number,
  adults?: string,
  children?: string,
  startdate?: string,
  enddate?: string,
  endUrl?: string
) => {
  return await campStayService
    .post(`${id}/availRate`, {
      noOfRooms: '1',
      adults: adults ? adults : '',
      children: children ? children : '',
      infants: '0',
      start: startdate ? startdate : '',
      end: enddate ? enddate : '',
    })
    .then(function (result) {
      element['CSName'] = result.data.propertyName
      if (result.data.roomTypes[0].isAvailable === true) {
        let PropertyPrice = 0
        if (result.data.roomTypes[0].rateTypes[0].totalPrice === undefined) {
          result.data.roomTypes[0].rateTypes[0].charges.forEach((item: any) => {
            Object.keys(item).map(k => {
              PropertyPrice = PropertyPrice + result.data.roomTypes[0].rateTypes[0].charges[k].price
              return PropertyPrice
            })
          })
        } else {
          PropertyPrice = result.data.roomTypes[0].rateTypes[0].totalPrice
        }
        element['CSLink'] = element.CSLink + endUrl
        element['CSRoomType'] = result.data.roomTypes[0].name
        element['CSPrice'] = 'AU$' + PropertyPrice
        element['CSUrl'] = getCampStayURL(location, endUrl)
      } else {
        element['CSRoomType'] = result.data.roomTypes[0].name
        element['CSLink'] = element.CSLink + endUrl
        element['CSPrice'] = 'Check availability'
        element['CSUrl'] = getCampStayURL(location, endUrl)
      }
      let r = {
        element: element,
      }
      return r
    })
}

export const getCampstay = (store: BookingStore | PaymentStore, tracker: WorkTracker) => {
  const location = store.pickupDropoffInfo?.startDepot.code
  const adults = store.quotation?.pax.adult.toString()
  const children = (
    Number(store.quotation?.pax.total) - Number(store.quotation?.pax.adult)
  ).toString()
  const startdate = store.pickupDropoffInfo?.startDateTime.toString().split('T')[0]
  const enddate = store.pickupDropoffInfo?.endDateTime.toString().split('T')[0]

  let EndUrl = ''
  EndUrl = EndUrl + '?startdate=' + startdate
  EndUrl = EndUrl + '&enddate=' + enddate
  EndUrl = EndUrl + '&adults=' + adults
  EndUrl = EndUrl + '&children=' + children

  for (const item of Object.entries(campStayResponse)) {
    const key = item[0]
    const value = item[1]
    if (key === location) {
      store.campstayOptionsResponse = value.data
      store.campstayOptionsResponse.forEach(async element => {
        if (element.id !== 0) {
          const r = await tracker.track(
            postCampstay(
              element,
              location,
              element.id,
              adults,
              children,
              startdate,
              enddate,
              EndUrl
            )
          )
          element = r.element
        }
      })
    }
  }
}

export const getCampStayURL = (place?: string, endURL?: string) => {
  let baseURL = 'http://apollo.campstay.com/caravan-parks'
  switch (place) {
    case 'ADL':
      baseURL = baseURL + '/sa/adelaide' + endURL

      break

    case 'ASP':
      baseURL = baseURL + '/northern-territory/alice-springs/alice-springs'

      break

    case 'BNE':
      baseURL = baseURL + '/qld/brisbane'

      break

    case 'BME':
      baseURL = baseURL + "wa/australia's-north-west/broome"

      break

    case 'CNS':
      baseURL = baseURL + '/queensland/tropical-north-queensland/cairnse'

      break

    case 'DRW':
      baseURL = baseURL + '/nt/darwin'

      break

    case 'HBA':
      baseURL = baseURL + '/tas/hobart-and-the-southe'

      break

    case 'MEL':
      baseURL = baseURL + '/vic/melbourne'

      break

    case 'PER':
      baseURL = baseURL + '/wa/perth'

      break

    case 'SYD':
      baseURL = baseURL + '/nsw/sydney'

      break
  }

  return baseURL
}

export const getRelocation = async (relocationId: string) => {
  return (await vendorService.get(`v2/relocation/${relocationId}`))
    .data as Relocation
}

export const getHotdeal = async (relocationId: string) => {
  return (await vendorService.get(`v2/hot_deal/${relocationId}`))
    .data as Relocation
}