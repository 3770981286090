import React from 'react'
import './TermsAndConditions.scss'
import {termsConditionsLinkFor} from '../../domain/services/MetadataService'
import { FormattedMessage } from 'react-intl'
import { VendorCode } from '../../domain/models/search-models'

export interface ITermsAndConditionsProps {
  onChecked: () => void
  checked: boolean
  partnerCode: string | undefined
  vendorCode: VendorCode | undefined
  country: string | undefined
}
const TermsAndConditions = (props: ITermsAndConditionsProps) => {
  const url = props.partnerCode && props.country && termsConditionsLinkFor(props.partnerCode, props.country, props.vendorCode)
  const termsLink = (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      <FormattedMessage id="terms_and_conditions_text" />
    </a>
  )

  return (
    <div className='tc checkbox'>
      <input onChange={() => props.onChecked()} id='termsandcondition' type='checkbox' checked={props.checked} />
      <label htmlFor='termsandcondition'>
        <FormattedMessage id="terms_and_conditions_link" values={{link: termsLink}} />
      </label>
    </div>
  )
}

export default TermsAndConditions
