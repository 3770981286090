import {Grid} from '@mui/material'
import React from 'react'
import {BookingSummaryLineItemModel, tryRenderSummaryLine} from '../../domain/models/bookingSummaryModel'
import './BookingSummaryItem.scss'
import { formatMoney } from '../../domain/helpers/number-formatters'
import { useIntl } from 'react-intl'

const BookingSummaryItem = ({item}: {item: BookingSummaryLineItemModel}) => {
  const intl = useIntl()
  const headerValue = item.description
  const priceBreakdownValue = tryRenderSummaryLine(intl, item.pricingExplanationTemplate, item.pricingExplanation)

  return (
    <Grid item className='booking-summary-item'>
      <div className='booking-summary-item__description'>
        <div className='description'>{headerValue || priceBreakdownValue}</div>
        {headerValue && (
          <div className='explanation'>{priceBreakdownValue}</div>
        )}
      </div>
      <div className='booking-summary-item__value'>{formatMoney(item.amount)}</div>
    </Grid>
  )
}

export default BookingSummaryItem
