import React from 'react'
import IPartnerConfirmationComponent from './component-interface'
import './ThankyouBlurb.scss'
import { isPartner } from '../../../domain/helpers/partner-helpers'
import { ReservationCustomer } from '../../../domain/models/api-models'
import * as VendorHelper from '../../../domain/helpers/vendor-helpers'
import { VendorCode } from '../../../domain/models/search-models'
import { countryNameFrom, currentLocale } from '../../../domain/services/MetadataService'

interface IBlurbDetails {
  customer: ReservationCustomer
  country: string
  reservationNumber: string
}

export const ThankyouBlurb = (props: IPartnerConfirmationComponent) => {
  const quote = props.store.quotation!
  const partnerCode = props.store.partner.code
  const vendorCode = props.store.vendor.code as VendorCode
  const country = quote.rental.startDepot!.country!

  var blurb: JSX.Element | null = null
  const blurbParams: IBlurbDetails = {
    customer: quote.customer,
    country: countryNameFrom(country.code!, currentLocale()) || country.name!,
    reservationNumber: props.store.reservationNumber!
  }
  
  if (VendorHelper.isApolloCars(vendorCode))
    blurb = ApolloCarsBlurb(blurbParams)
  else if (isPartner(partnerCode, 'apollo'))
    blurb = ApolloBlurb(blurbParams)
  else if (isPartner(partnerCode, 'cheapa'))
    blurb = CheapaBlurb(blurbParams)
  else if (isPartner(partnerCode, 'hippie'))
    blurb = HippieBlurb(blurbParams)
  else if (isPartner(partnerCode, 'star'))
    blurb = StarBlurb(blurbParams)
  else if (isPartner(partnerCode, 'bunk'))
    blurb = BunkBlurb(blurbParams)
  else
    blurb = DefaultBlurb(blurbParams)

  return (
    <div className="thankyou-blurb">
      {blurb}
    </div>
  )
}

const ApolloBlurb = ({customer, country}: IBlurbDetails) => {
  return ({
    "en-GB": (
      <>
        <p>
          Thank you for choosing Apollo RV Holidays for your road trip.<br />
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>.<br />
          We look forward to helping you explore {country} soon.
        </p>
  
        <p>
          Happy travels,<br />
          The Apollo Team.
        </p>
      </>
    ),
    "de-DE": (
      <>
        <p>
          Vielen Dank dafür, dass Sie sich für Apollo RV Holidays entschieden haben.<br />
          Eine Bestätigung wurde an Ihre E-Mail-Adresse gesendet <span id="customer_email">{customer.email || 'you'}</span>.<br />
          Wir freuen uns darauf, Ihnen bald dabei zu helfen, {country} zu erkunden.
        </p>
  
        <p>
          Gute Reise,<br />
          Ihr Apollo-Team
        </p>
      </>
    )
  })[currentLocale()]
}

const CheapaBlurb = ({customer, country}: IBlurbDetails) => {
  return ({
    "en-GB": (
      <>
        <p>
          Thank you for choosing Cheapa Campa for your road trip.<br />
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>.<br />
          We look forward to helping you explore {country} soon.
        </p>

        <p>
          Happy travels,<br />
          The Cheapa Team.
        </p>
      </>
    ),
    "de-DE": (
      <>
        <p>
          Vielen Dank dafür, dass Sie sich für Cheapa Campa entschieden haben.<br />
          Eine Bestätigung wurde an Ihre E-Mail-Adresse gesendet <span id="customer_email">{customer.email || 'you'}</span>.<br />
          Wir freuen uns darauf, Ihnen bald dabei zu helfen, {country} zu erkunden.
        </p>

        <p>
          Gute Reise,<br />
          Ihr Cheapa-Team
        </p>
      </>
    )
  })[currentLocale()]
}

const HippieBlurb = ({customer, country}: IBlurbDetails) => {
  return ({
    "en-GB": (
      <>
        <p>
          Thank you for choosing Hippie Camper for your road trip.<br />
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>.<br />
          We look forward to helping you explore {country} soon.
        </p>

        <p>
          Happy travels,<br />
          The Hippie Team.
        </p>
      </>
    ),
    "de-DE": (
      <>
        <p>
          Vielen Dank dafür, dass Sie sich für Hippie Camper entschieden haben.<br />
          Eine Bestätigung wurde an Ihre E-Mail-Adresse gesendet <span id="customer_email">{customer.email || 'you'}</span>.<br />
          Wir freuen uns darauf, Ihnen bald dabei zu helfen, {country} zu erkunden.
        </p>

        <p>
          Gute Reise,<br />
          Ihr Hippie-Team
        </p>
      </>
    )
  })[currentLocale()]
}

const StarBlurb = ({customer, country}: IBlurbDetails) => {
  return ({
    "en-GB": (
      <>
        <p>
          Thank you for choosing Star RV for your road trip.<br />
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>.<br />
          We look forward to helping you explore {country} soon.
        </p>

        <p>
          Happy travels,<br />
          The Star RV Team.
        </p>
      </>
    ),
    "de-DE": (
      <>
        <p>
          Vielen Dank dafür, dass Sie sich für Star RV entschieden haben.<br />
          Eine Bestätigung wurde an Ihre E-Mail-Adresse gesendet <span id="customer_email">{customer.email || 'you'}</span>.<br />
          Wir freuen uns darauf, Ihnen bald dabei zu helfen, {country} zu erkunden.
        </p>

        <p>
          Gute Reise,<br />
          Ihr Star RV-Team
        </p>
      </>
    )
  })[currentLocale()]
}

const BunkBlurb = ({customer, reservationNumber}: IBlurbDetails) => {
  const tripIdeasUrl = "https://www.bunkcampers.com/en/holiday-advice/"

  return ({
    "en-GB": (
      <>
        <p>
          Thank you for booking with Bunk Campers! Your reservation number is <strong>{reservationNumber}</strong>.
        </p>
        
        <p>
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>. Whilst you are waiting, why not visit 
          the <a target='_blank' rel='noopener noreferrer' href={tripIdeasUrl}>Trip Ideas</a> section 
          of our website for information and inspiration for your upcoming road trip!
        </p>

        <p>
          Kind regards,<br />
          The team at Bunk Campers.
        </p>
      </>
    ),
    "de-DE": (
      <>
        <p>
          Thank you for booking with Bunk Campers! Your reservation number is <strong>{reservationNumber}</strong>.
        </p>
        
        <p>
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>. Whilst you are waiting, why not visit 
          the <a target='_blank' rel='noopener noreferrer' href={tripIdeasUrl}>Trip Ideas</a> section 
          of our website for information and inspiration for your upcoming road trip!
        </p>

        <p>
          Kind regards,<br />
          The team at Bunk Campers.
        </p>
      </>
    )
  })[currentLocale()]
}

const ApolloCarsBlurb = ({customer, country}: IBlurbDetails) => {
  return ({
    "en-GB": (
      <>
        <p>
          Thank you for choosing Apollo Cars for your road trip.<br />
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>.<br />
          We look forward to helping you explore {country} soon.
        </p>

        <p>
          Happy travels,<br />
          The Apollo Team.
        </p>
      </>
    ),
    "de-DE": (
      <>
        <p>
          Vielen Dank dafür, dass Sie sich für Apollo Cars entschieden haben.<br />
          Eine Bestätigung wurde an Ihren Posteingang gesendet.<br />
          Wir freuen uns darauf, Ihnen bald dabei zu helfen, {country} zu erkunden.
        </p>

        <p>
          Gute Reise,<br />
          Ihr Apollo-Team
        </p>
      </>
    )
  })[currentLocale()]
}

const DefaultBlurb = ({customer, country}: IBlurbDetails) => {
  return ({
    "en-GB": (
      <>
        <p>
          Thank you for choosing us for your road trip.<br />
          A confirmation email has been sent to <span id="customer_email">{customer.email || 'you'}</span>.<br />
          We look forward to helping you explore {country} soon.
        </p>

        <p>Happy travels!</p>
      </>
    ),
    "de-DE": (
      <>
        <p>
          Vielen Dank dafür, dass Sie sich für Apollo RV Holidays entschieden haben.<br />
          Eine Bestätigung wurde an Ihren Posteingang gesendet.<br />
          Wir freuen uns darauf, Ihnen bald dabei zu helfen, {country} zu erkunden.
        </p>

        <p>
          Gute Reise,<br />
          Ihr Apollo-Team
        </p>
      </>
    )
  })[currentLocale()]
}