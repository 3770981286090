import React from 'react'
import {Grid} from '@mui/material'
import {useHistory} from 'react-router-dom'
import './GoBackButton.scss'
import { FormattedMessage } from 'react-intl'
export const GoBackButton = () => {
  const history = useHistory()
  return (
    <Grid item xs={12} sm={2}>
      <FormattedMessage id="action-buttons.go-back">
        {(t) => (
          <input
            type='button'
            value={t as string}
            className='go-back-button__go-back'
            onClick={() => history.goBack()}
          />
        )}
      </FormattedMessage>
    </Grid>
  )
}
