import {action, computed, observable} from 'mobx'
import {defineMessages} from 'react-intl'
import {PaymentOptionType} from '../../components/BookingPayment/BookingPaymentOption'
import {WorkTracker} from '../../components/LoadingPanel/work-tracker'
import {
  InvoicePayment,
  RentalQuotation,
  CoverageProductInfo,
  PaymentRegisterInfo,
  RentalActivityPriced
} from '../models/api-models'
import {CampstayData} from '../models/campstay-models'
import BookingSummaryModel from '../models/bookingSummaryModel'
import {Quotation, CoverOption, BookingParameters, SupportedLocale, GuestRegistrationDetail, ILoyaltyProgramMembershipDetail, PointsRedemptionResultPayload, PointsRedemptionRequest, InvoiceWithRedemptions} from '../models/search-models'
import * as Api from '../services/Api'
import * as Analytics from '../../utils/analytics'
import * as MetadataService from '../services/MetadataService'
import { formatForUrl } from '../helpers/date-formatters'
import { InvoiceDetail, paymentOptionsFrom } from '../helpers/payment-helpers'
import { calculatePossibleEarnRatesFrom } from '../services/LoyaltyPointsService'

const messages = defineMessages({
  getPaymentUrlLoadingMessage: {
    id: 'payment.store.get.payment.url.loading.message'
  },
  registerPointsPaymentLoadingMessage: {
    id: 'payment.store.registerPointsPaymentLoadingMessage'
  },
  secureBookingLoadingMessage: {
    id: 'payment.store.secure.booking.loading.message'
  },
  backFromPaymentGatewayLoadingMessage: {
    id: 'payment.store.back.from.payment.gateway.loading.message'
  },
  loadingVehicleAvailabilityLoadingMessage: {
    id: 'payment.store.vehicle.availability.loading.message'
  },
})

interface RedemptionBalance {
  totalPoints: number
  totalAmount: number
  earliestExpiration: Date | undefined
}

export interface ConfirmationBalance {
  isPaidInFull: boolean
  refundBond: number
  payable: number
  refundable: number
  chargesAtPickup: number
  adminFee: number
  paid: number
  currency: string
  redemptionBalance: RedemptionBalance | null
  possiblyIncursSurcharge: boolean
}

export class PaymentStore {
  @observable public explicitlySelectedPaymentOption: PaymentOptionType | null = null
  @observable public quotation: Quotation | null = null
  @observable public productAvailabilityVerificationResponse: RentalActivityPriced | null = null
  @observable private invoicePayments: InvoicePayment[] | null = null
  @observable private confirmedReservation: RentalQuotation | null = null
  @observable public invoiceResponse: InvoiceWithRedemptions | null = null
  @observable public paymentUrl: string | null = null
  @observable public bookingSecured: boolean = false
  @observable public termsAccepted: boolean = false
  @observable public campstayOptionsResponse: CampstayData[] | null = null
  @observable public vehicleStillAvailable: boolean | null = null
  @observable public quoteNotFound: boolean | null = null
  @observable private paymentOptionsSource: InvoiceDetail | null = null
  @observable public hasRegisteredLocationDetails: boolean = false

  constructor(readonly tracker: WorkTracker) {}

  @action async setQuotation(quotation: Quotation) {
    this.quotation = quotation
    await this.refreshQuotationDetails()
  }

  @action async setQuotationFrom(reservationNumber: string, surname: string) {
    const result = await this.tracker.track(Api.getQuotation(reservationNumber, surname), {
      message: messages.backFromPaymentGatewayLoadingMessage,
      analyticsKey: 'retrieve_quotation',
    })

    this.quotation = result.data || null
    this.quoteNotFound = !this.quotation

    await Promise.all([
      this.reloadVehicleAvailability(),
      this.refreshQuotationDetails()
    ])  
  }

  @action async refreshQuotationDetails() {
    if (!this.quotation || this.isCancelled) return

    const email = this.quotation?.customer.email
    if (email) Analytics.identifyUserAs(email)

    await Promise.all([
      this.loadInvoice(),
      this.loadPreviousPayments()
    ])

    if (this.isConfirmed) {
      this.confirmedReservation = this.quotation
      this.bookingSecured = true  
    }
  }

  @action async loadInvoice() {
    if (this.quotation) {
      var response = await this.tracker.track(
        Api.getInvoice(this.quotation.customerInvoiceId!),
        {message: messages.backFromPaymentGatewayLoadingMessage}
      )

      this.setInvoiceResponse(response.data!)
    }
  }

  @action async reloadInvoiceInBackground() {
    if (this.quotation && this.quotation.customerInvoiceId) {
      var response = await Api.getInvoice(this.quotation.customerInvoiceId)

      if (response.data) {
        this.setInvoiceResponse(response.data)
        return true
      }
    }

    return false
  }

  @action async loadPreviousPayments() {
    if (this.quotation) {
      this.invoicePayments = await this.tracker.track(
        Api.getInvoicePayments(this.quotation.customerInvoiceId!),
        {message: messages.backFromPaymentGatewayLoadingMessage}
      )
    }
  }

  @action async reloadVehicleAvailability() {
    this.vehicleStillAvailable = null
    this.vehicleStillAvailable = await this.checkVehicleAvailability()
  }

  @action async checkVehicleAvailability(): Promise<boolean> {
    if (!this.quotation || this.isCancelled) return false
    if (this.isConfirmed || this.isProvisional) return true

    try
    {
      this.productAvailabilityVerificationResponse = await this.tracker.track(
        Api.getPriceAvailProduct(
          this.searchParametersFromQuotation(this.quotation),
          this.selectedCover!.code
        ),
        {
          analyticsKey: 'verify_availability',
          message: messages.loadingVehicleAvailabilityLoadingMessage
        }
      )
    }
    catch
    {
      this.productAvailabilityVerificationResponse = null;
    }

    return !!this.productAvailabilityVerificationResponse && this.productAvailabilityVerificationResponse.inventoryStatus === 'Yes'
  }

  public searchParametersFromQuotation(quote: Quotation): BookingParameters {
    const startDateTime = new Date(quote.rental.startDateTime)
    const dateOfBirth = new Date(quote.customer.dateOfBirth)

    let age = Math.floor((startDateTime.getTime() - dateOfBirth.getTime()) / 365 / 24 / 60 / 60 / 1000)
    if (age > 2000) age = 25 // Sometimes the date of birth will be DateTime.MinValue. Rather than risk having a 2000 year old mummy through our branch, cap their age.

    const parameters: BookingParameters = {
      partner_code: quote.partner.code,
      country: quote.rental.startDepot!.country!.code,
      vehicle_type: 'RV',
      pick_up_location: quote.rental.startDepot!.code,
      drop_off_location: quote.rental.endDepot!.code,
      pick_up_date: formatForUrl(quote.rental.startDateTime),
      drop_off_date: formatForUrl(quote.rental.endDateTime),
      drivers_licence: quote.customer.driverLicenceCountry?.code || 'US', // TODO: Have this go through as nil/blank if not known
      adults: '1',
      children: '0',
      infants: '0',
      driver_age: age.toString(),
      vehicle_code: quote.rental.product!.code,
      rate_plan_code: quote.ratePlan.code
    }

    if (quote.inventoryControlNo && quote.ratePlan.name)
    {
      // HACK: Make this better when the new API made to verify availablity is available
      if (quote.ratePlan.name.toLowerCase().includes('hot deal'))
        parameters.hotdeal_id = quote.inventoryControlNo
      else
        parameters.relocation_id = quote.inventoryControlNo
    }

    return parameters
  }

  @action setTermsAccepted() {
    this.termsAccepted = !this.termsAccepted
  }

  @action
  public setSelectedPaymentOption(paymentOption: PaymentOptionType) {
    this.explicitlySelectedPaymentOption = paymentOption
  }

  @action
  public unsetSelectedPaymentOption() {
    this.explicitlySelectedPaymentOption = null
  }

  @action
  public async attachPreregistrationDetail(detail: GuestRegistrationDetail) {
    this.hasRegisteredLocationDetails = true

    const reservationNumber = this.quoteOrReservation.reservationNo
    const surname = this.quoteOrReservation.customer.surname
    Api.attachAddressDetailToCustomer(reservationNumber, surname, detail)
  }

  public async hasKnownAddressDetails() {
    if (this.hasRegisteredLocationDetails) return true

    const reservationNumber = this.quoteOrReservation.reservationNo
    const surname = this.quoteOrReservation.customer.surname
    const detailsAreKnown = await Api.isCustomerAddressDetailKnown(reservationNumber, surname)

    this.hasRegisteredLocationDetails = detailsAreKnown
    return detailsAreKnown
  }

  public getSelectedPaymentOption() {
    if (this.explicitlySelectedPaymentOption) return this.explicitlySelectedPaymentOption

    const possiblePaymentOptions = this.paymentOptions
    if (possiblePaymentOptions.length === 1) return possiblePaymentOptions[0]

    return null
  }

  public getSelectedPaymentOptionOrMinimum() {
    const bestGuessPaymentOption = this.getSelectedPaymentOption()
    if (bestGuessPaymentOption) return bestGuessPaymentOption

    return this.paymentOptions.sort((a, b) => a.payNow - b.payNow)[0]
  }

  public isSelectedPaymentOption(paymentOption: PaymentOptionType) {
    const selected = this.getSelectedPaymentOption()
    return !!selected &&
      selected.type === paymentOption.type &&
      selected.payNow === paymentOption.payNow &&
      selected.currency === paymentOption.currency
  }

  private bestLookingPaymentRegister(): PaymentRegisterInfo | null {
    const options = this.invoiceResponse?.charge?.options
    if (!options) return null

    const preferred = options.flatMap(o => {
      return o.registers.filter(r => {
        return r.supportsDelegatedPayment === true && r.code.startsWith("DPS")
      })
    })

    return preferred.length > 0 ? preferred[0] : options[0].registers[0]
  }

  @action async appendLoyaltyNumberToQuote(detail: ILoyaltyProgramMembershipDetail) {
    if (!this.quotation) return

    return this.tracker.track(
      Api.appendLoyaltyNumber(this.quotation.reservationNo, detail),
      {analyticsKey: 'append_loyalty_number'}
    )
  }

  @action async getPaymentUrl() {
    this.paymentUrl = await this.tracker.track(
      Api.getDelegatedPaymentUrl(
        this.quotation?.customerInvoiceId || '',
        this.quotation?.reservationNo || '',
        this.bestLookingPaymentRegister()?.code || '',
        this.quotation?.customer.surname || '',
        this.getSelectedPaymentOption()!,
        MetadataService.explicitlySetLocale() as SupportedLocale | undefined
      ),
      {message: messages.getPaymentUrlLoadingMessage}
    )

    return this.paymentUrl
  }

  @action async registerPointsRedemption(pointsProvider: string, pointsRedemptionResultPayload: PointsRedemptionResultPayload) {
    if (!this.quotation) return null

    const invoiceId = this.invoiceResponse?.id
    if (!invoiceId) return null

    pointsRedemptionResultPayload.currency = this.rental.totalPrice?.currency

    const payload: PointsRedemptionRequest = {
      invoiceId: invoiceId,
      reservationNumber: this.quotation.reservationNo,
      customerSurname: this.quotation.customer.surname,
      pointsProviderResult: pointsRedemptionResultPayload
    }

    const result = await this.tracker.track(Api.registerPointsPayment(pointsProvider, payload), {
      message: messages.registerPointsPaymentLoadingMessage,
      analyticsKey: 'register_points_payment'
    })

    this.quotation = result.reservation
    this.setInvoiceResponse(result.invoiceDetail)

    return result
  }

  @computed
  get anyWithLoyaltyPoints(): boolean {
    const rentalDetail = this.productAvailabilityVerificationResponse || this.confirmedReservation
    if (!rentalDetail) return false

    const points = calculatePossibleEarnRatesFrom(rentalDetail)
    return !!points && Object.values(points).some(v => v > 0)
  }

  @computed get quoteOrReservation() {
    return this.confirmedReservation || this.quotation!
  }

  @computed get rental() {
    return this.quoteOrReservation.rental
  }

  @computed get customer() {
    return this.quoteOrReservation.customer
  }

  @computed get partner() {
    return this.quoteOrReservation.partner
  }

  @computed get vendor() {
    return this.quoteOrReservation.vendor
  }

  @computed get isConfirmed() {
    return !!this.quotation && this.quotation.isConfirmed
  }

  @computed get isProvisional() {
    return !!this.quotation && this.quotation.isProvisional
  }

  @computed get isCancelled() {
    return !!this.quotation && this.quotation.isCancelled
  }

  @computed get currentPointRedemptions() {
    return this.invoiceResponse?.redemptions?.filter(r => r.isCurrent)
  }

  @computed get currentAmountPaid() {
    return (this.invoicePayments || [])
      .filter(payment => payment.paymentRequirement === 'Charge')
      .reduce((total, payment) => total + Math.abs(payment.amount.amount), 0)
  }

  @computed get invoiceLoaded() {
    return !!this.paymentOptionsSource || !!this.invoiceResponse
  }

  @computed get paymentOptions() {
    const pointRedemptions = this.currentPointRedemptions

    return paymentOptionsFrom({
      invoice: this.paymentOptionsSource || this.invoiceResponse,
      bookingConfirmed: this.isConfirmed,
      pointsRedeemed: pointRedemptions ? Math.abs(pointRedemptions.reduce<number>((v, r) => v + (r.quantity?.quantity || 0), 0)) : 0,
      amountPaid: this.currentAmountPaid
    })
  }

  @action setInvoiceResponse(invoice: InvoiceWithRedemptions) {
    this.invoiceResponse = invoice
    this.paymentOptionsSource = null
  }

  public setPaymentOptionsSource(source: InvoiceDetail | null) {
    this.paymentOptionsSource = source
  }

  @computed get selectedCover(): CoverageProductInfo | undefined {
    return this.quoteOrReservation.ancillaries.coverage?.product
  }

  @computed get selectedCoverMetadata(): CoverOption | null {
    const product = this.selectedCover
    if (!product) return null

    const meta = MetadataService.coverMetadataFor(
      this.rental.startDepot!.country!.code,
      this.quotation!.vendor.code,
      new Date(this.rental.startDateTime)
    )

    const found = meta.coverOptions.find(m => m.code.toUpperCase() === product.code.toUpperCase())
    if (found) return found

    return {
      code: product.code,
      bondLabel: meta.bondLabel,
      displayName: product.name || '',
      description: product.description || '',
      inclusions: [
        'Please refer to our terms and conditions on our website'
      ]
    }
  }

  @computed get balancePayment(): ConfirmationBalance {
    const charge = this.invoiceResponse?.charge
    const currency = this.confirmedReservation?.rental.totalPrice?.currency || charge?.amount?.currency || charge?.defaultAmount?.currency || ''
    const refundBond = this.confirmedReservation?.ancillaries.coverage?.liability?.amount || 0
    const pointRedemptions = this.currentPointRedemptions

    let payable = 0
    let refundable = 0
    let chargesAtPickup = 0
    let remainingSurcharge = 0
    let amountPaid = this.currentAmountPaid
    let possiblyIncursSurcharge = !!charge && (charge.options || []).some(o => !!o.surchargePercent)

    if (charge) {
      let amount = charge.amount.amount
      let requiredAmount = charge.defaultAmount.amount
      let firstPayment = (this.invoicePayments || [])[0]

      if (charge.externalAmount)
        chargesAtPickup = charge.externalAmount.amount

      if (amount > 0) {
        payable = amount
      } else if (amount < 0 && requiredAmount > 0) {
        // TODO: Assumption: amount seems to be -ve, and defaultAmount seems to be +ve for overpayments
        refundable = Math.abs(amount)
      }

      if (firstPayment) {
        const chargeOption = charge.options?.find(option => {
          return option.reuseMethodId && option.reuseMethodId === firstPayment.reuseMethodId
        })

        if (chargeOption) {
          remainingSurcharge = chargeOption.amountCharged - payable
        }
      }
    }

    var redemptionBalance: RedemptionBalance | null = null
    if (pointRedemptions) {
      redemptionBalance = {
        totalPoints: Math.abs(pointRedemptions.reduce<number>((v, r) => v + (r.quantity?.quantity || 0), 0)),
        totalAmount: Math.abs(pointRedemptions.reduce<number>((v, r) => v + r.amount.amount, 0)),
        earliestExpiration: pointRedemptions.map(r => r.expiry).filter(e => !!e).sort()[0]
      }
    }

    return {
      refundBond,
      payable,
      paid: amountPaid,
      isPaidInFull: (this.invoiceResponse && !charge) || (!!this.confirmedReservation && amountPaid > 0 && payable === 0),
      adminFee: remainingSurcharge,
      currency,
      refundable,
      chargesAtPickup,
      redemptionBalance,
      possiblyIncursSurcharge
    }
  }

  // private applySurcharge(amount: number, surchargePercent: number) {
  //   return surchargePercent < 1 ? Math.round((amount / (1 - surchargePercent)) * 100) / 100 : amount
  // }

  @computed get reservationNumber() {
    return this.quoteOrReservation.reservationNo
  }

  @computed get bookingSummary() {
    return this.quotation && this.invoiceResponse
      ? new BookingSummaryModel(this.quotation, this.invoiceResponse, this.selectedCoverMetadata!, this.getSelectedPaymentOptionOrMinimum(), this.quotation.total)
      : null
  }

  @computed get pickupDropoffInfo() {
    if (!this.quotation) return null

    return {
      endDateTime: this.quotation.rental.endDateTime,
      startDateTime: this.quotation.rental.startDateTime,
      startDepot: {
        code: this.quotation.rental.startDepot?.code || '',
        name: this.quotation.rental.startDepot?.name || '',
      },
      endDepot: {
        code: this.quotation.rental.endDepot?.code || '',
        name: this.quotation.rental.endDepot?.name || '',
      },
      vehicleCode: this.quotation.rental.product?.code || '',
    }
  }

  @computed get retrySearchUrl() {
    if (!this.quotation) return null

    const params = this.searchParametersFromQuotation(this.quotation)
    return `/search/${params.partner_code}/${params.country}/${params.vehicle_type}/${params.pick_up_location}/${params.drop_off_location}/${params.pick_up_date}/${params.drop_off_date}/${params.drivers_licence}/${params.adults}/${params.children}/${params.driver_age}/${params.promo_code || ""}`
  }

  @action
  getCampStay(externalApiTracker: WorkTracker) {
    Api.getCampstay(this, externalApiTracker)
  }
}
