import moment from 'moment'

const urlDateFormat = 'YYYY-MM-DDTHHmm'
export const formatDate = (date: Date | string, format?: string) => moment.parseZone(date).format(format || 'll')
export const formatDay = (date: Date | string) => moment.parseZone(date).format('dddd')
export const formatDayTime = (date: Date | string) => moment.parseZone(date).format('dddd LT')
export const formatForUrl = (date: Date | string) => moment.parseZone(date).format(urlDateFormat)
export const formatForDatestamp = (date: Date | string) => moment.parseZone(date).format('yyyy-MM-dd')
export const toDateAsUtc = (date: string | Date, format?: string) => moment.utc(date, format || urlDateFormat).toDate()
export const toDate = (date: string | Date, format?: string) => moment(date, format || urlDateFormat).toDate()
