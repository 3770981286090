import {toDate} from '../helpers/date-formatters'
import {possiblePartnerKeysFor} from '../helpers/partner-helpers'
import {possibleVendorGroupsFor} from '../helpers/vendor-helpers'
import {SupportedLocale, VendorCode} from "../models/search-models";

export interface PartnerFilterableCandidate {
  partners?: string[]
}
export interface CountryFilterableCandidate {
  countries?: string[]
}
export interface LocaleFilterableCandidate {
  locale?: string
}
export interface DateFilterableCandidate {
  startDate?: string
  endDate?: string
}
export interface EnabledCandidate {
  enabled?: boolean
}
export interface VendorFilterableCandidate {
  vendorCodes?: string[]
}

export interface VendorGroupFilterableCandidate {
  vendorGroups?: string[]
}

function matchesFilter(filterCollection: string[] | undefined, matchData: string) {
  return filterCollection
    && filterCollection.some(i => i === '*' || i.toLowerCase() === matchData.toLowerCase())
}

export function filterCandidatesByPartnerCode<T extends PartnerFilterableCandidate>(candidates: T[], partnerCode: string): T[] {
  const partnerKeys = possiblePartnerKeysFor(partnerCode).filter(key => candidates.some(c => matchesFilter(c.partners, key)))
  if (!partnerKeys || partnerKeys.length === 0) return candidates.filter(c => !c.partners)

  return candidates.filter(c => c.partners && partnerKeys.some(p => c.partners?.includes(p)))
}

export function filterCandidatesByVendorCode<T extends VendorFilterableCandidate>(candidates: T[], vendorCode: VendorCode): T[] {
  const results = candidates.filter(c => matchesFilter(c.vendorCodes, vendorCode))
  if (results.length > 0) return results

  return candidates.filter(c => !c.vendorCodes)
}

export function filterCandidatesByVendorGroup<T extends VendorGroupFilterableCandidate>(candidates: T[], vendorCode: VendorCode): T[] {
  const vendorGroups = possibleVendorGroupsFor(vendorCode).filter(key => candidates.some(c => matchesFilter(c.vendorGroups, key)))
  if (!vendorGroups || vendorGroups.length === 0) return candidates.filter(c => !c.vendorGroups)
  
  return candidates.filter(c => c.vendorGroups && vendorGroups.some(g => c.vendorGroups?.includes(g)))
}

export function filterCandidatesByCountry<T extends CountryFilterableCandidate>(candidates: T[], country: string): T[] {
  const withCountry = candidates.filter(c => matchesFilter(c.countries, country))
  if (withCountry.length > 0) return withCountry

  return candidates.filter(c => !c.countries)
}

export function filterCandidatesByLocale<T extends LocaleFilterableCandidate>(candidates: T[], locale: SupportedLocale): T[] {
  const withLocale = candidates.filter(c => c.locale && c.locale === locale)
  if (withLocale.length > 0) return withLocale

  return candidates.filter(c => !c.locale)
}

export function filterCandidatesByDateRange<T extends DateFilterableCandidate>(candidates: T[], date: Date): T[] {
  const meetsDateRequirements = candidates.filter(c => c.startDate && toDate(c.startDate) <= date && (!c.endDate || toDate(c.endDate) >= date))
  if (meetsDateRequirements.length > 0) return meetsDateRequirements

  return candidates.filter(c => !c.startDate && !c.endDate)
}
