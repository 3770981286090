import axios, {AxiosInstance} from 'axios'
import {toast} from 'react-toastify'
import {config} from './config'
import {v4 as uuid} from 'uuid'

export const createAuthenticatedService = (baseURL: string) => {
  if (config.isDevelopment) return createService(baseURL)

  const service = axios.create({baseURL, withCredentials: true, headers: defaultHeaders()})
  return decorateService(service)
}

export const createService = (baseURL: string) => {
  const service = axios.create({baseURL, headers: defaultHeaders()})
  return decorateService(service)
}

const decorateService = (service: AxiosInstance) => {
  service.interceptors.response.use(
    async response => {
      // TODO Azure functions is hardcoded to return 204 for errors. Need to fix that.
      if (response.status === 204) {
        let errorMsg = 'An unknown error has occured. Please try again.'
        toast.error(errorMsg, {toastId: errorMsg})
        return Promise.reject(response)
      }
      return response
    },
    error => {
      let errorMsg = 'An unknown error has occured. Please try again.'
      const response = error.response
      if (response && response.data && response.data.detail) errorMsg = response.data.detail
      toast.error(errorMsg, {toastId: errorMsg})
      return Promise.reject(error)
    }
  )
  return service
}

const defaultHeaders = () => {
  return {
    common: {
      "X-Correlation-Id": clientCorrelationId()
    }
  }
}

const clientCorrelationId = () => {
  if (typeof (window as any)._clientCorrelationId === "undefined") {
    (window as any)._clientCorrelationId = uuid()
  }

  return (window as any)._clientCorrelationId
}