import { PartnerKey } from "../models/search-models"
import partnerConfig from "../../assets/partner-config.json"
import { CountryFilterableCandidate, PartnerFilterableCandidate, filterCandidatesByCountry, filterCandidatesByPartnerCode } from "./metadata-filtering-helpers"

interface PartnerContactNumber extends CountryFilterableCandidate, PartnerFilterableCandidate {
  phoneNumber: string
}

export interface PartnerMarketingKey extends PartnerFilterableCandidate {
  marketingKey: string,
  listId: string,
  default?: boolean
}

const partnerKeyMapping: {[key in PartnerKey]: string[]} = partnerConfig.partnerGroupings
const affiliatesRequiringMembershipNumbers: string[] = partnerConfig.partnerCodesRequiringMembershipNumbers
const contactNumbers: PartnerContactNumber[] = partnerConfig.contactDetails
const marketingKeys: PartnerMarketingKey[] = partnerConfig.marketingKeys

export const isPartner = (partnerCode: string, partnerKey: PartnerKey): boolean => {
  return partnerKeyMapping[partnerKey].includes(partnerCode.toLowerCase())
}

export const isBunkPartner = (partnerCode: string): boolean => {
  return isPartner(partnerCode, 'bunk')
}

export const possiblePartnerKeysFor = (partnerCode: string): PartnerKey[] => {
  return (Object.keys(partnerKeyMapping) as PartnerKey[]).filter(k => isPartner(partnerCode, k))
}

export const requiresMembershipNumber = (partnerCode: string): boolean => {
  return affiliatesRequiringMembershipNumbers.includes(partnerCode.toUpperCase())
}

export const contactNumberFor = (countryCode: string, partnerCode: string): string => {
  let candidates = filterCandidatesByCountry(contactNumbers, countryCode)
  candidates = filterCandidatesByPartnerCode(candidates, partnerCode)
  if (candidates.length === 0) return "+800 3260 5466"

  return candidates[0].phoneNumber
}

const defaultPartnerMarketingKeys = marketingKeys.filter(k => k.default)
export const marketingListKeysFor = (partnerCode: string | undefined): PartnerMarketingKey[] => {
  if (!partnerCode) return defaultPartnerMarketingKeys
  let candidates = filterCandidatesByPartnerCode(marketingKeys, partnerCode)
  if (candidates.length === 0) return defaultPartnerMarketingKeys

  return candidates
}
