import {Grid} from '@mui/material'
import React from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { formatMoney } from '../../domain/helpers/number-formatters'
import { Price } from '../../domain/models/api-models'
import { BookingSummaryLineItemModel, tryRenderSummaryLine } from '../../domain/models/bookingSummaryModel'

export interface Charge {
  description: string
  price?: Price
  rates: BookingSummaryLineItemModel[]
}

export interface IDailyRatesProps {
  charges: Charge[]
}
const DailyRates = ({charges}: IDailyRatesProps) => {
  const intl = useIntl()

  return charges && (
    <Grid item container className='daily-rates'>
      <Grid item container xs={12} className={'daily-rates__header'}>
        <Grid item xs={4} md={4}>
          <FormattedMessage id="headers.description" />
        </Grid>
        <Grid item xs={5} md={6}>
          <FormattedMessage id="headers.rate" />
        </Grid>
        <Grid item xs={3} md={2} style={{textAlign: 'right'}}>
          <FormattedMessage id="headers.amount" />
        </Grid>
      </Grid>

      {charges.map((charge: Charge, index: number) => {
        const shouldShowPriceLine = charge.rates.some(r => r.pricingExplanationTemplate) || (charge.price && charge.price.amount !== 0)

        return shouldShowPriceLine ? (
          <Grid key={index} item container xs={12} className={'daily-rates__item'}>
            {renderLine(charge, intl)}
          </Grid>
        ) : <></>
      })}
    </Grid>
  )
}

const renderLine = (charge: Charge, intl: IntlShape) => {
  return (
    <>
      <Grid item xs={4} md={4}>
        {charge.description}
      </Grid>
      <Grid item xs={5} md={6}>
        {charge.rates.map((it, i) => (
          <div key={i}>
            {it.pricingExplanationTemplate
              ? tryRenderSummaryLine(intl, it.pricingExplanationTemplate)
              : it.pricingExplanation!}
          </div>
        ))}
      </Grid>
      <Grid item xs={3} md={2} style={{textAlign: 'right'}}>
        {charge.price ? `${charge.price.currency} ${formatMoney(charge.price.amount)}` : '-' }
      </Grid>
    </>
  )
}

export default DailyRates
