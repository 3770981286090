import {action, computed, observable} from 'mobx'
import {defineMessages} from 'react-intl'
import {isArray} from 'util'
import {WorkTracker} from '../../components/LoadingPanel/work-tracker'
import {toDate} from '../helpers/date-formatters'
import {
  Depot,
  Depots,
  DepotSchedulesModel,
  Feature,
  OperatingHoursModel,
  SearchParameters,
  VendorCode,
} from '../models/search-models'
import {
  BookingSearchResult,
  getDepots,
  getDepotSchedules,
  search,
  SearchMetadata,
  SearchResultChallenge,
  SearchResultChallengeResponse,
  SearchResultMatchDetail,
  XYDataPoint,
} from '../services/Api'
import {partnerVendorSortOrderFor} from '../services/MetadataService'
import {SelectableProduct, Product} from './product'
import * as Analytics from '../../utils/analytics'
import { searchUrlFrom, configureUrlWithQueryFrom, searchParametersFrom } from '../helpers/url-helpers'
import { SpecialSearchResultCandidate } from '../models/specialSearchResultCandidate'

const messages = defineMessages({
  loadingMessage: {
    id: 'search.store.search.vehicle.loading.message'
  },
})

const possibleSearchFilters: Feature[] = [
  'stove',
  'fridge',
  'microwave',
  'shower',
  'aircon',
  'tv',
  'childseats',
  'automatic',
  'manual',
  'offroad',
  'GPS',
  'bikerack',
  'reversingcamera',
  'ovengrill',
  'heating',
]

export class GroupedResultset {
  public vendorCode: VendorCode
  public results: SelectableProduct[]

  constructor(
    private readonly _vendorCode: VendorCode,
    private readonly _products: Product[],
    private readonly _indexOffset: number
  ) {
    this.vendorCode = _vendorCode
    this.results = _products.map((product, index) => {
      return new SelectableProduct(product, _indexOffset + index)
    })
  }
}

export class SearchStore {
  @observable public searchResultsResponse: BookingSearchResult | null = null
  @observable public searchResultProducts: Product[] | null = null
  @observable public specialSearchResults: SpecialSearchResultCandidate[] | null = null
  @observable public searchResultMetadata: SearchMetadata | null = null
  @observable public searchResultsChallengeDetail: SearchResultChallenge | null = null
  @observable private depots: Depots | undefined
  @observable public pickupDepot: Depot | undefined
  @observable private pickupDepotSchedules: DepotSchedulesModel | undefined
  @observable public dropOffDepot: Depot | undefined
  @observable private dropOffDepotSchedules: DepotSchedulesModel | undefined
  @observable public pickupDate: Date | undefined
  @observable public dropOffDate: Date | undefined
  @observable public searchParameters: SearchParameters | null = null
  @observable private selectedFilters: Feature[] = []
  @observable public hideUnavailableFilter: boolean = true
  @observable public selectedBrands: string[] = []
  @observable public selectedSleepsAdults: number | null = null
  @observable public selectedSleepsChildren: number = 0
  @observable public selectedSleepsInfants: number = 0
  @observable public selectedPriceRangeFilter: [number, number] | undefined
  @observable public maximumPossibleChildren: number = 0
  @observable public maximumPossibleInfants: number = 0
  @observable public searchFilters: Feature[] = possibleSearchFilters
  
  constructor(readonly tracker: WorkTracker) {
  }

  private convertKeysToDates = (points: XYDataPoint[]): XYDataPoint[] => {
    return points.map(p => ({x: toDate(p.x, "DD MMM YYYY"), y: p.y}))
  }

  private normaliseMetadataKeys = (metadata: SearchMetadata): SearchMetadata => {
    const {availabilityData, priceData} = metadata.priceAvailabilityHeatmap

    return {
      priceAvailabilityHeatmap: {
        availabilityData: {
          name: availabilityData.name,
          data: this.convertKeysToDates(availabilityData.data)
        },
        priceData: {
          name: priceData.name,
          data: this.convertKeysToDates(priceData.data)
        }
      }
    }
  }

  @action
  public async search(challengeResponse?: SearchResultChallengeResponse) {
    if (!this.searchParameters) return

    Analytics.registerSearch(this)

    this.searchResultsChallengeDetail = null
    this.searchResultsResponse = null
    this.specialSearchResults = null
    this.searchResultProducts = null

    // ignore passenger counts when performing searches
    const params: SearchParameters = {
      ...this.searchParameters,
      ...(challengeResponse || {}),
      adults: '1', children: '0', infants: '0'
    }

    const result = await this.tracker.track(search(params), {
      message: messages.loadingMessage,
      analyticsKey: 'vehicle_search',
    })

    this.searchResultProducts = result.results.map(product => new Product(this, product))
    this.specialSearchResults = this.buildSpecialCandidatesFrom(result.specialCandidates, this.searchParameters!, this.searchResultProducts)
    this.searchResultMetadata = result.metadata ? this.normaliseMetadataKeys(result.metadata) : null
    this.searchResultsChallengeDetail = result.challenge || null

    this.searchResultsResponse = result
    this.selectedBrands.push(...this.availableVendorCodes)
    this.selectedPriceRangeFilter = this.filterPrice
    
    // if any of the (unfiltered) search results have capacity for child seating, then show the filter option
    this.maximumPossibleChildren = this.searchResultProducts
      .map(product => product.info.seatingCapacity.child)
      .sort((a, b) => b - a)[0]

    // if any of the (unfiltered) search results have capacity for infant seating, then show the filter option
    this.maximumPossibleInfants = this.searchResultProducts
      .map(product => product.info.seatingCapacity.infant)
      .sort((a, b) => b - a)[0]

    this.searchFilters = possibleSearchFilters.filter((feature) => {
      return this.searchResultProducts!.some((product) => product.info.meta.features.includes(feature))
    })

    const filteredSearchResultProducts = this.searchResultProducts.filter(product => this.matchesSearchFilter(product))

    if (!filteredSearchResultProducts.some(p => p.info.isAvailable) && !this.shouldPromptRevisedSearch)
      this.hideUnavailableFilter = false
  }

  private buildSpecialCandidatesFrom(specialCandidates: SearchResultMatchDetail[] | undefined, searchParameters: SearchParameters, knownProductList: Product[]): SpecialSearchResultCandidate[] {
    const result: SpecialSearchResultCandidate[] = [] as SpecialSearchResultCandidate[]

    (specialCandidates || []).forEach(candidate => {
        var candidateResult = this.buildSpecialCandidateFrom(candidate, searchParameters, knownProductList)

        if (candidateResult)
          result.push(candidateResult)
      })

    return result
  }

  private buildSpecialCandidateFrom(candidate: SearchResultMatchDetail, searchParameters: SearchParameters, knownProductList: Product[]): SpecialSearchResultCandidate | null {
    const productDetail = knownProductList.filter(p => p.code.toLowerCase() === candidate.deal.product.code.toLowerCase())[0]
    if (!productDetail) return null

    return new SpecialSearchResultCandidate(searchParameters, candidate, productDetail)
  }

  @action
  public async updateSearchParameters(searchParameters: SearchParameters) {
    if (!searchParameters) return

    this.searchParameters = searchParametersFrom(searchParameters)

    const {
      partner_code,
      country,
      vehicle_type,
      pick_up_location,
      drop_off_location,
      pick_up_date,
      drop_off_date
    } = this.searchParameters

    this.pickupDate = toDate(pick_up_date)
    this.dropOffDate = toDate(drop_off_date)
    this.depots = await getDepots(partner_code, country, vehicle_type)
    this.selectedSleepsAdults = (+searchParameters.adults) || 2
    this.selectedSleepsChildren = (+searchParameters.children) || 0
    this.selectedSleepsInfants = (+searchParameters.infants) || 0

    this.pickupDepot = this.depots.depots.find(d => d.code.toUpperCase() === pick_up_location.toUpperCase())
    this.dropOffDepot = this.depots.depots.find(d => d.code.toUpperCase() === drop_off_location.toUpperCase())
    this.pickupDepotSchedules = await getDepotSchedules(partner_code, country, vehicle_type, pick_up_location)
    this.dropOffDepotSchedules = pick_up_location.toUpperCase() === drop_off_location.toUpperCase()
     ? this.pickupDepotSchedules
     : await getDepotSchedules(partner_code, country, vehicle_type, pick_up_location)
  }

  @computed get shouldPromptRevisedSearch() {
    if (!this.searchResultsResponse || !this.pickupDate || !this.dropOffDate)
      return false
    
    return this.pickupDate > this.dropOffDate
  }

  @computed get pickupDepots() {
    return this.depots?.depots.filter(d => d.isForPickup)
  }

  @computed get dropOffDepots() {
    return this.pickupDepotSchedules && this.depots
      ? this.depots.depots.filter(depot => this.pickupDepotSchedules?.dropOffAllowed.includes(depot.code))
      : undefined
  }

  @action async updatePickupDepot(depotCode: string) {
    var updatedDepot = this.depots?.depots.find(a => a.code === depotCode)
    if (this.pickupDepot && this.pickupDepot === updatedDepot) return

    this.pickupDepot = updatedDepot
    this.pickupDepotSchedules = undefined

    const {partner_code, country, vehicle_type} = this.searchParameters!
    this.pickupDepotSchedules = await getDepotSchedules(partner_code, country, vehicle_type, depotCode)
  }

  @action async updateDropoffDepot(depotCode: string) {
    var updatedDepot = this.depots?.depots.find(a => a.code === depotCode)
    if (this.dropOffDepot && this.dropOffDepot === updatedDepot) return

    this.dropOffDepot = updatedDepot
    this.dropOffDepotSchedules = undefined

    const {partner_code, country, vehicle_type} = this.searchParameters!
    this.dropOffDepotSchedules = await getDepotSchedules(partner_code, country, vehicle_type, depotCode)
  }

  @action setPickupDate(date: Date | null) {
    if (!date) return
    this.updatePickupDate(this.latestPossiblePickupFor(date));
  }

  @action setPickupDateAndUpdateDropoffDate(date: Date | null) {
    if (!date || !this.dropOffDate || !this.pickupDate || !this.dropOffDepotSchedules) return

    var millisecondsDelta = Math.abs(this.dropOffDate.getTime() - this.pickupDate.getTime())
    this.updatePickupDate(this.latestPossiblePickupFor(date));
    this.updateDropOffDate(this.findBestDropoffDateTimeFrom(date, millisecondsDelta));
  }

  private findBestDropoffDateTimeFrom(minimumDate: Date, millisecondsDelta: number, attempt: number = 0): Date {
    if (attempt >= 30) return minimumDate

    const millisecondsInDay = 86400000 // 60 * 60 * 24 * 1000

    const upperDate = new Date(minimumDate.getTime() + millisecondsDelta + (millisecondsInDay * attempt))
    const upperDateResult = this.latestPossibleDropoffFor(upperDate)
    if (upperDateResult) return upperDateResult

    const lowerDate = new Date(minimumDate.getTime() + millisecondsDelta - (millisecondsInDay * attempt))
    if (lowerDate > minimumDate) {
      const lowerDateResult = this.latestPossibleDropoffFor(lowerDate)
      if (lowerDateResult) return lowerDateResult
    }

    return this.findBestDropoffDateTimeFrom(minimumDate, millisecondsDelta, attempt + 1)
  }

  private latestPossiblePickupFor(date: Date): Date | null {
    var operatingHours = this.validPickupHoursFor(date)
    if (!operatingHours || !operatingHours.isOpenForPickup) return null

    var newDateString = [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, '0'), date.getDate().toString().padStart(2, '0')].join('-')
    var newDateTimeString = newDateString + "T" + operatingHours.latestPickupTime
    return new Date(newDateTimeString)
  }
  private latestPossibleDropoffFor(date: Date): Date | null {
    var operatingHours = this.validDropoffHoursFor(date)
    if (!operatingHours || !operatingHours.isOpenForDropoff) return null

    var newDateString = [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, '0'), date.getDate().toString().padStart(2, '0')].join('-')
    var newDateTimeString = newDateString + "T" + operatingHours.latestDropoffTime
    return new Date(newDateTimeString)
  }

  private validHoursFor(dateTime: Date, depotSchedules: DepotSchedulesModel | undefined, hoursValid: (h: OperatingHoursModel) => boolean): OperatingHoursModel | null {
    if (!depotSchedules) return null

    // remove Time component and force to UTC
    const date = new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()))
    var applicableSchedule = depotSchedules.operatingSchedules.find(s => new Date(s.start) <= date && new Date(s.end) > date)
    if (!applicableSchedule) return null

    var operatingHours = applicableSchedule.operatingHours.find(h => h.code === date.getDay().toString())
    if (!operatingHours || !hoursValid(operatingHours)) return null

    var locationHoliday = applicableSchedule.holidays
      .find(h => h.dates.some(d => (new Date(d)).getTime() === date.getTime()))
    if (!!locationHoliday) return null

    return operatingHours
  }

  private validPickupHoursFor(date: Date): OperatingHoursModel | null {
    return this.validHoursFor(date, this.pickupDepotSchedules, h => h.isOpenForPickup)
  }
  private validDropoffHoursFor(date: Date): OperatingHoursModel | null {
    return this.validHoursFor(date, this.dropOffDepotSchedules, h => h.isOpenForDropoff)
  }

  public isOpenForPickupOn(date: Date | undefined): boolean {
    return !!date && !!this.validPickupHoursFor(date)
  }
  public isOpenForDropoffOn(date: Date | undefined): boolean {
    return !!date && !!this.validDropoffHoursFor(date)
  }

  @action updatePickupDate(date: Date | null) {
    this.pickupDate = date || undefined
  }

  @action updateDropOffDate(date: Date | null) {
    this.dropOffDate = date || undefined
  }

  @action updatePriceRangeFilter(priceRange: number | number[]) {
    if (isArray(priceRange) && priceRange.length === 2)
      this.selectedPriceRangeFilter = priceRange as [number, number]
  }
  @action toggleHideUnavailableVehiclesFilter() {
    this.hideUnavailableFilter = !this.hideUnavailableFilter

    if (!this.hideUnavailableFilter)
      Analytics.trackEvent('show_unavailable')
  }
  @action showUnavailableVehicleOptions() {
    this.hideUnavailableFilter = false

    if (!this.hideUnavailableFilter)
      Analytics.trackEvent('show_unavailable')
  }

  @action updateSleepsAdultsFilter(sleeps: string) {
    this.selectedSleepsAdults = (+sleeps) || 1

    if (this.searchParameters) {
      this.searchParameters.adults = this.selectedSleepsAdults.toString()
      this.updatePageUrl()
    }
  }
  @action updateSleepsChildrenFilter(sleeps: string) {
    this.selectedSleepsChildren = (+sleeps) || 0

    if (this.searchParameters) {
      this.searchParameters.children = this.selectedSleepsChildren.toString()
      this.updatePageUrl()
    }
  }
  @action updateSleepsInfantsFilter(sleeps: string) {
    this.selectedSleepsInfants = (+sleeps) || 0

    if (this.searchParameters) {
      this.searchParameters.infants = this.selectedSleepsInfants.toString()
      this.updatePageUrl()
    }
  }

  @action updateSearchBrandFilter(vendor: string, include: boolean) {
    include
      ? this.selectedBrands.push(vendor)
      : this.selectedBrands.splice(this.selectedBrands.indexOf(vendor), 1)
  }

  @action updateSearchFilter(feature: Feature, include: boolean) {
    include
      ? this.selectedFilters.push(feature)
      : this.selectedFilters.splice(this.selectedFilters.indexOf(feature), 1)
  }

  getFilterBrandState(code: string) {
    return this.selectedBrands.includes(code)
  }
  getFilterState(feature: Feature) {
    return this.selectedFilters.includes(feature)
  }

  @computed get filterPrice(): [number, number] | undefined {
    const prices = this.searchResultsResponse?.results.map(a => a.total).sort((a, b) => a - b)
    return prices
      ? [Math.floor(prices[0] / 100) * 100, Math.ceil(prices[prices.length - 1] / 100) * 100]
      : undefined
  }

  private matchesSearchFilter = (product: Product) => {
    const matchesFeatures =
      this.selectedFilters.length === 0 ||
      this.selectedFilters.every(sf => product.info.meta.features.includes(sf))
    const matchesBrand = this.selectedBrands.includes(product.info.vendorCode)

    const totalChildGuests = this.selectedSleepsChildren + this.selectedSleepsInfants
    const totalGuests = (this.selectedSleepsAdults || 1) + totalChildGuests
    const matchesSleep = product.info.berth === 0 || product.info.berth >= totalGuests

    const seats = product.info.seatingCapacity
    const sleeps = product.info.sleepingCapacity
    const accommodatesChildren = (sleeps.child === 0 || sleeps.child >= totalChildGuests) &&
      seats.child >= this.selectedSleepsChildren &&
      seats.infant >= this.selectedSleepsInfants

    const matchesPriceRange =
      !this.selectedPriceRangeFilter ||
      this.selectedPriceRangeFilter.length !== 2 ||
      (product.info.total >= this.selectedPriceRangeFilter[0] &&
        product.info.total <= this.selectedPriceRangeFilter[1])

    return matchesBrand && matchesFeatures && matchesSleep && accommodatesChildren && matchesPriceRange
  }

  private groupSearchResults = (searchResults: Product[]) => {
    return searchResults
      .filter(r => this.matchesSearchFilter(r))
      .reduce((object, vehicle) => {
        const key = vehicle.info.vendorCode
        const collection = [...(object[key] || []), vehicle].sort((a, b) => {
          // Sort by Price and availability
          if (a.info.isAvailable === b.info.isAvailable) return a.info.total - b.info.total
          else if (a.info.isAvailable) return -1
          else return 1
        })

        return {
          ...object,
          [key]: collection,
        }
      }, {} as {[key: string]: Product[]})
  }

  private sortResultsByVendor(results: {[key: string]: Product[]}) {
    const sortMap = this.sortOrder
    return Object.entries(results).sort((a, b) => {
      const vendorA = a[0].toLowerCase()
      const vendorB = b[0].toLowerCase()
      return sortMap.indexOf(vendorA) - sortMap.indexOf(vendorB)
    })
  }

  @computed
  get anyWithLoyaltyPoints(): boolean {
    return !!this.searchResultProducts &&
      this.searchResultProducts.some(p => p.canEarnLoyaltyPoints())
  }

  @computed
  get groupedProductResults(): [string, Product[]][] {
    if (!this.searchResultProducts) return []
    
    const grouped = this.groupSearchResults(this.searchResultProducts)
    return this.sortResultsByVendor(grouped)
  }

  @computed
  get groupedSearchResults(): GroupedResultset[] {
    let indexOffset = 1

    return this.groupedProductResults.map(group => {
      const groupResults = group[1]
      const resultset = new GroupedResultset(group[0] as VendorCode, groupResults, indexOffset)
      indexOffset += groupResults.length
      return resultset
    })
  }

  @computed
  get availableVendorCodes(): VendorCode[] {
    return Array.from(
      new Set(
        this.searchResultsResponse?.results
          ?.map(item => item.result)
          .map(item => item.vendor.code as VendorCode)
          .sort(
            (a, b) =>
              this.sortOrder.indexOf(a.toLowerCase()) - this.sortOrder.indexOf(b.toLowerCase())
          )
      )
    )
  }

  @computed
  get sortOrder() {
    let partnerCode = this.searchParameters?.partner_code
    return partnerVendorSortOrderFor(partnerCode)
  }

  updatePageUrl() {
    window.history.pushState({}, "", this.getCurrentSearchUrl())
  }

  getCurrentSearchUrl() {
    const params = this.searchParameters
    if (!params) return null

    return searchUrlFrom(params)
  }

  getBookingUrl(params: SearchParameters, vehicle_code: string, rate_plan_code: string): LocationPath {
    const additionalInfo = {
      membership_number: params.membership_number,
      relocation_identifier: params.relocation_id,
      deal_identifier: params.hotdeal_id
    }
    return configureUrlWithQueryFrom(params, vehicle_code, rate_plan_code, additionalInfo)
  }
}

export interface LocationPath {
  pathname?: string
  search?: string
}