import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { FunctionComponent, useState } from "react"
import { FormattedHTMLMessage, FormattedMessage } from "react-intl"
import { GuestRegistrationDetail } from "../../../domain/models/search-models"
import { PaymentStore } from "../../../domain/stores/payment-store"
import { CountrySelector } from "../../GuestDetails/CountrySelector"

import styles from './BasicPreregistrationDialog.module.scss'

interface IBasicPreregistrationDialogProps {
  store: PaymentStore
  isOpen: boolean
  onClose: () => void
}

interface IAddressDetailsProps {
  formState: GuestRegistrationDetail
  onChange: (key: string, value: string) => void
}

interface IRonaRegistrationFormProps {
  country: string | undefined
  onChange: (detail: GuestRegistrationDetail) => void
}

export const BasicPreregistrationDialog: FunctionComponent<IBasicPreregistrationDialogProps> = ({isOpen, onClose, store}) => {
  const [guestDetailsFormState, setGuestDetailsFormState] = useState({} as GuestRegistrationDetail)

  const onGuestDetailsChanged = (key: string, value: string) => {
    setGuestDetailsFormState({ ...guestDetailsFormState, [key]: value })
  }

  const guestDetailsFormSubmitted = () => {
    store.attachPreregistrationDetail(guestDetailsFormState)
    onClose()
  }

  return (
    <Dialog aria-labelledby='basic-prereg-dialog' open={isOpen} onClose={onClose}>
      <DialogTitle>
        <span className={styles.title}>
          <FormattedMessage id="dialogs.preregistration.header" />
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid container item md={12} className={styles.gridContainer}>
          <span className={styles.content}>
            <FormattedHTMLMessage id="dialogs.preregistration.intro" />

            <Grid container spacing={2} className={styles.booking_guest_details}>
              <AddressDetails formState={guestDetailsFormState} onChange={onGuestDetailsChanged} />

              <Grid xs={12} item>
                <button className={styles.register_guest_details_button} onClick={guestDetailsFormSubmitted}>
                  <FormattedMessage id='action-buttons.submit' />
                </button>
              </Grid>
            </Grid>
          </span>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export const BasicPreRegistrationSection: FunctionComponent<IRonaRegistrationFormProps> = ({country, onChange}) => {
  const [guestDetailsFormState, setGuestDetailsFormState] = useState({country: country} as GuestRegistrationDetail)

  const onGuestDetailsChanged = (key: string, value: string) => {
    const details = { ...guestDetailsFormState, [key]: value }
    setGuestDetailsFormState(details)
    onChange(details)
  }

  return (
    <Grid item xs={12}>
      <div className="booking-payment-option">
        <Grid container item alignItems="center" spacing={2}>
          <Grid container item xs={12} className={styles.gridContainer}>
            <span className={styles.content}>
              <FormattedHTMLMessage id="dialogs.preregistration.intro_short" />

              <Grid container spacing={2} className={styles.booking_guest_details}>
                <AddressDetails formState={guestDetailsFormState} onChange={onGuestDetailsChanged} />
              </Grid>
            </span>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

const AddressDetails: FunctionComponent<IAddressDetailsProps> = ({ formState, onChange }) => {
  return (
    <>
      <Grid xs={6} item>
        <div>
          <FormattedMessage id='registration-details.postcode'>
            {(t) => (
              <>
                <label htmlFor='postcode'>{t}</label>
                <input name='postcode' type='text' id='postcode' placeholder={t as string} onChange={(event) => onChange("postcode", event.target.value)} />
              </>
            )}
          </FormattedMessage>
        </div>
      </Grid>

      <Grid xs={6} item>
        <div>
          <FormattedMessage id='registration-details.country'>
            {(t) => (
              <>
                <label htmlFor='country'>{t}</label>
                <CountrySelector
                  selected={formState.country}
                  onChange={(newValue) => onChange("country", newValue)} />
              </>
            )}
          </FormattedMessage>
        </div>
      </Grid>
    </>
  )
}