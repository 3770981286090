import {Grid} from '@mui/material'
import {useObserver} from 'mobx-react-lite'
import React, {FunctionComponent, useContext, useState} from 'react'
import {BookingStoreContext} from '../../App'
import {getExtrasIcon} from '../../domain/helpers/extrasIconLoader'
import {Tooltip, Button, ClickAwayListener} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import './Item.scss'
import { BookingSummaryLineItemModel, tryRenderSummaryLine } from '../../domain/models/bookingSummaryModel'
import { useIntl } from 'react-intl'

export interface IExtra {
  code: string
  name: string
  pricing: BookingSummaryLineItemModel
  maximumQty: number
  description: string
}
interface IItemProps {
  item: IExtra
}
export const Item: FunctionComponent<IItemProps> = ({
  item: {code, name, pricing, maximumQty, description},
}) => {
  const intl = useIntl()
  const store = useContext(BookingStoreContext)
  const [quantity, setQuantity] = useState(store.getExtraCount(code))
  const [debounce, setDebounce] = useState(() => setTimeout(() => {}, 0))
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const updateQuantity = (q: number) => {
    if (q < 0 || q > maximumQty) return
    clearTimeout(debounce)
    setQuantity(q)
    setDebounce(() =>
      setTimeout(() => {
        store.updateExtraCount(code, q)
      }, 500)
    )
  }

  const ExtrasIcon = getExtrasIcon(code)

  return useObserver(() => (
    <div className='booking-details-extras-item'>
      <div className='booking-details-extras-item__wrapper'>
        <div>
          <div className='cover-extras-tabs__info-wrapper'>
            {description && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div className='cover-extras-tabs__info'>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    title={description}
                    arrow
                    placement='top'
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <Button onClick={handleTooltipOpen}>
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            )}
          </div>
          <ExtrasIcon className='booking-details-extras-item__icon' />
          <div className='booking-details-extras-item__name'>{name}</div>
        </div>

        <Grid container className='booking-details-extras-item__count'>
          <Grid
            item
            xs={4}
            onClick={() => updateQuantity(quantity - 1)}
            className={quantity > 0 ? 'booking-details-extras-item__count--minus-enabled' : ''}
          >
            <span>-</span>
          </Grid>
          <Grid item xs={4} className='booking-details-extras-item__count__number'>
            {quantity}
          </Grid>
          <Grid
            item
            xs={4}
            onClick={() => updateQuantity(quantity + 1)}
            className={
              quantity < maximumQty ? 'booking-details-extras-item__count--plus-enabled' : ''
            }
          >
            <span>+</span>
          </Grid>
        </Grid>
      </div>
      <div className='booking-details-extras-item__price'>
        {tryRenderSummaryLine(intl, pricing.pricingExplanationTemplate, pricing.pricingExplanation)}
      </div>
    </div>
  ))
}
