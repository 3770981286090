import React from 'react'
import { FormattedMessage } from 'react-intl'
import { paymentDisclaimerKeysFor } from '../../domain/services/MetadataService'

interface IPaymentDisclaimersProps {
  country: string,
  partnerCode: string
}

export const AdditionalPaymentDisclaimers = (props: IPaymentDisclaimersProps) => {
  const keys = paymentDisclaimerKeysFor(props.country, props.partnerCode)
  if (keys.length === 0) return <></>
  
  return (
    <ul>
      {
        keys.map(k => (
          <li key={`payment-disclaimer-${k}`}>
            <FormattedMessage id={k} />
          </li>
        ))
      }
    </ul>
  )
}
