import { useMediaQuery, useTheme } from "@mui/material"

interface IHiddenProps {
  smUp?: boolean
  smDown?: boolean
  mdUp?: boolean
  mdDown?: boolean
  children: any
}

export const Hidden = (props: IHiddenProps) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })

  if (props.smDown && isSmDown) return null
  if (props.smUp && isSmUp) return null
  if (props.mdDown && isMdDown) return null
  if (props.mdUp && isMdUp) return null

  return props.children
}