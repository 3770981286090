import {Grid} from '@mui/material'
import Check from '@mui/icons-material/Check'
import React, {useState, FunctionComponent} from 'react'
import './CustomisedStepper.scss'
import { FormattedMessage } from 'react-intl'

interface ICustomisedStepperProps {
  step: number
}
export const CustomisedStepper: FunctionComponent<ICustomisedStepperProps> = ({step}) => {
  const [activeIndex] = useState(step)
  const steps = ['search', 'customise', 'guest_details', 'confirmation']
  const content = (index: number) => {
    if (index > activeIndex) {
      return <>{index + 1}</>
    } else if (index < activeIndex) {
      return <Check className='customised-stepper__tick' />
    } else {
      return (
        <svg
          width='100%'
          height='100%'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          className='customised-stepper__inner-circle'
        >
          <circle cx='50' cy='50' r='50' fill='white' />
          <circle cx='50' cy='50' r='40' />
        </svg>
      )
    }
  }

  const className = (index: number) => {
    if (index > activeIndex) {
      return 'uncompleted'
    } else if (index < activeIndex) {
      return 'completed'
    } else {
      return 'current'
    }
  }

  return (
    <>
      {steps.map((step, index) => (
        <Grid item className='customised-stepper__step' key={index}>
          <div style={{position: 'relative'}}>
            <div className='customised-stepper__title'>
              <FormattedMessage id={`booking.summary.steps.${step}`} />
            </div>
            <div className={`customised-stepper__circle customised-stepper--${className(index)}`}>
              {content(index)}
            </div>
            {index > 0 && (
              <div
                className={`customised-stepper__line customised-stepper--line-${
                  index <= activeIndex ? 'completed' : 'uncompleted'
                }`}
              ></div>
            )}
          </div>
        </Grid>
      ))}
    </>
  )
}
