import React, {FunctionComponent, useEffect} from 'react'
import {useObserver} from 'mobx-react-lite'
import {PaymentStore} from '../../../domain/stores/payment-store'
import Grid from '@mui/material/Grid'
import './Campstay.scss'
import {WorkTracker} from '../../LoadingPanel/work-tracker'
import {Page} from '../../Page/Page'
import { Quotation } from '../../../domain/models/search-models'
import { formatForDatestamp } from '../../../domain/helpers/date-formatters'
import { ConfirmationPageSection } from './ConfirmationPageSection'
import { CampstayData } from '../../../domain/models/campstay-models'

interface ICamp {
  store: PaymentStore
  tracker: WorkTracker
}

interface ICampstayCardProps {
  result: CampstayData
  tracker: WorkTracker
}

declare var injectScript: any
const windowHost = window as any
const injectScriptEnabled = () => typeof injectScript !== 'undefined';

const injectAutoPilotTrackingScript = () => {
  const scriptPath = "https://turbolion.io/anywhere/f848e50ff29c4a6594f29b18216dea92aaa99ca0c363431bb634b0ac239eb21c"
  const scriptQuery = `t=${encodeURIComponent(document.title || "")}&u=${encodeURIComponent(document.location.href || "")}&r=${encodeURIComponent(document.referrer || "")}`

  if (!windowHost.AutopilotAnywhere)
    windowHost.AutopilotAnywhere = {
      _runQueue: [],
      run: function() {
        this._runQueue.push(arguments);
      },
    }

  if (!windowHost.Autopilot)
    windowHost.Autopilot = windowHost.AutopilotAnywhere

  return injectScript(`${scriptPath}?${scriptQuery}`)
}

const associateBookingWithBrowserSession = (quote: Quotation) => {
  if (!windowHost.Autopilot) return

  windowHost.Autopilot.run("associate", {
    Email: quote.customer.email,
    FirstName: quote.customer.firstName,
    LastName: quote.customer.surname,
    custom: {
        "date--startdate": formatForDatestamp(quote.rental.startDateTime),
        "string--apolloCamperBooked": "True",
        "string--campStayBooked": "False",
        "string--adults": quote.pax.adult || 1,
        "string--children": (quote.pax.total || 1) - (quote.pax.adult || 1),
        "string--startLocation": quote.rental.startDepot?.name,
        "string--endLocation": quote.rental.endDepot?.name
    }
  });
}

export const Campstay: FunctionComponent<ICamp> = props => {
  const {store, tracker} = props

  useEffect(() => {
    store.getCampStay(tracker)

    if (injectScriptEnabled()) {
      const autoPilotScript = injectAutoPilotTrackingScript()
      associateBookingWithBrowserSession(store.quotation!)
      return () => {
        document.head.contains(autoPilotScript) && document.head.removeChild(autoPilotScript)
        document.body.contains(autoPilotScript) && document.body.removeChild(autoPilotScript)
      }
    }
  }, [store, tracker])

  const adults = store.quotation?.pax.adult.toString()
  const children = (
    Number(store.quotation?.pax.total) - Number(store.quotation?.pax.adult)
  ).toString()
  const startDate = store.pickupDropoffInfo?.startDateTime.toString().split('T')[0]
  const endDate = store.pickupDropoffInfo?.endDateTime.toString().split('T')[0]

  return useObserver(() => {
    return (
      <ConfirmationPageSection headerKey="headers.confirmation.campstay">
        <Grid container item spacing={1}>
          <Grid item>
            Showing prices for {adults} adults and {children} children kids staying from {startDate} to {endDate}
          </Grid>

          <Grid container item justifyContent="space-between" spacing={1}>
            {store.campstayOptionsResponse &&
              store.campstayOptionsResponse.length > 0 &&
              store.campstayOptionsResponse.map(item => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={item.CSLink} className='CampStayCard'>
                    <CampstayCard result={item} tracker={tracker} />
                  </Grid>
                )
              })}
          </Grid>

          <Grid item>
            <a href='https://apollo.campstay.com/' className="partner-link">See all holiday parks</a>
          </Grid>
        </Grid>
      </ConfirmationPageSection>
    )
  })
}

const CampstayCard: FunctionComponent<ICampstayCardProps> = ({result, tracker}) => {
  return (
    <div className='CampStayCard__content partner-border'>
      <div className='CampStayCard__content--img'>
        <img src={result.CSImage} alt='Camp Stay' />
      </div>
      <Page tracker={tracker} small={true}>
        <div className='CampStayCard__content--text'>
          <div className='CSName'>{result.CSName}</div>
          <div className='CSType'>{result.CSRoomType}</div>
          <div className='CSPrice'>{result.CSPrice}</div>
          <p>
            <a href={result.CSLink} className="partner-link">See More Information</a>
          </p>
        </div>
      </Page>
    </div>
  )
}