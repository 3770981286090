import {action, computed, observable} from 'mobx'
import {defineMessages} from 'react-intl'
import {IImportantInformationTabProps} from '../../components/BookingDetails/ImportantInformationTab'
import {IExtra} from '../../components/BookingDetails/Item'
import {IRentalDetailsTabProps} from '../../components/BookingDetails/RentalDetailsTab'
import {ISection} from '../../components/BookingDetails/Sections'
import {WorkTracker} from '../../components/LoadingPanel/work-tracker'
import {toDateAsUtc} from '../helpers/date-formatters'
import {RentalAncillaryOptions, RentalActivityPriced, RentalActivityBase, RentalDetails} from '../models/api-models'
import {CampstayData} from '../models/campstay-models'
import BookingSummaryModel, { processProductPricingSummaryLine } from '../models/bookingSummaryModel'
import {BookingParameters, CoverOption, Quotation, VendorCode, BasicContactInformation, GuestRegistrationDetail} from '../models/search-models'
import * as Api from '../services/Api'
import * as Analytics from '../../utils/analytics'
import * as MetadataService from '../services/MetadataService'
import {processPriceBreakdown} from '../../utils/regx'
import {paymentOptionsFrom} from '../helpers/payment-helpers'
import { bookingParametersFrom, guestRegistrationUrlFrom } from '../helpers/url-helpers'
import { IApiResult } from '../models/apiResult'
import { calculatePossibleEarnRatesFrom } from '../services/LoyaltyPointsService'

const messages = defineMessages({
  updateSearchParamsLoadingMessage: {
    id: 'booking.store.update.search.param.loading.message'
  },
  addOptionsLoadingMessage: {
    id: 'booking.store.add.options.loading.message'
  },
  createQuotationLoadingMessage: {
    id: 'booking.store.create.quotation.loading.message'
  },
})

export class BookingStore {
  @observable private selectedSearchResultResponse: RentalActivityPriced | null = null
  @observable private ancillaryOptionsResponse: RentalAncillaryOptions | null = null
  @observable public campstayOptionsResponse: CampstayData[] | null = null
  @observable public quotation: Quotation | null = null
  @observable public searchParameters: BookingParameters | null = null
  @observable public emailMeQuote: boolean = false
  @observable public guestDetails: BasicContactInformation = {
    email: '',
    firstName: '',
    telephone: '',
    surname: '',
    title: 'Mr',
    confirmEmail: '',
    membershipNumber: this.searchParameters?.membership_number
  }

  constructor(readonly tracker: WorkTracker) {}

  @action
  public async updateExtraCount(extraCode: string, count: number) {
    const extras = [...(this.selectedSearchResult?.ancillaries.equipmentAndServices || [])]
    const item = extras?.find(item => item.product.code === extraCode)

    if (item) {
      if (item.quantity < count) {
        Analytics.addItem(extraCode, count, item.price?.amount)
      } else if (item.quantity > count) {
        Analytics.removeItem(extraCode, count, item.price?.amount)
      }

      item.quantity = count
    } else {
      extras.push({quantity: count, product: {code: extraCode}})
      Analytics.addItem(extraCode, count)
    }

    this.selectedSearchResultResponse = await this.tracker.track(
      Api.getPriceAvailProduct(
        this.searchParameters!,
        this.selectedSearchResult?.ancillaries.coverage?.product.code,
        extras
      ),
      {analyticsKey: 'add_extras'}
    )
  }

  @action
  public async getAncillaryOptions() {
    this.ancillaryOptionsResponse = await this.tracker.track(
      Api.getAncillaries(
        this.searchParameters!,
        this.selectedSearchResult?.ancillaries.coverage?.product.code
      ),
      {
        message: messages.updateSearchParamsLoadingMessage,
        analyticsKey: 'get_ancillary_options',
      }
    )
  }

  @action
  public async updateSelectedCover(coverCode: string) {
    Analytics.addItem(coverCode, 1)

    this.selectedSearchResultResponse = await this.tracker.track(
      Api.getPriceAvailProduct(
        this.searchParameters!,
        coverCode,
        this.selectedSearchResult?.ancillaries.equipmentAndServices
      ),
      {
        message: messages.addOptionsLoadingMessage,
        analyticsKey: 'update_selected_cover',
      }
    )
  }

  @action
  public async updateSearchParameters(bookingParameters: BookingParameters) {
    if (!bookingParameters) return

    const calculatedSearchParameters = bookingParametersFrom(bookingParameters)
    
    if (!this.searchParameters || this.searchParameters.vehicle_code !== calculatedSearchParameters.vehicle_code)
      this.selectedSearchResultResponse = null
    
    this.searchParameters = calculatedSearchParameters
    this.selectedSearchResultResponse = await this.tracker.track(
      Api.getPriceAvailProduct(
        bookingParameters,
        this.selectedSearchResult?.ancillaries.coverage?.product.code,
        this.selectedSearchResult?.ancillaries.equipmentAndServices
      ),
      {
        message: messages.updateSearchParamsLoadingMessage,
        analyticsKey: 'update_search_parameters',
      }
    )

    if (this.selectedSearchResultResponse.rental.product?.code)
      this.searchParameters.vehicle_code = this.selectedSearchResultResponse.rental.product.code
  }

  @action
  public setGuestDetail(key: string, value: string) {
    this.guestDetails = { ...this.guestDetails, [key]: value }
  }

  @action
  public setEmailMeQuote(value: boolean) {
    this.emailMeQuote = value
  }

  @action async createQuotation(
    details: BasicContactInformation,
    subscribedNewsletterKeys: string[],
    sendCustomerEmail?: boolean | undefined,
    preRegistrationDetails?: GuestRegistrationDetail
  ): Promise<IApiResult<Quotation>> {
    const result = await this.tracker.track(
      Api.createQuotation(
        this.searchParameters!,
        this.selectedVehicleCode,
        this.selectedSearchResult?.ancillaries.coverage?.product.code || '',
        this.selectedSearchResult?.ratePlan.code || '',
        this.selectedSearchResult?.ancillaries.equipmentAndServices || [],
        {
          ...details,
          dateOfBirth: this.defaultDateOfBirth
        },
        sendCustomerEmail,
        subscribedNewsletterKeys,
        preRegistrationDetails
      ),
      {
        message: messages.createQuotationLoadingMessage,
        analyticsKey: 'create_quotation',
      }
    )

    this.quotation = result.data || null
    return result
  }

  @computed get defaultDateOfBirth() {
    let dob = new Date('1985-01-01')

    const startDate = new Date(this.rental!.startDateTime!)
    const driverAge = Number(this.searchParameters!.driver_age)
    if (startDate !== undefined && driverAge > 0) dob = new Date(`${startDate.getFullYear() - driverAge}-01-01`)

    return dob
  }

  @computed get reservationNumber() {
    return this.quotation?.reservationNo
  }

  @computed
  get selectedCoverOptionCode() {
    return this.selectedSearchResult?.ancillaries.coverage?.product.code
  }

  @computed get selectedCoverMetadata() {
    const code = this.selectedCoverOptionCode
    if (!code) return null

    const meta = MetadataService.coverMetadataFor(
      this.selectedSearchResult!.rental.startDepot!.country!.code,
      this.selectedSearchResult!.vendor.code,
      toDateAsUtc(this.searchParameters!.pick_up_date)
    )

    return meta.coverOptions.find(m => m.code.toUpperCase() === code.toUpperCase())
  }

  @computed
  get partner() {
    return this.selectedSearchResult?.partner
  }

  getExtraCount(extraCode: string) {
    return (
      computed(
        () =>
          this.selectedSearchResult?.ancillaries.equipmentAndServices?.find(
            item => item.product.code === extraCode
          )?.quantity
      ).get() || 0
    )
  }

  @computed get selectedVehicleMetaData() {
    return MetadataService.vehicleMetadataFor(this.selectedVehicleCode)
  }

  get coverOptions(): CoverOption[] {
    const meta = MetadataService.coverMetadataFor(
      this.selectedSearchResult!.rental.startDepot!.country!.code,
      this.selectedSearchResult!.vendor.code,
      toDateAsUtc(this.searchParameters!.pick_up_date)
    )
    return (
      this.ancillaryOptionsResponse?.coverages
        ?.map(item => {
          const matches = processPriceBreakdown(item.pricing || '')
          const dailyPrice = matches ? matches.perUnitPrice : undefined
          const code = item.product.code
          const coverMeta = meta.coverOptions.find(m => m.code.toUpperCase() === code.toUpperCase())
          return {
            code: code,
            currency: this.currency,
            displayName: coverMeta?.displayName || item.product.name || '',
            description: coverMeta?.description || item.product.description || '',
            inclusions: coverMeta?.inclusions || [],
            liability: item.liability?.amount,
            dailyPrice: Number(dailyPrice) || 0,
            priceBreakdown: matches,
            bondLabel: coverMeta?.bondLabel || meta.bondLabel
          }
        })
        .sort((a, b) => b.dailyPrice - a.dailyPrice) || []
    )
  }

  @computed get bookingSummary() {
    const rental = this.selectedSearchResult
    return rental && rental.customerInvoice
      ? new BookingSummaryModel(rental, rental.customerInvoice, this.selectedCoverMetadata!, null, null)
      : null
  }

  @computed get rental(): RentalDetails | undefined {
    return this.rentalSource?.rental
  }

  @computed get currency() {
    return this.rental?.totalPrice?.currency
  }

  @computed get pickupDropoffInfo() {
    if (!this.rental) return null
    return {
      endDateTime: this.rental.endDateTime!,
      startDateTime: this.rental.startDateTime!,
      startDepot: {
        code: this.rental.startDepot?.code || '',
        name: this.rental.startDepot?.name || '',
      },
      endDepot: {
        code: this.rental.endDepot?.code || '',
        name: this.rental.endDepot?.name || '',
      },
      vehicleCode: this.rental.product?.code || '',
    }
  }

  @computed get selectedSearchResult(): RentalActivityPriced | undefined {
    return this.selectedSearchResultResponse || undefined
  }

  @computed get selectedVendorCode(): VendorCode {
    return this.selectedSearchResult?.vendor.code as VendorCode
  }

  @computed get rentalSource(): RentalActivityBase | undefined {
    return this.selectedSearchResult || this.quotation || undefined
  }

  @computed
  get anyWithLoyaltyPoints(): boolean {
    if (!this.rentalSource) return false

    const points = calculatePossibleEarnRatesFrom(this.rentalSource)
    return !!points && Object.values(points).some(v => v > 0)
  }

  @computed get paymentOptions() {
    return paymentOptionsFrom({
      invoice: this.paymentOptionsSource,
      amountPaid: 0,
      pointsRedeemed: 0,
      bookingConfirmed: false
    })
  }

  @computed get paymentOptionsSource() {
    return this.selectedSearchResultResponse?.customerInvoice || null
  }

  @computed get rentalDetailsTab(): IRentalDetailsTabProps {
    return {
      berth: this.rental?.product?.sleeping?.adult || 0,
      name: this.selectedVehicleMetaData.name || this.rental?.product?.name || '',
      vendorCode: this.selectedVendorCode,
      features: this.selectedVehicleMetaData.features,
      moreDetails: this.selectedVehicleMetaData.moreDetails,
    }
  }

  @computed get importantInfo(): IImportantInformationTabProps {
    return {
      infos: this.selectedVehicleMetaData.importantInfo,
      partnerCode: this.partner!.code,
      countryCode: this.rental!.startDepot!.country!.code,
      vendorCode: this.selectedVendorCode,
      locale: MetadataService.currentLocale()
    }
  }

  public hasExtraAvailable(code: string): boolean {
    const availableExtras = this.ancillaryOptionsResponse?.equipmentAndServices
    return !!availableExtras && availableExtras.some(item => item.product.code === code)
  }

  public hasExtraApplied(code: string): boolean {
    return this.getExtraCount(code) > 0
  }

  @computed get extras(): ISection[] {
    const resultMap = new Map<string, IExtra[]>()
    this.ancillaryOptionsResponse?.equipmentAndServices?.filter(item => item.isDefault).forEach(item => {
      const ancillaryMetadata = MetadataService.ancillaryMetadataFor(item.product.code, this.rental?.startDepot?.country?.code)
      const e: IExtra = {
        code: item.product.code,
        name: ancillaryMetadata.displayName || item.product.name || '',
        pricing: processProductPricingSummaryLine(item, this.rental?.startDepot?.country?.code),
        maximumQty: item.maximumQty || 0,
        description: ancillaryMetadata.description || item.product.description || '',
      }

      const entry = resultMap.get(ancillaryMetadata.category)
      if (!entry) {
        resultMap.set(ancillaryMetadata.category, [e])
      } else {
        entry.push(e)
      }
    })

    return Array.from(resultMap).map(([key, value]) => ({
      section: key,
      items: value,
    }))
  }

  @computed get selectedVehicleCode() {
    return this.searchParameters?.vehicle_code || ''
  }

  @computed get guestUrl(): string {
    return guestRegistrationUrlFrom()
  }
}
