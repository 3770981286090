import {Grid} from '@mui/material'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {BookingSummarySectionModel} from '../../domain/models/bookingSummaryModel'
import BookingSummaryItem from './BookingSummaryItem'
import './BookingSummarySection.scss'

const BookingSummarySection = ({item}: {item: BookingSummarySectionModel}) => (
  <Grid item className='booking-summary-section'>
    <div className='booking-summary-section__header'>
      <div className='booking-summary-section__header--title'>
        <FormattedMessage id={`booking.payment_summary.section_headers.${item.type}`} />
      </div>
    </div>
    {item.items.map((item, i) => (
      <BookingSummaryItem key={i} item={item} />
    ))}
  </Grid>
)

export default BookingSummarySection
