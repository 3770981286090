import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'
import { isBunkPartner } from '../../domain/helpers/partner-helpers'
import { petFriendlyPolicyLinkFor } from '../../domain/services/MetadataService'

const PetFriendlyPolicy = (params: IImportantInfoSection) => {
  if (isBunkPartner(params.partnerCode)) {
    if (params.locale === 'en-GB') {
      return BunkPetFriendlyPolicyEN
    } else {
      return BunkPetFriendlyPolicyDE
    }
  } else {
    if (params.locale === 'en-GB') {
      return DefaultPetFriendlyPolicyEN(params)
    } else {
      return DefaultPetFriendlyPolicyDE
    }
  }
}

const DefaultPetFriendlyPolicyEN = ({partnerCode, countryCode, vendorCode}: IImportantInfoSection) => (
  <div className='important-info'>
    <h3>Pet Friendly Vehicles</h3>
    <p>
      We know your fur baby is just as much part of the family as anyone else, and pets love to
      holiday too! We have a number of pet-friendly Apollo, Cheapa Campa and Hippie Camper
      campervans and motorhomes available for road trips in Australia and New Zealand. Read about our{' '}
      <strong>Pet Friendly Vehicles</strong> and your responsibilities at time of booking and during travel in our{' '}
      <a href={petFriendlyPolicyLinkFor(partnerCode, countryCode, vendorCode)} target="_blank" rel="noopener noreferrer">
        Terms &amp; Conditions.
      </a>
    </p>
  </div>
)

const DefaultPetFriendlyPolicyDE = (
  <div className='important-info'>
    <h3>Haustiere im Wohnmobil</h3>
    <p>
      Wir wissen, dass Ihre pelzigen Freunde genauso zur Familie gehören wie jeder andere auch, und
      auch Haustiere lieben den Urlaub! Um sicherzustellen, dass Ihre Familie genügend Platz und Komfort an Bord Ihres
      Wohnmobils hat, erlauben wir maximal 2 Tiere pro Fahrzeug.
    </p>
  </div>
)

const BunkPetFriendlyPolicyEN = (
  <div className='important-info'>
    <h3>Pet Friendly Vehicles</h3>
    <p>
      We know your fur baby is just as much part of the family as anyone else, and pets love to
      holiday too! At the time of booking be sure to tick the 'pet' option so that we can assign
      you a pet-friendly motorhome from your chosen vehicle type. To ensure your family has enough
      space &amp; comfort onboard your motorhome, we allow a maximum of 2 dogs per vehicle.
    </p>
  </div>
)

const BunkPetFriendlyPolicyDE = (
  <div className='important-info'>
    <h3>Haustiere im Wohnmobil</h3>
    <p>
      Wir wissen, dass Ihre pelzigen Freunde genauso zur Familie gehören wie jeder andere auch, und
      auch Haustiere lieben den Urlaub! Kreuzen Sie bei der Buchung unbedingt die Option "Haustier"
      an, damit wir Ihnen ein tierfreundliches Wohnmobil aus dem von Ihnen gewählten Fahrzeugtyp
      zuweisen können. Um sicherzustellen, dass Ihre Familie genügend Platz und Komfort an Bord Ihres
      Wohnmobils hat, erlauben wir maximal 2 Tiere pro Fahrzeug.
    </p>
  </div>
)

export default PetFriendlyPolicy
