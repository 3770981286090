import {Grid} from '@mui/material'
import {observer} from 'mobx-react-lite'
import React, {useContext, useState} from 'react'
import {PaymentStoreContext} from '../../App'
import {ReactComponent as Tick} from '../../assets/img/confirmed-tick.svg'
import BookingPaymentSummary from '../BookingSummary/BookingPaymentSummary'
import './PaymentConfirmation.scss'
import { ThankyouBlurb } from './partner-components/ThankyouBlurb'
import { UpsellSection } from './partner-components/UpsellSection'
import { OutstandingFinancials } from './partner-components/OutstandingFinancials'
import { FormattedMessage } from 'react-intl'
import { PreregistrationBlurb } from './partner-components/PreregistrationBlurb'
import { BasicPreregistrationDialog } from './partner-components/BasicPreregistrationDialog'
import { RegisterLocationDetailsBlurb } from './partner-components/RegisterLocationDetailsBlurb'

const BookingConfirmation = () => {
  const store = useContext(PaymentStoreContext)
  const [preregistrationDialogHasBeenClosed, registerPreregistrationDialogClosure] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  if (!preregistrationDialogHasBeenClosed) {
    store.hasKnownAddressDetails().then((result) => {
      if (result === false) setIsOpen(true)
    })
  }

  const closePreregistrationDialog = () => {
    setIsOpen(false)
    registerPreregistrationDialogClosure(true)
  }

  return (
    store.invoiceResponse && (
      <Grid container className={`booking-confirmed partner_${store.partner.code}`} justifyContent="space-between" spacing={3}>
        <Grid item xs={12} md={7}>
          <Grid container spacing={1}>
            <Grid item>
              <div className='booking-confirmed__title'>
                <FormattedMessage id="headers.booking_confirmation" /> <Tick />
              </div>
              <div className='booking-confirmed__secondary'>
                <FormattedMessage id="headers.reservation_number" />
              </div>
              <div className='booking-confirmed__reservation'>{store.reservationNumber}</div>
            </Grid>

            <Grid item>
              <ThankyouBlurb store={store} />
            </Grid>

            {
              preregistrationDialogHasBeenClosed && !isOpen && !store.hasRegisteredLocationDetails && (
                <Grid item>
                  <RegisterLocationDetailsBlurb onClick={() => setIsOpen(true)} />
                </Grid>
              )
            }

            <Grid item>
              <PreregistrationBlurb paymentStore={store} />
            </Grid>

            {store.balancePayment && (
              <Grid item>
                <OutstandingFinancials store={store} />
              </Grid>
            )}

            <Grid item>
              <UpsellSection store={store} />
            </Grid>

            {
              store.anyWithLoyaltyPoints && (
                <Grid item>
                  <p className="loyaltyProgramDisclaimerText">
                    <sup>&dagger;</sup>
                    <FormattedMessage id="messages.possible_loyalty_earn.disclaimer" />
                  </p>
                </Grid>
              )
            }
          </Grid>
        </Grid>

        <Grid item xs={12} md={5}>
          <BookingPaymentSummary
            bookingSummary={store.bookingSummary}
            isThankYouPage
            showProceedAndEmailQuote={false}
            confirmation={store.balancePayment}
          />
        </Grid>

        <BasicPreregistrationDialog store={store} isOpen={isOpen} onClose={closePreregistrationDialog} />
      </Grid>
    )
  )
}

export default observer(BookingConfirmation)
