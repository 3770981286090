import Grid from '@mui/material/Grid'
import {observer, useObserver} from 'mobx-react-lite'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import BookingSummaryModel from '../../domain/models/bookingSummaryModel'
import {ConfirmationBalance} from '../../domain/stores/payment-store'
import {ActionButtons} from './ActionButtons'
import './BookingPaymentSummary.scss'
import BookingSummarySection from './BookingSummarySection'
import { formatMoney, formatNumber } from '../../domain/helpers/number-formatters'
import { LoyaltyPointsEarnBadge } from '../LoyaltyPrograms/LoyaltyPointsEarnBadge'

interface IBookingPaymentSummaryProps {
  showProceedAndEmailQuote: boolean
  showEmailQuote?: boolean
  isThankYouPage?: boolean
  bookingSummary: BookingSummaryModel | null
  confirmation?: ConfirmationBalance
}
const BookingPaymentSummary = ({
  showProceedAndEmailQuote,
  showEmailQuote,
  isThankYouPage,
  bookingSummary,
  confirmation,
}: IBookingPaymentSummaryProps) => {
  return useObserver(() => {
    return (
      <Grid container direction='column'>
        <Grid item className='booking-summary' container direction='column'>
          <Grid item className="booking-summary__header">
            {
              isThankYouPage 
                ? <FormattedMessage id='booking.payment_summary.headers.thankyou' />
                : <FormattedMessage id='booking.payment_summary.headers.general' />
            }
          </Grid>
          {bookingSummary && (
            <>
              {bookingSummary.sections
                .filter(a => a.items.length > 0)
                .map(section => (
                  <BookingSummarySection key={section.type} item={section} />
                ))}

              <Grid item>
                <div className='booking-summary__total'>
                  <Grid item className='booking-summary-item'>
                    <div className='booking-summary__total-title'>
                      <FormattedMessage id='booking.total-rental' />
                    </div>
                    <div className='booking-summary-item__value'>
                      <span className='currency'>
                        {bookingSummary.rental.rental.totalPrice?.currency}{' '}
                      </span>
                      {formatMoney(bookingSummary.totalCustomerAmountPayable)}
                    </div>
                  </Grid>

                  {confirmation && (
                    <>
                      {confirmation.paid > 0 && (
                        <Grid item className='booking-summary-item'>
                          <div className='booking-summary__total-subtitle'>
                            <FormattedMessage id='booking.amount-paid' />
                          </div>
                          <div className='booking-summary-item__subvalue'>
                            <span className='currency'>{confirmation.currency} </span>
                            {formatMoney(confirmation.paid)}
                          </div>
                        </Grid>
                      )}

                      {confirmation.redemptionBalance && confirmation.redemptionBalance.totalPoints > 0 && (
                        <Grid item className='booking-summary-item'>
                          <div className='booking-summary__total-subtitle'>
                            <FormattedMessage id='booking.redeemed-points.qff' />
                          </div>
                          <div className='booking-summary-item__subvalue'>
                            {formatNumber(confirmation.redemptionBalance.totalPoints)}<br />
                          </div>
                        </Grid>  
                      )}

                      {
                        isThankYouPage && (
                          <>
                            {confirmation.adminFee > 0 && (
                              <Grid item className='booking-summary-item'>
                                <div className='booking-summary__total-subtitle'>
                                  <FormattedMessage id='booking.admin-fee' />
                                </div>
                                <div className='booking-summary-item__subvalue'>
                                  <span className='currency'>{confirmation.currency} </span>
                                  {formatMoney(confirmation.adminFee)}
                                </div>
                              </Grid>
                            )}

                            <Grid item className='booking-summary-item'>
                              <div className='booking-summary__total-title'>
                                <FormattedMessage id='booking.gross-outstanding' />
                              </div>
                              <div className='booking-summary-item__value'>
                                <span className='currency'>{confirmation.currency} </span>
                                {formatMoney(confirmation.adminFee + confirmation.payable + confirmation.chargesAtPickup)}
                              </div>
                            </Grid>
                          </>
                        )
                      }

                      {
                        !isThankYouPage && confirmation.paid > 0 && (
                          <Grid item className='booking-summary-item'>
                            <div className='booking-summary__total-title'>
                              <FormattedMessage id='booking.net-outstanding' />
                            </div>
                            <div className='booking-summary-item__value'>
                              <span className='currency'>{confirmation.currency} </span>
                              {formatMoney(confirmation.payable + confirmation.chargesAtPickup)}
                            </div>
                          </Grid>  
                        )
                      }
                    </>
                  )}
                  {bookingSummary.refundableBond !== undefined && (
                    <Grid item className='booking-summary-item'>
                      <div className='booking-summary__total-title'>
                        {bookingSummary.bondLabel()}
                      </div>
                      <div className='booking-summary-item__value'>
                        <span className='currency'>
                          {bookingSummary.rental.rental.totalPrice?.currency}{' '}
                        </span>
                        {formatMoney(bookingSummary.refundableBond)}
                      </div>
                    </Grid>
                  )}
                  {!isThankYouPage && (!confirmation || confirmation.paid === 0) && (
                    <div className='booking-summary__total-payable'>
                      <div>
                        {
                          bookingSummary.selectedPaymentOption?.type === 'full' ? (
                            <FormattedMessage id='booking.total-amount-payable' />
                          ) : (
                            <FormattedMessage id='booking.deposit-amount-payable' />
                          )
                        }
                      </div>
                      <div className='booking-summary__total-amount'>
                        <span className='booking-summary__total-currency'>
                          {bookingSummary.rental.rental.totalPrice?.currency}{' '}
                        </span>
                        {bookingSummary.displayedAmountPayable}
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container item xs={12} style={{marginTop: "1em"}}>
          <ActionButtons showProceedAndEmailQuote={showProceedAndEmailQuote} showEmailQuote={showEmailQuote} />
        </Grid>

        {
          bookingSummary && (
            <Grid item xs={12}>
              <Grid container item direction="column" className='loyaltyProgramLogoList'>
                {Object.entries(bookingSummary.loyaltyEarnRates || []).filter(v => v[1] > 0).map(i => {
                  const provider = i[0].toLowerCase()

                  return (
                    <Grid key={provider} container item xs={12} alignItems="center">
                      <LoyaltyPointsEarnBadge provider={provider} amount={i[1]} />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          )
        }
      </Grid>
    )
  })
}

export default observer(BookingPaymentSummary)
