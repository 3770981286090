import {Grid} from '@mui/material'
import {observer} from 'mobx-react-lite'
import React, {useContext, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useHistory} from 'react-router-dom'
import {SearchStoreContext} from '../../App'
import {ReactComponent as DownArrowHead} from '../../assets/img/down-arrow-head.svg'
import {formatDate, formatForUrl} from '../../domain/helpers/date-formatters'
import {IAlternateResult} from '../../domain/stores/product'
import DailyRates from './DailyRates'
import ResultPrice from './ResultPrice'
import * as Analytics from '../../utils/analytics'
import { SearchStore } from '../../domain/stores/search-store'

export interface Charge {
  description: string
  amount: number
  rates: string[]
}

export interface IAlternativeResultsProps {
  alternatives: IAlternateResult[] | null
}
const AlternativeResults = ({alternatives}: IAlternativeResultsProps) => {
  const searchStore = useContext(SearchStoreContext)

  return alternatives && (
    <Grid item container className='daily-rates'>
      <Grid item container xs={12} className="daily-rates__header">
        <Grid item xs={3} md={4}>
          <FormattedMessage id="headers.pickup" />
        </Grid>
        <Grid item xs={3} md={4}>
          <FormattedMessage id="headers.dropoff" />
        </Grid>
        <Grid item xs={6} md={4} style={{textAlign: "right"}}>
          <FormattedMessage id="headers.amount" />
        </Grid>
      </Grid>

      {alternatives.length === 0 ? (
        <div className='daily-rates__item u-error'>
          <FormattedMessage id='search-result.no-search-alternatives' />
        </div>
      ) : (
        alternatives.map((alternative: IAlternateResult, index: number) => (
          <AlternativeResultItem
            key={index}
            alternative={alternative}
            searchStore={searchStore}
            index={index}
            className='daily-rates__item'
          />
        ))
      )}
    </Grid>
  )
}

interface IAlternateResultItemProps {
  className: string,
  alternative: IAlternateResult,
  searchStore: SearchStore,
  index: number
}

const AlternativeResultItem = ({
  className,
  alternative,
  searchStore,
  index
}: IAlternateResultItemProps) => {
  const history = useHistory()
  const [showDailyRates, setShowDailyRates] = useState(false)
  const bookingUrl = searchStore.getBookingUrl(
    {
      ...searchStore.searchParameters!,
      ...{
        pick_up_location: alternative.pickupDepotCode,
        drop_off_location: alternative.dropOffDepotCode,
        pick_up_date: formatForUrl(alternative.pickupDateTime),
        drop_off_date: formatForUrl(alternative.dropOffDateTime),
      },
    },
    alternative.code,
    alternative.ratePlanCode
  )

  return (
    <>
      <Grid item container xs={12} className={className}>
        <Grid item xs={3} md={4}>
          <div>{alternative.pickupDepotName}</div>
          <div>{formatDate(alternative.pickupDateTime)}</div>
        </Grid>
        <Grid item xs={3} md={4}>
          <div>{alternative.dropOffDepotName}</div>
          <div>{formatDate(alternative.dropOffDateTime)}</div>
        </Grid>

        <Grid item xs={6} md={4} style={{textAlign: 'right'}}>
          <ResultPrice currency={alternative.currency} amount={alternative.total} vendorCode={alternative.vendorCode} />
        </Grid>
        <Grid item container xs={12} className='search-result-item__price-text' justifyContent="flex-end">
          <Grid item xs={12} md={3}>
            <div className='search-result-item__select-vehicle--button'>
              <button
                className='search-result-item__select-vehicle__see-daily-rates'
                onClick={e => {
                  if (!showDailyRates) {
                    Analytics.trackEvent('show_daily_rates')
                  }

                  setShowDailyRates(!showDailyRates)
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                <FormattedMessage id='search-result.see-daily-rates' />
                <DownArrowHead />
              </button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className='search-result-item__select-vehicle--button'>
              <button
                className='search-result-item__select-vehicle__select-vehicle-button'
                onClick={() => {
                  Analytics.selectProduct({
                    code: alternative.code,
                    name: alternative.name,
                    vendor: alternative.vendorCode,
                    list_position: index,
                    total: alternative.total,
                  }, "alternative_results")
                  history.push(bookingUrl)
                }}
              >
                <FormattedMessage id="search-result.select-vehicle" />
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {showDailyRates && (
        <div className='search-result-item__rates u-margin-top-med '>
          <DailyRates charges={alternative.charges} />
        </div>
      )}
    </>
  )
}

export default observer(AlternativeResults)
