import {Grid} from '@mui/material'
import {observer, useObserver} from 'mobx-react-lite'
import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {FormattedHTMLMessage, FormattedMessage, useIntl} from 'react-intl'
import {RouteComponentProps} from 'react-router'
import {useHistory, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {PaymentStoreContext} from '../../App'
import {BookingDetails} from '../BookingDetails/BookingDetails'
import {PickupDropoffInfo} from '../BookingDetails/PickupDropoffInfo'
import './BookingPayment.scss'
import BookingPaymentOption from './BookingPaymentOption'
import Container from './Container'
import { BondPayableNotice } from './partner-components/BondPayableNotice'
import { PaymentStore } from '../../domain/stores/payment-store'
import { successfulPaymentUrlFrom } from '../../domain/helpers/url-helpers'
import { AdditionalPaymentDisclaimers } from './AdditionalPaymentDisclaimers'
import { LoyaltySchemeDetails } from '../GuestDetails/LoyaltySchemeDetails'
import { calculatePossibleEarnRatesFrom } from '../../domain/services/LoyaltyPointsService'
import { GuestRegistrationDetail, ILoyaltyProgramMembershipDetail, PointsRedemptionResultPayload } from '../../domain/models/search-models'
import { LoyaltyProgramType } from '../../domain/services/MetadataService'
import { PointsPlusPayBookingPaymentOption } from './PointsPlusPayBookingPaymentOption'
import { useCallback } from 'react'
import { contactNumberFor } from '../../domain/helpers/partner-helpers'
import { Hidden } from '../Hidden'
import { BasicPreRegistrationSection } from './partner-components/BasicPreregistrationDialog'

interface IPaymentOptionsProps
  extends RouteComponentProps<{reservationNumber: string; lastName: string}> {}

const Label: FunctionComponent<{htmlFor: string; className?: string}> = props => (
  <label htmlFor={props.htmlFor} className={props.className}>
    {props.children}
  </label>
)

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return null
}

const BookingPayment = (props: IPaymentOptionsProps) => {
  const history = useHistory()
  const location = useLocation()
  const paymentStore = useContext(PaymentStoreContext)
  const intl = useIntl()
  const isInError = new URLSearchParams(location.search).get('paymentError')

  useEffect(() => {
    const messageFor = (key: string): string => {
      return intl.formatMessage({id: key})
    }

    const f = async () => {
      if (isInError) toast.error(messageFor('payment.store.payment.failed.error.message'))
      
      const rn = props.match.params.reservationNumber
      const sn = props.match.params.lastName
      if (rn && sn) await paymentStore.setQuotationFrom(rn, sn)
      
      if (!paymentStore.isConfirmed && !paymentStore.isCancelled && !paymentStore.quoteNotFound && !paymentStore.vehicleStillAvailable)
        toast.error(messageFor('payment.store.availability-confirmation.failed.error.message'))
    }
    f()
  }, [paymentStore, intl, props.match.params.reservationNumber, props.match.params.lastName, isInError])

  const redirectToThankyouPage = () => {
    const url = successfulPaymentUrlFrom(paymentStore.reservationNumber!, paymentStore.quotation!.customer.surname)
    history.replace(url)

    return null
  }

  const shouldSkipPaymentPage = () => {
    if (!paymentStore.quotation) return false
    if (paymentStore.balancePayment.isPaidInFull) return true

    return paymentStore.isConfirmed && paymentStore.balancePayment.possiblyIncursSurcharge
  }

  return useObserver(() => {
    if (shouldSkipPaymentPage())
      return redirectToThankyouPage()
    else if (paymentStore.quoteNotFound)
      return <QuoteNotFoundPage paymentStore={paymentStore} />
    else if (paymentStore.isCancelled)
      return <QuoteCancelledPage paymentStore={paymentStore} />
    else
      return <BookingContinuationPage paymentStore={paymentStore} />
  })
}

const BookingContinuationPage: FunctionComponent<{paymentStore: PaymentStore}> = props => {
  const paymentStore = props.paymentStore

  return useObserver(() => (
    <div className={`page-content partner_${paymentStore.quotation?.partner.code}`}>
      <ScrollToTopOnMount />
      <BookingDetails
        tracker={paymentStore.tracker}
        bookingSummary={paymentStore.bookingSummary}
        confirmation={paymentStore.balancePayment}
        pickupDropoffInfo={paymentStore.pickupDropoffInfo}
        showGoBackButton={false}
        showProceedAndEmailQuote={false}
        step={2}
        afterInfoPane={
          <Grid item xs={12}>
            {
              paymentStore.anyWithLoyaltyPoints && (
                <Grid item>
                  <p className="loyaltyProgramDisclaimerText">
                    <sup>&dagger;</sup>
                    <FormattedMessage id="messages.possible_loyalty_earn.disclaimer" />
                  </p>
                </Grid>
              )
            }
          </Grid>
        }
      >
        {paymentStore.pickupDropoffInfo && (
          <Hidden smDown>
            <PickupDropoffInfo {...paymentStore.pickupDropoffInfo} />
          </Hidden>
        )}

        <div className='booking-payment'>
          <>
            <ContactDetailsContainer paymentStore={paymentStore} />
            {paymentStore.vehicleStillAvailable === true && <BookingContinuationSection paymentStore={paymentStore} />}
            {paymentStore.vehicleStillAvailable === false && <VehicleTypeNotAvailableContainer paymentStore={paymentStore} />}
          </>
        </div>
      </BookingDetails>
    </div>
  ))
}

const QuoteNotFoundPage: FunctionComponent<{paymentStore: PaymentStore}> = props => {
  const paymentStore = props.paymentStore

  return useObserver(() => (
    <div className={`page-content partner_${paymentStore.quotation?.partner?.code}`}>
      <ScrollToTopOnMount />
      <BookingDetails
        tracker={paymentStore.tracker}
        bookingSummary={null}
        pickupDropoffInfo={paymentStore.pickupDropoffInfo}
        showGoBackButton={false}
        showProceedAndEmailQuote={false}
        step={2}
        hideChildrenUntilComplete={true}
        confirmation={paymentStore.balancePayment}
      >
        <div className='booking-payment'>
          <Container titleKey='headers.dialogs.quote_not_found'>
            <div style={{marginTop: '1em'}}>
              <p>
                <FormattedHTMLMessage id="messages.quote_not_found" />
              </p>
            </div>
          </Container>
        </div>
      </BookingDetails>
    </div>
  ))
}

const QuoteCancelledPage: FunctionComponent<{paymentStore: PaymentStore}> = props => {
  const paymentStore = props.paymentStore

  return useObserver(() => (
    <div className={`page-content partner_${paymentStore.quotation?.partner?.code}`}>
      <ScrollToTopOnMount />
      <BookingDetails
        tracker={paymentStore.tracker}
        bookingSummary={null}
        pickupDropoffInfo={paymentStore.pickupDropoffInfo}
        showGoBackButton={false}
        showProceedAndEmailQuote={false}
        step={2}
        hideChildrenUntilComplete={true}
        confirmation={paymentStore.balancePayment}
      >
        {paymentStore.pickupDropoffInfo && (
          <Hidden smDown>
            <PickupDropoffInfo {...paymentStore.pickupDropoffInfo} />
          </Hidden>
        )}

        <div className='booking-payment'>
          <Container titleKey='headers.dialogs.quote_not_found'>
            <div style={{marginTop: '1em'}}>
              <p>
                <FormattedHTMLMessage
                  id="messages.quote_expired"
                  values={{
                    contactNumber: contactNumberFor(paymentStore.rental.startDepot!.country!.code, paymentStore.quotation!.partner.code)
                  }} />
              </p>

              {paymentStore.retrySearchUrl && (
                <div>
                  <FormattedMessage id="action-buttons.retry-search">
                    {t => (
                      <input
                        className='button-primary'
                        type='button'
                        value={t as string}
                        onClick={() => {
                          if (paymentStore.retrySearchUrl)
                            window.location.href = paymentStore.retrySearchUrl
                        }}
                      />
                    )}
                  </FormattedMessage>
                </div>
              )}
            </div>
          </Container>
        </div>
      </BookingDetails>
    </div>
  ))
}

const ContactDetailsContainer: FunctionComponent<{paymentStore: PaymentStore}> = props => {
  const paymentStore = props.paymentStore

  return (
    <Container titleKey='headers.dialogs.contact_details' showTick={true}>
      <Grid container>
        <Grid container item md={12}>
          <Grid xs={12} md={4} item>
            <div>
              <Label htmlFor='quoteNumber'>
                <FormattedMessage id='guest-details.quote-number' />
              </Label>
              <strong>{paymentStore.quotation?.reservationNo}</strong>
            </div>
          </Grid>
          <Grid xs={12} md={4} item>
            <div>
              <Label htmlFor='firstName'>
                <FormattedMessage id='guest-details.first-name' />
              </Label>
              {paymentStore.quotation?.customer.firstName}
            </div>
          </Grid>
          <Grid xs={12} md={4} item>
            <div>
              <Label htmlFor='lastName'>
                <FormattedMessage id='guest-details.last-name' />
              </Label>
              {paymentStore.quotation?.customer.surname}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

const BookingContinuationSection: FunctionComponent<{paymentStore: PaymentStore}> = props => {
  const paymentStore = props.paymentStore
  const [loyaltyNumberFormState, setLoyaltyNumberFormState] = useState<ILoyaltyProgramMembershipDetail>({})
  const [requiresPreRegistration, setRequiresPreRegistration] = useState(false)
  const [ronaRegistrationDetails, setRonaRegistrationDetails] = useState<GuestRegistrationDetail | null>(null)

  const tryConfirmQuote = async () => {
    await Promise.all([
      paymentStore.appendLoyaltyNumberToQuote(loyaltyNumberFormState),
      maybeRegisterPreRegistraionDetails()
    ])

    window.location.href = await paymentStore.getPaymentUrl()
  }

  const maybeRegisterPreRegistraionDetails = async () => {
    if (!requiresPreRegistration || !ronaRegistrationDetails) return
    await paymentStore.attachPreregistrationDetail(ronaRegistrationDetails)
  }

  const onRonaRegistrationDetailsChanged = (detail: GuestRegistrationDetail) => {
    setRonaRegistrationDetails(detail)
  }

  useEffect(() => {
    paymentStore.hasKnownAddressDetails()
      .then(result => setRequiresPreRegistration(result === false))
  }, [paymentStore])

  return useObserver(() => (
    <>
      <LoyaltyProgramSection paymentStore={paymentStore} onChange={setLoyaltyNumberFormState} />

      {
        requiresPreRegistration && (
          <Grid container className="container">
            <Grid container item md={12}>
              <BasicPreRegistrationSection
                country={paymentStore.quotation?.rental.startDepot?.country?.code}
                onChange={onRonaRegistrationDetailsChanged} />
            </Grid>
          </Grid>
        )
      }

      {
        paymentStore.invoiceLoaded && (
          <PaymentDetailsContainer paymentStore={paymentStore} handleSubmit={tryConfirmQuote} />
        )
      }
    </>
  ))
}

const LoyaltyProgramSection: FunctionComponent<{paymentStore: PaymentStore, onChange: (value: ILoyaltyProgramMembershipDetail) => void}> = props => {
  const paymentStore = props.paymentStore
  const quotation = paymentStore.quotation
  const productAvailabilityVerificationResponse = paymentStore.productAvailabilityVerificationResponse
  const earnRates = productAvailabilityVerificationResponse && calculatePossibleEarnRatesFrom(productAvailabilityVerificationResponse)
  const showLoyaltySection = !!earnRates && Object.values(earnRates).some(v => v > 0)
  const currentLoyaltyDetail = quotation?.customer.loyaltyProgramNo ? {provider: LoyaltyProgramType.QFF, number: quotation?.customer.loyaltyProgramNo} : undefined

  const handleLoyaltyMembershipDetailChanges = (_surname: string, providerDetails: ILoyaltyProgramMembershipDetail) => {
    props.onChange(providerDetails)
  }

  return useObserver(() => {
    return (showLoyaltySection && earnRates) ? (
      <Grid container className="container">
        <Grid container item md={12}>
          <LoyaltySchemeDetails
            surname={quotation!.customer.surname}
            onChange={handleLoyaltyMembershipDetailChanges}
            possiblePointEarnRates={earnRates}
            current={currentLoyaltyDetail} />
        </Grid>
      </Grid>
    ) : null
  })
}

const PaymentDetailsContainer: FunctionComponent<{paymentStore: PaymentStore, handleSubmit: () => void}> = props => {
  const paymentStore = props.paymentStore
  const bookingSummary = paymentStore.bookingSummary

  const onPointRedemptionRegistered = useCallback((pointsProvider: string, payload: PointsRedemptionResultPayload) => {
    paymentStore.registerPointsRedemption(pointsProvider, payload)
  }, [paymentStore])

  useEffect(() => {
    const timer = setInterval(async () => {
      if (paymentStore.quotation && paymentStore.currentPointRedemptions && paymentStore.currentPointRedemptions.length > 0) {
        var success = await paymentStore.reloadInvoiceInBackground();
        if (!success) clearInterval(timer)
      }
    }, 20000);
    return () => { clearInterval(timer); };
  }, [paymentStore]);

  const paymentOptionsDisplay = () => (
    <>
      {
        bookingSummary && bookingSummary.amountPayableToApollo > 0 && (
          <PointsPlusPayBookingPaymentOption
            redemptionOptions={paymentStore.invoiceResponse?.charge?.redemptionOptions || []}
            redemptions={(paymentStore.invoiceResponse?.redemptions || []).filter(r => r.isCurrent)}
            value={bookingSummary.amountPayableToApollo}
            onPointRedemptionRegistered={onPointRedemptionRegistered} />
        )
      }

      {paymentStore.paymentOptions.map((option) => (
        <BookingPaymentOption
          key={option.type}
          selected={paymentStore.isSelectedPaymentOption(option)}
          paymentOptionType={option}
          country={paymentStore.rental.startDepot!.country!.code}
          date={paymentStore.rental.startDateTime}
          onPaymentSelected={() => {
            paymentStore.setSelectedPaymentOption(option)
          }}
        />
      ))}

      <BondPayableNotice store={paymentStore} />

      {paymentStore.quotation?.partner.code && paymentStore.quotation?.rental.startDepot?.country?.code && (
        <div style={{marginTop: "20px", marginLeft: "20px", fontSize: "small"}}>
          <AdditionalPaymentDisclaimers partnerCode={paymentStore.quotation?.partner.code} country={paymentStore.quotation?.rental.startDepot?.country?.code} />
        </div>
      )}

      <FormattedMessage id="action-buttons.pay">
        {t => (
          <input
            className='button-primary'
            disabled={!paymentStore.getSelectedPaymentOption()?.type}
            type='button'
            onClick={props.handleSubmit}
            value={t as string}
          />
        )}
      </FormattedMessage>
    </>
  )

  const paymentOptionsErrorNotice = () => (
    <div className='u-error'>
      <FormattedMessage id='payment.noPaymentOptions' />
    </div>
  )

  return useObserver(() => (
    <Container titleKey='headers.dialogs.payment_options'>
      {
        paymentStore.paymentOptions.length > 0
          ? paymentOptionsDisplay()
          : paymentOptionsErrorNotice()
      }
    </Container>
  ))
}

const VehicleTypeNotAvailableContainer: FunctionComponent<{paymentStore: PaymentStore}> = props => {
  const paymentStore = props.paymentStore

  return (
    <Container titleKey='headers.dialogs.vehicle_not_available'>
      <div style={{marginTop: '1em'}}>
        <FormattedMessage id='payment.vehicleTypeUnavailable' />

        {
          paymentStore.retrySearchUrl && (
            <div>
              <FormattedMessage id="action-buttons.retry-search">
                {t => (
                  <input
                    className='button-primary'
                    type='button'
                    value={t as string}
                    onClick={() => {
                      if (paymentStore.retrySearchUrl)
                        window.location.href = paymentStore.retrySearchUrl
                    }}
                  />
                )}
              </FormattedMessage>
            </div>
          )
        }
      </div>
    </Container>
  )
}

export default observer(BookingPayment)
