import {Box, CircularProgress, Dialog, DialogContent, Grid, Skeleton} from '@mui/material'
import {observer, useObserver} from 'mobx-react-lite'
import React, {FunctionComponent, useCallback, useContext, useEffect, useState} from 'react'
import {RouteComponentProps, useLocation} from 'react-router'
import {SearchStoreContext} from '../../App'
import {SearchParameters, VendorCode} from '../../domain/models/search-models'
import {Page} from '../Page/Page'
import {VehicleLogo} from '../VehicleLogo/VehicleLogo'
import BookingSearchFilter from './BookingSearchFilter'
import BookingSearchResultGroup from './BookingSearchResultGroup'
import {BookingSearchResultItem} from './BookingSearchResultItem'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography'
import './BookingSearchResults.scss'
import UpdateSearch, { UpdateSearchDialogLayout } from './UpdateSearch'
import * as Analytics from '../../utils/analytics'
import { contactUsLinkFor } from '../../domain/services/MetadataService'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { SuggestedDealsBanner } from './SuggestedDealsBanner'
import { DealPanel, SuggestedDealsDialog } from './SuggestedDealsDialog'
import { GroupedResultset, SearchStore } from '../../domain/stores/search-store'
import { SpecialSearchResultCandidate } from '../../domain/models/specialSearchResultCandidate'
import { AvailabilityHeatmap } from './AvailabilityHeatmap'
import { searchUrlFrom } from '../../domain/helpers/url-helpers'
import { formatForUrl } from '../../domain/helpers/date-formatters'
import { useHistory } from 'react-router-dom'
import { Product } from '../../domain/stores/product'
import classNames from 'classnames'
import { SearchResultChallengeResponse } from '../../domain/services/Api'
import { Hidden } from '../Hidden'

interface IBookingSearchResultsProps extends RouteComponentProps<SearchParameters> {}

interface IUnavailableOptionsProps {
  vendorCode: VendorCode
  action: (() => void)
}

interface IVehicleSearchResultsProps {
  results: GroupedResultset[]
  moreResultsBanner: JSX.Element | false
}

interface IPromotedAlternativesDisplayProps {
  alternatives: SpecialSearchResultCandidate[]
}

interface ISearchRevisionProps {
  searchStore: SearchStore
}

interface IChallengeProps {
  searchStore: SearchStore
}

const NoResultsPlaceholder = () => {
  const searchStore = useContext(SearchStoreContext)
  const partnerCode = searchStore.searchParameters!.partner_code
  const country = searchStore.searchParameters!.country

  return (
    <Grid item>
      <div>
        <FormattedMessage id="messages.search_results.no_results_found" />
      </div>
      <FormattedMessage id="messages.contact_reservations">
        {t => (
          <input
            type='button'
            className='button-primary'
            value={t as string}
            onClick={() => {
              window.open(
                contactUsLinkFor(partnerCode, country),
                '_blank',
                'noopener noreferrer'
              )
            }}
          />
      )}
      </FormattedMessage>
    </Grid>
  )
}

const UnavailableVehiclesNotice = ({action, vendorCode}: IUnavailableOptionsProps) => {
  return (
    <Grid container className={`${vendorCode} unavailable-search-result-banner`} onClick={action}>
      <Hidden smDown>
        <Grid item md={1}>
          <InfoOutlinedIcon />
        </Grid>
      </Hidden>

      <Grid item xs={12} md={11}>
        <div>
          <div className="header">
            <FormattedMessage id="messages.search_results.more_results_header" />
          </div>
          <div>
            <FormattedHTMLMessage id="messages.search_results.more_results_body" />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

const NoVisibleSearchResultsDisplay = ({moreResultsBanner}: IVehicleSearchResultsProps) => {
  return (
    <>
      {
        moreResultsBanner && (
          <Grid item container>
            {moreResultsBanner}
          </Grid>
        )
      }

      <NoResultsPlaceholder />
    </>
  )
}

const SearchResultsDisplayPlaceholder = () => {
  const vehicleResultPlaceholder = (
    <>
      <Grid container item xs={8} alignItems="flex-start">
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="50%" height={30} />
        </Grid>

        <Grid container item xs={12} direction="row">
          <Grid item xs={1}>
            <Skeleton variant="circular" width={30} height={30} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="circular" width={30} height={30} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="circular" width={30} height={30} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="circular" width={30} height={30} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="circular" width={30} height={30} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Skeleton variant="rectangular" width="100%" height={150} />
      </Grid>
    </>
  )

  return (
    <Grid container item spacing={3}>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </Grid>

        <Grid container item spacing={3} xs={12} direction="row">
          <Grid item xs={2}>
            <Skeleton variant="rectangular" width={90} height={40} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="rectangular" width={90} height={40} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="rectangular" width={90} height={40} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="rectangular" width={90} height={40} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item spacing={5}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="text" width="25%" />
        </Grid>

        {
          [1, 2, 3, 4, 5, 6, 7, 8].map(i => (
            <Grid key={`placeholder-${i}`} container item spacing={3} xs={12}>
              {vehicleResultPlaceholder}
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  )
}

const VehicleSearchResultsDisplay = ({results, moreResultsBanner}: IVehicleSearchResultsProps) => {
  const searchStore = useContext(SearchStoreContext)
  const isVisible = (product: Product): boolean => !searchStore.hideUnavailableFilter || product.info.isAvailable

  const allUnavailable = results.every(gr => gr.results.every(r => !r.product.info.isAvailable))
  const visibleVendorResults = results.filter(gr => gr.results.some(r => isVisible(r.product)))

  return (
    <>
      {
        allUnavailable && (
          <NoVisibleSearchResultsDisplay results={results} moreResultsBanner={moreResultsBanner} />
        )
      }

      {visibleVendorResults.map((groupedResult, i) => {
        const visibleResults = groupedResult.results.filter(r => isVisible(r.product))

        return (
          <Grid container item key={groupedResult.vendorCode}>
            <BookingSearchResultGroup vendorCode={groupedResult.vendorCode} vehicleCount={visibleResults.length}>
              {
                visibleResults.map(selectableProduct => (
                  <BookingSearchResultItem
                    key={selectableProduct.product.code}
                    selectableProduct={selectableProduct}
                    bookingContinuationUrl={searchStore.getBookingUrl(
                      searchStore.searchParameters!,
                      selectableProduct.product.code,
                      selectableProduct.product.info.ratePlanCode
                    )}
                  />
                ))
              }
            </BookingSearchResultGroup>

            {
              moreResultsBanner &&
                ((visibleVendorResults.length > 1 && i === 0) || i === visibleVendorResults.length - 1) && (
                  <Grid item container>
                    {moreResultsBanner}
                  </Grid>
                )
            }
          </Grid>
        )
      })}
    </>
  )
}

const PromotedAlternativesDisplay = ({alternatives}: IPromotedAlternativesDisplayProps) => {
  const searchStore = useContext(SearchStoreContext)
  const promotedAlternatives = alternatives
    .sort((a, b) => b.specialDetail.score - a.specialDetail.score)
    .slice(0, 3)

  return (
    <Grid container item spacing={3} direction="column">
      <Grid item>
        <div className={classNames([promotedAlternatives[0].product.info.vendorCode, "promoted-specials-header"])}>
          <h4><FormattedMessage id="search-result.promoted-specials-header" /></h4>
        </div>
      </Grid>

      {
        promotedAlternatives.map((d, i) => (
          <Grid container item key={d.specialDetail.deal.inventoryControlNumber}>
            <DealPanel searchStore={searchStore} suggestedDeal={d} index={i} />
          </Grid>
        ))
      }
    </Grid>
  )
}

const SearchResultsDisplay = () => {
  const history = useHistory()
  const searchStore = useContext(SearchStoreContext)

  const showUnavailableBannerClicked = () => {
    searchStore.showUnavailableVehicleOptions()
    window.scrollTo(0, 0)
  }

  return useObserver(() => {
    const groupedResults = searchStore.groupedSearchResults
    if (groupedResults.length === 0) return null

    const firstVendor = groupedResults[0].vendorCode
    const anyResultsHidden = searchStore.hideUnavailableFilter && groupedResults.some(gr => gr.results.some(r => !r.product.info.isAvailable))
    const hasSpecialSearchResults = searchStore.specialSearchResults && searchStore.specialSearchResults.length > 0

    const moreResultsBanner = anyResultsHidden && (
      <UnavailableVehiclesNotice
        vendorCode={firstVendor}
        action={showUnavailableBannerClicked} />
    )

    const heatmapClicked = (date: Date) => {
      const microsecondsInHour = 60 * 60 * 1000
      const sixDays = 6 * 24 * microsecondsInHour

      const pickupHour = searchStore.pickupDate!.getHours()
      const dropoffHour = searchStore.dropOffDate!.getHours()
      
      const searchParams: SearchParameters = {
        ...searchStore.searchParameters!,
        pick_up_date: formatForUrl(new Date(date.getTime() + (pickupHour * microsecondsInHour))),
        drop_off_date: formatForUrl(new Date(date.getTime() + sixDays + (dropoffHour * microsecondsInHour)))
      }

      Analytics.trackEvent("price_availability_heatmap_clicked")

      history.push(searchUrlFrom(searchParams))
      searchStore.hideUnavailableFilter = true
    }

    return (
      <Grid container item spacing={3}>
        {
          !searchStore.hideUnavailableFilter
            && searchStore.searchResultMetadata?.priceAvailabilityHeatmap && (
              <Hidden smDown>
                <Grid item xs={12}>
                  <AvailabilityHeatmap
                    onClick={heatmapClicked}
                    selectedDate={searchStore.pickupDate!}
                    heatmapData={searchStore.searchResultMetadata.priceAvailabilityHeatmap} />
                </Grid>
              </Hidden>
            )
        }

        {
          !searchStore.hideUnavailableFilter && hasSpecialSearchResults && (
            <PromotedAlternativesDisplay alternatives={searchStore.specialSearchResults!} />
          )
        }

        <VehicleSearchResultsDisplay results={groupedResults} moreResultsBanner={moreResultsBanner} />

        {
          searchStore.hideUnavailableFilter
            && hasSpecialSearchResults
            && !groupedResults.some(gr => gr.results.some(r => r.product.info.isAvailable))
            && (
              <PromotedAlternativesDisplay alternatives={searchStore.specialSearchResults!} />
            )
        }

        {
          searchStore.anyWithLoyaltyPoints && (
            <Grid item>
              <p className="loyaltyProgramDisclaimerText">
                <sup>&dagger;</sup>
                <FormattedMessage id="messages.possible_loyalty_earn.disclaimer" />
              </p>
            </Grid>
          )
        }
      </Grid>
    )
  })
}

const SearchResultsDisplaySelector = () => {
  const searchStore = useContext(SearchStoreContext)

  return useObserver(() => {
    if (!searchStore.tracker.isComplete)
      return <SearchResultsDisplayPlaceholder />

    if (searchStore.groupedProductResults.length === 0)
      return <NoResultsPlaceholder />

    return <SearchResultsDisplay />
  })
}

export const BookingSearchResults = (props: IBookingSearchResultsProps) => {
  const location = useLocation()
  const searchStore = useContext(SearchStoreContext)

  const [suggestedDealsPopupVisible, setSuggestedDealsPopupVisible] = useState(false)
  const [showSearchRevisionDialog, setShowSearchRevisionDialog] = useState(false)

  useEffect(() => {
    const searchParams = props.match.params
    const queryParameters = new URLSearchParams(location.search)
    searchParams.membership_number = queryParameters.get("membership_number") || searchParams.membership_number

    searchStore.updateSearchParameters(searchParams)

    searchStore.search().then(() => {
      const flattenedResults = searchStore.groupedSearchResults.flatMap(group => group.results)

      Analytics.displayedVehicleList(flattenedResults)
    })
  }, [searchStore, props.match.params, location.search])

  useEffect(() => {
    setShowSearchRevisionDialog(searchStore.shouldPromptRevisedSearch)
  }, [searchStore, searchStore.searchResultsResponse])

  const refineSearchExpansionChanged = (_event: any, isExpanded: boolean) => {
    if (isExpanded) Analytics.trackEvent("refine_search_expanded")
  }

  const showSuggestedDealsPopup = () => {
    Analytics.trackEvent("view_hotdeals_popup")
    setSuggestedDealsPopupVisible(true)
  }

  const closeSuggestedDealsPopup = () => {
    setSuggestedDealsPopupVisible(false)
  }

  return useObserver(() => (
    <Page tracker={searchStore.tracker}>
      <Hidden mdDown>{searchStore.searchParameters && <UpdateSearch />}</Hidden>
      <Hidden mdUp>
        <div className="collapse-update-search">
          <Accordion onChange={refineSearchExpansionChanged}>
            <AccordionSummary
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>
                <FormattedMessage id="headers.search_filter.refine_search" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {searchStore.searchParameters && <UpdateSearch />}
            </AccordionDetails>
          </Accordion>
        </div>
      </Hidden>
      <Grid container className={classNames({'search-results': true, 'challenged': !!searchStore.searchResultsChallengeDetail})} alignItems='flex-start'>
        <Grid item xs={12} md={3} className='search-results__filters'>
          <BookingSearchFilter />
        </Grid>
        <Grid container item xs={12} md={9}>
          {
            searchStore.specialSearchResults && searchStore.specialSearchResults.length > 0 && (
              <SuggestedDealsBanner onClick={showSuggestedDealsPopup} />
            )
          }

          {searchStore.availableVendorCodes.length !== 0 && (
            <Hidden smDown>
              <Grid item container alignItems='center'>
                <Grid item xs={12}>
                  <div className='search-results__logos'>
                    {searchStore.availableVendorCodes
                      .filter(v => searchStore.selectedBrands.includes(v))
                      .map(code => (
                        <div className='search-results__logo' key={code}>
                          <VehicleLogo
                            vendorCode={code}
                            className='search-results__top-logo'
                            onClick={() => document.getElementById(code)?.scrollIntoView()}
                          />
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          )}

          <SearchResultsDisplaySelector />
        </Grid>
      </Grid>

      {
        searchStore.specialSearchResults && searchStore.specialSearchResults.length > 0 && (
          <SuggestedDealsDialog
            isOpen={suggestedDealsPopupVisible}
            onClose={closeSuggestedDealsPopup}
            searchStore={searchStore} />
        )
      }

      {
        searchStore.searchResultsChallengeDetail && (
          <SearchChallengeDialog searchStore={searchStore} />
        )
      }

      {
        !searchStore.searchResultsChallengeDetail && showSearchRevisionDialog && (
          <SearchRevisionDialog searchStore={searchStore} />
        )
      }
    </Page>
  ))
}

const SearchRevisionDialog: FunctionComponent<ISearchRevisionProps> = observer(({ searchStore }) => {
  return useObserver(() => (
    <Dialog aria-labelledby='edit-search-parameters' open={true}>
      <DialogContent>
        <div style={{marginBottom: '1em'}}>
          Oops, the drop off date selected is before your pick up date.<br />
          Please update your travel dates and search again.
        </div>

        {
          searchStore.searchParameters && (
            <UpdateSearchDialogLayout />
          )
        }
      </DialogContent>
    </Dialog>
  ))
})

const SearchChallengeDialog = ({ searchStore }: IChallengeProps) => {
  const [captchaLoaded, setCaptchaLoaded] = useState(false)

  const refreshSearchResults = useCallback((captchaResponse: string) => {
    const challengeResponse: SearchResultChallengeResponse = {
      signedRetryToken: searchStore.searchResultsChallengeDetail!.signedRetryToken,
      captchaResponse: captchaResponse
    }

    searchStore.search(challengeResponse)
  }, [searchStore])

  const onCaptchaLoaded = useCallback(() => {
    setCaptchaLoaded(true);

    (window as any).grecaptcha.render('captcha_container', {
      'sitekey': '6LftptcUAAAAACvYfAh0gimCUZmx9alQfTrSp-xE',
      'callback' : refreshSearchResults
    })
  }, [setCaptchaLoaded, refreshSearchResults])

  useEffect(() => {
    (window as any).onCaptchaLoaded = onCaptchaLoaded;
    
    const e = document.createElement('script')
    e.type = 'text/javascript'
    e.src = 'https://www.google.com/recaptcha/api.js?onload=onCaptchaLoaded&render=explicit'
    document.getElementsByTagName('head')[0].appendChild(e)
  }, [onCaptchaLoaded])

  const browserVerificationStep = () => (
    <Grid container style={{margin: 20}} direction="column" alignItems="center">
      <Grid item>
        Please wait while we perform some checks against your web browser...
      </Grid>

      <Grid item>
        <Box style={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="indeterminate" />
        </Box>
      </Grid>
    </Grid>
  )

  const continueSearchStep = () => (
    <Grid container spacing={3} direction="column" alignItems="center">
      <Grid item>
        <div id="captcha_container"></div>
      </Grid>
    </Grid>
  )

  return (
    <Dialog aria-labelledby='edit-search-parameters' open={true}>
      <DialogContent>
        {
          captchaLoaded
            ? continueSearchStep()
            : browserVerificationStep()
        }
      </DialogContent>
    </Dialog>
  )
}
