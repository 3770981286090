import { Grid } from '@mui/material'
import React from "react"
import { FunctionComponent } from "react"

import styles from './SuggestedDealsBanner.module.scss'

interface ISuggestedDealsBannerProps {
  onClick: () => void
}

export const SuggestedDealsBanner: FunctionComponent<ISuggestedDealsBannerProps> = props => {
  return (
    <Grid container item onClick={props.onClick} className={styles.suggested_deals_banner_container}>
      <Grid item xs={6} sm={3}>
        <span className={styles.hotdeals_header}>
          <p className={styles.hotdeals_hot}>Hot</p>
          <p className={styles.hotdeals_deals}>Deals!</p>
        </span>
      </Grid>

      <Grid item xs={6} sm={9}>
        <div className={styles.blurb_container}>
          <p className={styles.dates_flexible}>Flexible with your travel?</p>
          <p className={styles.savings_blurb}>
            <span className={styles.savings_blurb_line1}>Save up to </span>
            <span className={styles.savings_blurb_line2}>55% off</span>
          </p>
          <p className={styles.action_button}>Find out more</p>
        </div>
      </Grid>
    </Grid>
  )
}
