import { membershipNumberValidationFormatsFor } from "./MetadataService"

export const validateMembershipNumber = (partnerCode: string, membershipNumber: string): boolean => {
  const validatingValue = membershipNumber.replace(" ", "")
  if (validatingValue.length === 0) return true

  const validationFormats = membershipNumberValidationFormatsFor(partnerCode)
  if (validationFormats.length === 0) return true
  
  return validationFormats.some(r => r.test(validatingValue))
}