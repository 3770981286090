import { useObserver } from 'mobx-react-lite'
import React, {ReactNode} from 'react'
import LoadingPanel from '../LoadingPanel/LoadingPanel'
import {WorkTracker} from '../LoadingPanel/work-tracker'
import './Page.scss'

interface IPageProps {
  small?:boolean
  children?: ReactNode
  tracker: WorkTracker
  hideChildrenUntilComplete?: boolean
}

export const Page = (props: IPageProps) => {
  return useObserver(() => (
    <div className='page'>
      <main>
        <LoadingPanel workTracker={props.tracker} color='light' small={props.small}>
          {(!props.hideChildrenUntilComplete || props.tracker.isComplete) && (
            <article>
              <section>
                <div id='page-content' className='page__content'>
                  {props.children}
                </div>
              </section>
            </article>
          )}
        </LoadingPanel>
      </main>
    </div>
  ))
}
