import { processPointsBreakdown } from "../../utils/regx";
import { RentalActivityBase } from "../models/api-models";
import { VendorCode } from "../models/search-models";
import { LoyaltyProgramType, loyaltySchemesFor } from "./MetadataService";

const QFFProductCodes = ["QFFBONUS", "QFFBASE"]
export const LoyaltyProductFeeCodes = [...QFFProductCodes]

export interface ILoyaltyPointEarnRates {
  [scheme: string]: number
}

export interface IPointSource {
  productCode: string
  priceSource: string
}

export const calculatePossibleEarnRatesFrom = (quote: RentalActivityBase): ILoyaltyPointEarnRates | null => {
  const vendorCode: VendorCode = quote.vendor.code as VendorCode
  const applicableLoyaltySchemes = loyaltySchemesFor(vendorCode, quote.rental.startDateTime)
  if (applicableLoyaltySchemes.length === 0) return null

  const result: ILoyaltyPointEarnRates = {}

  if (applicableLoyaltySchemes.includes(LoyaltyProgramType.QFF))
    result[LoyaltyProgramType.QFF] = totalQFFPointsFrom(quote)

  return result
}

const totalQFFPointsFrom = (quote: RentalActivityBase): number => {
  return totalPointsFrom(quote, QFFProductCodes)
}

const totalPointsFrom = (quote: RentalActivityBase, interestingProductCodes: string[]): number => {
  const interestingItems = pointSourcesFrom(quote).filter(item => interestingProductCodes.includes(item.productCode))
  if (!interestingItems || interestingItems.length === 0) return 0

  return interestingItems.reduce((sum, item) => sum + pointsFrom(item.priceSource), 0)
}

const pointSourcesFrom = (quote: RentalActivityBase): IPointSource[] => {
  return [
    ...(quote.ancillaries.fees || []).map(f => {
      return {
        productCode: f.product.code,
        priceSource: f.priceText || f.pricing || ''
      }
    }),

    ...(quote.ancillaries.equipmentAndServices || []).map(f => {
      return {
        productCode: f.product.code,
        priceSource: f.priceText || f.pricing || ''
      }
    }),

    ...(quote.rental.charges || []).flatMap(charge => {
      return (charge.calculations || []).map(calc => {
        return {
          productCode: charge.product?.code || '',
          priceSource: calc.priceText || calc.pricing || ''
        }
      })
    }),
  ]
}

const pointsFrom = (pointsText: string | undefined): number => {
  if (!pointsText) return 0

  const breakdown = processPointsBreakdown(pointsText)
  return Number(breakdown?.totalPoints || 0)
}