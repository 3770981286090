import {observable} from 'mobx'
import {MessageDescriptor} from 'react-intl'
import * as Analytics from '../../utils/analytics'

export interface IWorkTrackerOptions {
  startCompleted?: boolean
  defaultMessage?: MessageDescriptor
  defaultDoneMessage?: MessageDescriptor
}

interface ITrackOptions<T> {
  message?: MessageDescriptor
  doneMessage?: (t: T) => MessageDescriptor
  minDelayMs?: number,
  analyticsKey?: string
}

export class WorkTracker {
  @observable public isComplete: boolean
  @observable public message: MessageDescriptor | null

  private activePromises: number

  constructor(private readonly options: IWorkTrackerOptions) {
    this.isComplete = !!options.startCompleted
    this.activePromises = 0
    this.message = options.defaultMessage || null
  }

  public readonly track = async <T>(promise: Promise<T>, options?: ITrackOptions<T>) => {
    this.message = options ? options.message || null : null
    this.isComplete = false
    this.activePromises++

    let result: T
    const startTime = Date.now()
    try {
      ;[result] = await Promise.all([promise, delay(options ? options.minDelayMs || 0 : 0)])

      this.message =
        options && options.doneMessage
          ? options.doneMessage(result)
          : this.options.defaultDoneMessage || null
    } finally {
      if (options?.analyticsKey) {
        Analytics.registerTiming(options.analyticsKey, Date.now() - startTime)
      }

      this.completePromise()
    }

    return result!
  }

  private readonly completePromise = () => {
    this.activePromises = Math.max(0, this.activePromises - 1)
    this.isComplete = this.activePromises === 0
  }
}

const delay = (ms: number) =>
  ms < 1 ? Promise.resolve() : new Promise<void>(r => setTimeout(r, ms))
