import React, {FunctionComponent, ReactElement} from 'react'

type TabState = {index: number; selected: string; hidden: string}
interface ITabProps {
  header?: boolean
  content?: boolean
  index: number
  children: ReactElement
  tabState: [TabState, React.Dispatch<React.SetStateAction<TabState>>]
}
export const Tab: FunctionComponent<ITabProps> = ({
  header,
  content,
  index,
  children: child,
  tabState,
}) => {
  if (!((header || content) && (!header || !content))) return <></>

  let newProps = {}
  if (header) {
    const newClassName = [
      child.props.className,
      tabState[0].index === index ? tabState[0].selected : '',
    ].join(' ')
    newProps = {
      onClick: () => tabState[1]({...tabState[0], index: index}),
      className: newClassName,
    }
  } else if (content) {
    const newClassName = [
      child.props.className,
      tabState[0].index === index ? '' : tabState[0].hidden,
    ].join(' ')
    newProps = {className: newClassName}
  }

  return React.cloneElement(child, {...newProps})
}
