import {Grid} from '@mui/material'
import React, {ReactNode} from 'react'
import { FormattedMessage } from 'react-intl'
import {ReactComponent as Tick} from '../../assets/img/payment-tick.svg'
import './Container.scss'

export interface IContainerProps {
  children?: ReactNode
  title?: string
  titleKey?: string
  showTick?: boolean
}
const Container = ({title, titleKey, children, showTick}: IContainerProps) => (
  <Grid container>
    <Grid item className='container' container xs={12}>
      <Grid item container xs={12} className='container__header'>
        <Grid item xs={11}>
          {title || <FormattedMessage id={titleKey} />}
        </Grid>
        <Grid item xs={1} className='container__icon'>
          {showTick && <Tick />}
        </Grid>
      </Grid>
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  </Grid>
)

export default Container
