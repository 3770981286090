import React from 'react'
import './ImportantInfo.scss'
import { IImportantInfoSection } from './IImportantInfoSection'

const Saver4PolicyAP = (params: IImportantInfoSection) => {
  if (params.locale === 'en-GB') {
    return Saver4PolicyEN
  } else {
    return Saver4PolicyDE
  }
}

const Saver4PolicyEN = (
  <div className='important-info'>
    <h3>Saver 2/4 Information</h3>
    <ul>
      <li>Guests selecting a Saver 2 or 4 unit accept they will be informed of their actual motorhome on the day of pick up.</li>
      <li>Saver 2 unit will be our choice of either the Duo, Duo Plus or Traveller. Maximum sleeping capcity is 2.</li>
      <li>Saver 4 unit will be our choice of either the Traveller, Traveller Plus and Voyager. Maximum sleeping capcity is 4.</li>
      <li>The guest does not have any choice and must accept the vehicle as allocated.</li>
    </ul>
  </div>
)

const Saver4PolicyDE = (
  <div className='important-info'>
    <h3>Saver 2/4 hinzufügen</h3>
    <ul>
      <li>Gäste, die sich den Saver 4 entscheiden, akzeptieren, dass sie am Tag der Abholung über ihr tatsächliches Wohnmobil informiert werden.</li>
      <li>Das Fahrzeug, Saver 2 wird unsere Wahl zwischen Duo, Duo Plus oder Traveller sein.</li>
      <li>Das Fahrzeug Saver 4 wird unsere Wahl zwischen Traveller, Traveller Plus oder Voyager sein.</li>
      <li>Der Gast hat keine Wahl und muss das Fahrzeug wie zugeteilt annehmen.</li>
    </ul>
  </div>
)

export default Saver4PolicyAP
