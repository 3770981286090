export const regxEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const priceBreakdownPattern = /((\d+) (day|night|week)s? free \+ )?(([\d]+) x )?((\d+) (day|night|week)s? @ )?([\d.,]+) each/i
const discountBreakdownPattern = /([-.\d]+)% of ([^\s]+) ([-\d,.]+)/i
const distanceBreakdownPattern = /Unlimited Kilometres|(\d+) Kilometres per (day|week|night) then ([\d.,]+) per Kilometre/i
const loyaltyPointsBreakdownPattern = /([\d,.]+) points/i

export type PriceBreakdownUnit =
  | 'day'
  | 'week'
  | 'night'

export interface IPriceBreakdown {
  qty: number
  units?: PriceBreakdownUnit
  unitCount?: number,
  perUnitPrice: number
  included?: {qty: number, units: PriceBreakdownUnit}
}

export interface IDiscountBreakdown {
  percentage: number
  currency: string
  totalDiscount: number
}

export interface IDistanceBreakdown {
  qty?: number
  unlimited: boolean
  units?: PriceBreakdownUnit
  overagePrice?: number
}

export interface ILoyaltyPointsBreakdown {
  totalPoints?: number
}

export const processPriceBreakdown = (pricingDescription: string): IPriceBreakdown | null => {
  const breakdown = priceBreakdownPattern.exec(pricingDescription.toLowerCase())
  if (!breakdown) return null

  const result: IPriceBreakdown = {
    qty: Number(breakdown[5] || 1),
    unitCount: Number(breakdown[7]),
    units: breakdown[8] as PriceBreakdownUnit,
    perUnitPrice: Number(breakdown[9].replace(',', ''))
  }

  if (breakdown[1]) {
    result.included = {
      qty: Number(breakdown[2]),
      units: breakdown[3] as PriceBreakdownUnit
    }
  }

  return result
}

export const processDiscountBreakdown = (discountDescription: string): IDiscountBreakdown | null => {
  const matches = discountBreakdownPattern.exec(discountDescription.toLowerCase())
  if (!matches) return null

  return {
    percentage: Number(matches[1]),
    currency: matches[2].toUpperCase(),
    totalDiscount: Number(matches[3].replace(',', ''))
  }
}

export const processDistanceBreakdown = (pricingDescription: string): IDistanceBreakdown | null => {
  const matches = distanceBreakdownPattern.exec(pricingDescription.toLowerCase())
  if (!matches) return null

  if (matches[1] === undefined) {
    return {unlimited: true}
  }

  return {
    qty: Number(matches[1]),
    unlimited: false,
    units: matches[2] as PriceBreakdownUnit,
    overagePrice: Number(matches[3].replace(',', ''))
  }
}

export const processPointsBreakdown = (pricingDescription: string): ILoyaltyPointsBreakdown | null => {
  const matches = loyaltyPointsBreakdownPattern.exec(pricingDescription.toLowerCase())
  if (!matches) return null

  return {
    totalPoints: Number(matches[1].replace(',', ''))
  }
}
