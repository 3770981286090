import Grid from '@mui/material/Grid'
import {useObserver} from 'mobx-react-lite'
import React, {ReactNode, useContext} from 'react'
import { BookingStoreContext, EditSearchStoreContext } from '../../App'
import BookingSummaryModel from '../../domain/models/bookingSummaryModel'
import { ConfirmationBalance } from '../../domain/stores/payment-store'
import BookingPaymentSummary from '../BookingSummary/BookingPaymentSummary'
import { Hidden } from '../Hidden'
import {WorkTracker} from '../LoadingPanel/work-tracker'
import {Page} from '../Page/Page'
import './BookingDetails.scss'
import {CustomisedStepper} from './CustomisedStepper'
import {GoBackButton} from './GoBackButton'
import {ChangeSearchParameterButton, IPickupDropoffInfoProps, PickupDropoffInfo} from './PickupDropoffInfo'

interface IBookingDetailProps {
  showGoBackButton: boolean
  showProceedAndEmailQuote: boolean
  showEmailQuote?: boolean
  children: ReactNode
  step: number
  afterInfoPane?: ReactNode
  tracker: WorkTracker
  pickupDropoffInfo: IPickupDropoffInfoProps | null
  bookingSummary: BookingSummaryModel | null
  hideChildrenUntilComplete?: boolean
  confirmation?: ConfirmationBalance
}

export const BookingDetails = (props: IBookingDetailProps) => {
  const store = useContext(BookingStoreContext)
  const editSearchStore = useContext(EditSearchStoreContext)

  return useObserver(() => {
    const bookingSummary = props.bookingSummary && (
      <div className='booking-details__booking-summary'>
        <BookingPaymentSummary
          bookingSummary={props.bookingSummary}
          showProceedAndEmailQuote={props.showProceedAndEmailQuote}
          showEmailQuote={props.showEmailQuote}
          confirmation={props.confirmation}
        />
      </div>
    )
    return (
      <Page tracker={props.tracker} hideChildrenUntilComplete={props.hideChildrenUntilComplete}>
        {props.pickupDropoffInfo && (
          <Hidden smUp>
            <Grid container item xs={12} className='booking-details__pd-container'>
              <PickupDropoffInfo {...props.pickupDropoffInfo} />
              <ChangeSearchParameterButton bookingStore={store} editStore={editSearchStore} />
            </Grid>
          </Hidden>
        )}

        <Grid container alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={bookingSummary ? 8 : 12}>
            <div className='booking-details__body'>
              <div className='booking-details__body-content'>
                <Grid container justifyContent='space-around' className='booking-details__nav'>
                  <Hidden smDown>
                    <GoBackButton />
                    <Grid container item xs={10}>
                      <CustomisedStepper step={props.step} />
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid container item xs={12} className='booking-details__info-pane'>
                  {props.children}
                </Grid>
                {props.afterInfoPane || <></>}
              </div>
            </div>
          </Grid>

          {
            bookingSummary && (
              <Grid item xs={12} sm={12} md={4} className='booking-details__sticky'>
                <div className='booking-details__summary'>{bookingSummary}</div>
              </Grid>
            )
          }
        </Grid>
      </Page>
    )
  })
}
