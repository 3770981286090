import React, {FunctionComponent, ReactNode, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import './CoverAndExtrasTabs.scss'
import {Tab} from './Tab'
import {useTheme, useMediaQuery} from '@mui/material'
import { Hidden } from '../Hidden'

interface ICoverAndExtrasTabsProps {
  covers: ReactNode
  extras: ReactNode
}
export const CoverAndExtrasTabs: FunctionComponent<ICoverAndExtrasTabsProps> = ({
  covers,
  extras,
}) => {
  const ancTabState = useState({
    index: useMediaQuery(useTheme().breakpoints.down('xs'), {noSsr: true}) ? -1 : 0,
    selected: 'cover-extras-tabs__header--selected',
    hidden: 'cover-extras-tabs__content--hidden',
  })

  return (
    <>
      <Hidden mdUp>
        <div className='cover-extras-tabs__content'>{covers}</div>
        <div className='cover-extras-tabs__content'>{extras}</div>
      </Hidden>

      <Hidden smDown>
        <Tab header index={0} tabState={ancTabState}>
          <span className='cover-extras-tabs__header'>
            <FormattedMessage id='booking.summary.coverHeader' />
          </span>
        </Tab>

        <Tab header index={1} tabState={ancTabState}>
          <span className='cover-extras-tabs__header'>
            <FormattedMessage id='booking.summary.extrasHeader' />
          </span>
        </Tab>

        <Tab content index={0} tabState={ancTabState}>
          <div className='cover-extras-tabs__content'>{covers}</div>
        </Tab>

        <Tab content index={1} tabState={ancTabState}>
          <div className='cover-extras-tabs__content'>{extras}</div>
        </Tab>
      </Hidden>
    </>
  )
}
