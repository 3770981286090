import {useObserver} from 'mobx-react-lite'
import React, {useContext, useEffect} from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {PaymentStoreContext} from '../../App'
import {WorkTracker} from '../LoadingPanel/work-tracker'
import {Page} from '../Page/Page'
import BookingConfirmation from './BookingConfirmation'
import './PaymentConfirmation.scss'

interface IBookingConfirmationProps
  extends RouteComponentProps<{reservationNumber: string; lastName: string}> {}

const PaymentConfirmation = (props: IBookingConfirmationProps) => {
  const store = useContext(PaymentStoreContext)
  const tracker = store.quotation ? new WorkTracker({startCompleted: true}) : store.tracker

  useEffect(() => {
    const loadQuoteFromParams = async () => {
      const rn = props.match.params.reservationNumber
      const sn = props.match.params.lastName
      if (rn && sn) await store.setQuotationFrom(rn, sn)
      
      if (store.quotation && store.isCancelled)
        window.location.href = `/payment/${store.reservationNumber}/${store.quotation.customer.surname}`
    }

    if (!store.quotation) loadQuoteFromParams()
  }, [store, props.match.params.reservationNumber, props.match.params.lastName])

  return useObserver(() => {
    return (
      <div className="page-content">
        <Page tracker={tracker}>
          <BookingConfirmation />
        </Page>
      </div>
    )
  })
}

export default PaymentConfirmation
