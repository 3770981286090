import React from 'react'
import './EmailSubscription.scss'
import { FormattedMessage } from 'react-intl'
import { marketingListKeysFor, PartnerMarketingKey } from '../../domain/helpers/partner-helpers'

export interface IEmailSubscriptionProps {
  onChecked: (partnerCampaign: PartnerMarketingKey, checked: boolean) => void
  partnerCode: string | undefined
}

const EmailSubscription = (props: IEmailSubscriptionProps) => {
  const checkboxList = marketingListKeysFor(props.partnerCode).map(partnerCampaign => {
    const checkboxId = `emailSubscriptionCheckbox:${partnerCampaign.marketingKey}`

    return (
      <div className='es checkbox'>
        <input onChange={e => props.onChecked(partnerCampaign, e.target.checked)} id={checkboxId} type='checkbox' />
        <label htmlFor={checkboxId}>
          <FormattedMessage id={partnerCampaign.marketingKey} />
        </label>
      </div>
    )
  })

  return <>{checkboxList}</>
}

export default EmailSubscription


