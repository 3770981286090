import {Grid} from '@mui/material'
import {useObserver} from 'mobx-react-lite'
import React, {FunctionComponent, useContext} from 'react'
import {BookingStoreContext} from '../../App'
import {CoverOption} from '../../domain/models/search-models'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { formatMoney } from '../../domain/helpers/number-formatters'
import { FormattedMessage } from 'react-intl'

interface ICoversProps {
  coverOptions: CoverOption[]
}
export const Covers: FunctionComponent<ICoversProps> = props => {
  const store = useContext(BookingStoreContext)
  const isSelected = (code: string) => store.selectedCoverOptionCode === code
  return useObserver(() => {
    const selectCover = async (code: string) => {
      await store.updateSelectedCover(code)
      await store.getAncillaryOptions()
    }
    return (
      <Grid container item xs={12} className='cover__container'>
        {props.coverOptions.map(cover => {
          const securityDepositAmount = (
            <span className='cover__liability-amount'>
              {cover.currency} {formatMoney(cover.liability || 0)}
            </span>
          )

          return (
            <Grid container item xs={12} className='cover__item' key={cover.code}>
              <Grid container item xs={12} md={9} className='cover__info'>
                <Grid item xs={12} className='cover__name'>
                  {cover.displayName}
                </Grid>

                <Grid item xs={12} className='cover__description'>
                  {cover.description}
                  <div className='cover__liability-refundable'>
                    <FormattedMessage id="booking.summary.security_deposit_applies" values={{bondLabel: cover.bondLabel, amount: securityDepositAmount}} />
                  </div>
                </Grid>
                <Grid item xs={12} className='cover__inclusions'>
                  <FormattedMessage id="booking.summary.pack_inclusions" />
                </Grid>
                <Grid item xs={12}>
                  {cover.inclusions.map(inclusion => {
                    return (
                      <div className='cover__inclusions--list' key={inclusion}>
                        <span className='cover__inclusions--list-check'>
                          <CheckCircleOutlineIcon />
                        </span>
                        {inclusion}
                      </div>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={3}
                alignItems='center'
                justifyContent='center'
                className='cover__button-container'
              >
                <Grid item>
                  <Grid item xs={12} className='cover__dailyprice'>
                    <FormattedMessage
                      id={`booking.summary.cover_price.${cover.priceBreakdown ? cover.priceBreakdown.units : 'day'}`}
                      values={{currency: cover.currency, price: cover.dailyPrice}} />
                  </Grid>
                  <FormattedMessage id={isSelected(cover.code) ? 'booking.summary.cover_selected' : 'booking.summary.select_cover'}>
                    {(translated) => (
                      <input
                        type='button'
                        className={`cover__select ${isSelected(cover.code) ? 'cover--selected' : ''}`}
                        value={translated as string}
                        onClick={async () => selectCover(cover.code)}
                      />
                  )}
                  </FormattedMessage>
                  {
                    cover.priceBreakdown && cover.priceBreakdown.included && (
                      <Grid item xs={12} className='cover__capped'>
                        <FormattedMessage id={`booking.summary.cover_capped.${cover.priceBreakdown.units}`} values={{cappedAmount: cover.priceBreakdown.unitCount}} />
                      </Grid>
                    )
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} className='cover__seperator'></Grid>
            </Grid>
          )
        })}
      </Grid>
    )
  })
}
