import {Grid} from '@mui/material'
import React, {FunctionComponent} from 'react'
import {Feature, VendorCode} from '../../domain/models/search-models'
import {FeaturesTable} from '../BookingSearchResults/FeaturesTable'
import {NameAndBerth} from '../BookingSearchResults/NameAndBerth'
import {SpecsLink} from '../BookingSearchResults/SpecsLink'
import './RentalDetailsTab.scss'

export interface IRentalDetailsTabProps {
  berth: number
  name: string
  vendorCode: VendorCode
  features: Feature[]
  moreDetails: string
}
export const RentalDetailsTab: FunctionComponent<IRentalDetailsTabProps> = ({
  berth,
  name,
  vendorCode,
  features,
  moreDetails,
}) => (
  <Grid item xs={12} container className='rental-details'>
    <Grid item xs={12}>
      <NameAndBerth berth={berth} name={name} vendorCode={vendorCode} />
    </Grid>
    <Grid item xs={12}>
      <SpecsLink vendorCode={vendorCode} moreDetails={moreDetails} />
    </Grid>
    <Grid item xs={12}>
      <FeaturesTable features={features} vendorCode={vendorCode} />
    </Grid>
  </Grid>
)
