import Chart from 'react-apexcharts'
import React from 'react'
import {FunctionComponent} from 'react'
import { HeatmapDataMap, PriceAvailabilityHeatmapData } from '../../domain/services/Api'
import { ApexOptions } from 'apexcharts'
import { formatDate } from '../../domain/helpers/date-formatters'
import { useIntl } from 'react-intl'

interface IAvailabilityHeatmapProps {
  heatmapData: PriceAvailabilityHeatmapData
  selectedDate: Date
  onClick: (date: Date) => void
}

export const AvailabilityHeatmap: FunctionComponent<IAvailabilityHeatmapProps> = ({heatmapData, selectedDate, onClick}: IAvailabilityHeatmapProps) => {
  const oneWeekInMicroseconds = 60 * 60 * 24 * 7 * 1000;
  const intl = useIntl()

  const percentileLabels = [
    ["heatmap_labels.price.low", "heatmap_labels.price.medium", "heatmap_labels.price.high"],
    ["heatmap_labels.availability.low", "heatmap_labels.availability.medium", "heatmap_labels.availability.high"]
  ]

  const percentileLabelFrom = (series: number[], value: number, labels: string[]) => {
    if (series.length === 0) return null
    const percentile = series.filter(n => n <= value).length / series.length

    if (percentile <= 0.4) return labels[0]
    if (percentile >= 0.9) return labels[2]
    return labels[1]
  }

  const cellClicked = (_e: any, _c: any, o: any) => {
    const index = o.dataPointIndex
    if (index < 0) return

    const date = heatmapData.availabilityData.data[index].x as Date
    onClick(date)
  }

  const options: ApexOptions = {
    colors: ["#FF9800"],
    dataLabels: { enabled: false },
    tooltip: {
      x: {
        formatter: (val): string => {
          const start = new Date(val)
          const end = new Date(val + oneWeekInMicroseconds)

          return formatDate(start, "DD MMM") + " - " + formatDate(end, "DD MMM")
        }
      },
      y: {
        formatter: function(value, { series, seriesIndex }) {
          const labelKey = percentileLabelFrom(series[seriesIndex], value, percentileLabels[seriesIndex])
          if (!labelKey) return ""
          
          return intl.formatMessage({id: labelKey})
        }
      }
    },
    annotations: {
      xaxis: [{
        x: selectedDate.getTime(),
        strokeDashArray: 0, 
        opacity: 1, 
        borderColor: "firebrick",
        fillColor: "firebrick"
      }]
    },
    xaxis: {
      type: "datetime",
      labels: {
        showDuplicates: false,
        hideOverlappingLabels: true,
        rotateAlways: true,
        format: "MMM"
      }
    },
    plotOptions: {
      heatmap: {
        enableShades: true,
        shadeIntensity: 0.2,
        distributed: true
      }
    },
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      events: { click: cellClicked }
    }
  }

  const normaliseLabels = (data: HeatmapDataMap): HeatmapDataMap => {
    return {
      name: data.name,
      data: data.data.map(point => ({
        x: point.x as Date,
        y: point.y
      }))
    }
  }

  const series = [
    normaliseLabels(heatmapData.priceData),
    normaliseLabels(heatmapData.availabilityData)
  ]

  return (
    <div>
      <Chart options={options} series={series} type='heatmap' width='100%' height={100} />
    </div>
  )
}
