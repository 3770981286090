import React, { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, LinearProgress } from '@mui/material'
import { EditSearchStoreContext } from '../../App'
import styles from './EditBookingParameters.module.scss'
import { observer } from 'mobx-react-lite'
import DatePicker from 'react-datepicker'
import { toDate } from '../../domain/helpers/date-formatters'
import { useHistory } from 'react-router-dom'
import { formatMoney } from '../../domain/helpers/number-formatters'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { EditSearchStore } from '../../domain/stores/edit-search-store'
import { PickupDropoffComparisonDisplay } from './PickupDropoffInfo'
import { getDailyChargesFor } from '../../domain/stores/product'
import { Price } from '../../domain/models/api-models'
import { FormattedMessage, useIntl } from 'react-intl'
import { tryRenderSummaryLine } from '../../domain/models/bookingSummaryModel'

const renderOption = (keyPrefix: string, key: string, description: string) => (
  <option key={`${keyPrefix}-${key}`} value={key}>
    {description}
  </option>
)

const sequentialDropdownOptions = (max: number) => {
  const results = []

  for (var counter = 0; counter <= max; counter++) {
    results.push(<option key={counter} value={counter}>{counter}</option>)
  }

  return results
}

interface IEditBookingParameters {
  open: boolean
  handleClose: () => void
}

interface IModalWrapper extends IEditBookingParameters, IHasCallback {
  title: string
  children: ReactNode
}

interface IHasEditSearchStore {
  store: EditSearchStore
}

interface IHasCallback extends IHasEditSearchStore {
  callback: () => void
}

const ConfigureBookingParametersModal: FunctionComponent<IModalWrapper> = observer(({ title, open, handleClose, children, store, callback }) => {
  useEffect(() => {
    if (!store.bookingParameters) return
    store.updateParameters(store.bookingParameters)
  }, [store, store.bookingParameters])

  const clearSearch = () => store.clearSearch()

  return (
    <>
      <Dialog aria-labelledby='edit-search-parameters' open={open} onClose={handleClose} PaperProps={{ className: styles.dialog }}>
        <DialogTitle>
          <span className={styles.title}>{title}</span>
        </DialogTitle>
        <DialogContent>
          <Grid container item md={12} className={styles.gridContainer}>
            {store.getPriceException && <SearchErroredNotice store={store} />}
            {
              !store.isBusy && (
                !store.newRentalPrice
                  ? <SearchFields store={store} callback={callback} />
                  : <ClearSearchButton store={store} callback={clearSearch} />
              )
            }

            {
              (store.isBusy || store.newRentalPrice) &&
                <NewSearchInfoHeader store={store} />
            }

            {store.isBusy && <SearchInProgressNotice store={store} />}

            {children}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
})

export const ConfigureRelocationParameters: FunctionComponent<IEditBookingParameters> = observer(({ open, handleClose }) => {
  const t = useIntl()
  const history = useHistory()
  const editSearchStore = useContext(EditSearchStoreContext)

  const getQuoteForRelocation = async () => editSearchStore.getQuoteForRelocation()

  const applyNewSearchCriteria = async () => {
    if (!editSearchStore.bookingParameters) return;
    if (!editSearchStore.newRentalPrice) return;

    history.replace(editSearchStore.bookingUrl) // Use .replace here so that the back button on the nav bar works
    handleClose()
  }

  return (
    <>
      <ConfigureBookingParametersModal title={t.formatMessage({id: "headers.booking_modify.relocation_title"})} open={open} handleClose={handleClose} store={editSearchStore} callback={getQuoteForRelocation}>
        {
          editSearchStore.originalRentalPrice && editSearchStore.newRentalPrice &&
            <SearchResultNotice store={editSearchStore} callback={applyNewSearchCriteria} />
        }
      </ConfigureBookingParametersModal>
    </>
  )
})

export const ConfigureSearchParameters: FunctionComponent<IEditBookingParameters> = observer(({ open, handleClose }) => {
  const t = useIntl()
  const history = useHistory()
  const editSearchStore = useContext(EditSearchStoreContext)

  const getQuoteForChangedSearchParameters = async () => editSearchStore.getQuoteForChangedSearchParameters()

  const applyNewSearchCriteria = async () => {
    if (!editSearchStore.bookingParameters) return;
    if (!editSearchStore.newRentalPrice) return;

    history.replace(editSearchStore.bookingUrl) // Use .replace here so that the back button on the nav bar works
    handleClose()
  }

  return (
    <>
      <ConfigureBookingParametersModal title={t.formatMessage({ id: "headers.booking_modify.configure_title" })} open={open} handleClose={handleClose} store={editSearchStore} callback={getQuoteForChangedSearchParameters}>
        {
          editSearchStore.originalRentalPrice && editSearchStore.newRentalPrice &&
            <SearchResultNotice store={editSearchStore} callback={applyNewSearchCriteria} />
        }
      </ConfigureBookingParametersModal>
    </>
  )
})

export const PickupLocationPicker: FunctionComponent<IHasEditSearchStore> = observer(({ store }) => {
  return (
    <select
      className={styles.select}
      id='pickupSelect'
      value={store.bookingParameters?.pick_up_location}
      onChange={e => store.clearAndRefreshPickupDepot(e.target.value)}
      disabled={!store.pickupDepots}
    >
      {store.pickupDepots?.map(depot => renderOption('pickup-', depot.code, depot.name))}
    </select>
  )
})
export const DropoffLocationPicker: FunctionComponent<IHasEditSearchStore> = observer(({ store }) => {
  return (
    <select
      className={styles.select}
      id='dropoffSelect'
      value={store.bookingParameters?.drop_off_location}
      onChange={e => store.clearAndRefreshDropoffDepot(e.target.value)}
      disabled={!store.dropOffDepots}
    >
      {store.dropOffDepots?.map(depot => renderOption('dropoff-', depot.code, depot.name))}
    </select>
  )
})
export const PickupDatePicker: FunctionComponent<IHasEditSearchStore> = observer(({ store }) => {
  return <DatePicker
    className={styles.datePicker}
    selected={toDate(store.bookingParameters?.pick_up_date!)}
    onChange={date => { store.updatePickupDate(date) }}
    dateFormat='dd MMM yyyy'
    excludeDates={store.pickupDepotDateExclusions}
    minDate={store.minStartDate}
    maxDate={store.maxStartDate}
    showYearDropdown={true}
    scrollableYearDropdown={true}
    showMonthDropdown={true}
  />
})
export const DropoffDatePicker: FunctionComponent<IHasEditSearchStore> = observer(({ store }) => {
  return <DatePicker
    className={styles.datePicker}
    selected={toDate(store.bookingParameters?.drop_off_date!)}
    onChange={date => { store.updateDropOffDate(date) }}
    dateFormat='dd MMM yyyy'
    excludeDates={store.dropoffDepotDateExclusions}
    minDate={store.minEndDate}
    maxDate={store.maxEndDate}
    showYearDropdown={true}
    scrollableYearDropdown={true}
    showMonthDropdown={true}
  />
})
const SearchFields: FunctionComponent<IHasCallback> = observer(({ store, callback }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <label>
          <FormattedMessage id="headers.search_filter.pickup_location" />
        </label>
        {
          store.isLoading
            ? <LinearProgress />
            : <PickupLocationPicker store={store} />
        }
      </Grid>
      <Grid item xs={12} md={6}>
        <label>
          <FormattedMessage id="headers.search_filter.dropoff_location" />
        </label>
        {
          store.isLoading
            ? <LinearProgress />
            : <DropoffLocationPicker store={store} />
        }        
      </Grid>
      <Grid item xs={12} md={6}>
        <label>
          <FormattedMessage id="headers.search_filter.pickup_date" />
        </label>
        <div className={styles.datePickerWrapper}>
          {
            store.isLoading
              ? <LinearProgress />
              : <PickupDatePicker store={store} />
          }
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <label>
          <FormattedMessage id="headers.search_filter.dropoff_date" />
        </label>
        <div className={styles.datePickerWrapper}>
          {
            store.isLoading
              ? <LinearProgress />
              : <DropoffDatePicker store={store} />
          }
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <label>
          <FormattedMessage id="headers.search_filter.min_adults" />
        </label>
        <select
          className={styles.select}
          value={store.bookingParameters?.adults || 2}
          id='sleepFilterSelect'
          onChange={e => store.updateSleepsAdultsFilter(e.target.value)}
        >
          {
            [2, 4, 6].filter(n => !store.maxAllowedAdults || n <= store.maxAllowedAdults)
              .map(n => <option key={`${n}+`} value={n}>{`${n}+`}</option>)
          }
        </select>
      </Grid>
      <Grid item xs={12} md={6}>
        {
          store.maxAllowedChildren > 0 && (
            <>
              <label>
                <FormattedMessage id="headers.search_filter.min_children" />
              </label>
              <select
                className={styles.select}
                value={store.bookingParameters?.children || 0}
                id='childrenSleepFilterSelect'
                onChange={e => store.updateSleepsChildrenFilter(e.target.value)}
              >
                {sequentialDropdownOptions(store.maxAllowedChildren)}
              </select>
            </>
          )
        }
      </Grid>
      <Grid item xs={12}>
        <button onClick={callback} disabled={store.isBusy} className={styles.primaryActionButton}>
          <FormattedMessage id="action-buttons.configure-booking.run-search" />
        </button>
      </Grid>
    </>
  )
})

const NewSearchInfoHeader: FunctionComponent<IHasEditSearchStore> = ({store}) => {
  return (
    <PickupDropoffComparisonDisplay before={store.originalSearchDetail!} after={store.proposedSearchDetail} />
  )
}

const SearchInProgressNotice: FunctionComponent<IHasEditSearchStore> = () => {
  return (
    <Grid item xs={12}>
      <div className={styles.inProgressNotice}>
        <CircularProgress size={20}></CircularProgress>
        <p><FormattedMessage id="messages.booking_modify.searching" /></p>
      </div>
    </Grid>
  )
}

const SearchErroredNotice: FunctionComponent<IHasEditSearchStore> = () => {
  return (
    <Grid item xs={12}>
      <div className={styles.alert}>
        <ErrorOutlineIcon></ErrorOutlineIcon>
        <span><FormattedMessage id="messages.booking_modify.generic_error" /></span>
      </div>
    </Grid>
  )
}

const ClearSearchButton: FunctionComponent<IHasCallback> = ({ callback }) => {
  return (
    <Grid item xs={4}>
      <button onClick={callback} className={styles.secondaryActionButton}>
        <FormattedMessage id="action-buttons.configure-booking.search-again" />
      </button>
    </Grid>
  )
}

const SearchResultNotice: FunctionComponent<IHasCallback> = ({ store, callback }) => {
  const t = useIntl()
  const charges = getDailyChargesFor(store.newRentalPrice!)
  const priceDifference = store.difference

  const renderPrice = (price: Price | null) => {
    return (
      <div className={styles.chargeAmount}>
        <span className={styles.currency}>{price?.currency}</span>
        <span>{formatMoney(price?.amount || 0)}</span>
      </div>
    )
  }

  const renderPriceChangeLabel = (difference: number) => {
    if (difference === 0) return t.formatMessage({ id: "headers.booking_modify.price_unchanged" })
    else return t.formatMessage({ id: "headers.booking_modify.price_changed" })
    // else if (difference < 0) return t.formatMessage({ id: "headers.booking_modify.price_decreased" })
    // else return t.formatMessage({ id: "headers.booking_modify.price_increased" })
  }

  return (
    <Grid item container xs={12} className={styles.searchResultDisplayNotice}>
      <Grid item container xs={12}>
        {
          charges.map((charge, index) => (
            <Grid container key={`charge-item-${index}`}>
              <Grid item xs={4} md={4}>
                {charge.description}
              </Grid>
              <Grid item xs={5} md={6}>
                {charge.rates.map((it, i) => (
                  <div key={`${index}-${i}`}>
                    {it.pricingExplanationTemplate
                      ? tryRenderSummaryLine(t, it.pricingExplanationTemplate)
                      : it.pricingExplanation!}
                  </div>
                ))}
              </Grid>
              <Grid item xs={3} md={2} style={{ textAlign: 'right' }}>
                {renderPrice(charge.price || null)}
              </Grid>
            </Grid>
          ))
        }
        <Grid item xs={12} className={styles.originalRentalPriceLine}>
          <FormattedMessage id="headers.booking_modify.price_was" />
          {renderPrice(store.wasPrice)}
        </Grid>
        {
          priceDifference && (
            <Grid item xs={12} className={styles.rentalPriceDifferenceLine}>
              {renderPriceChangeLabel(priceDifference.amount)}
              {priceDifference.amount !== 0 && renderPrice(priceDifference)}
            </Grid>
          )
        }
        {
          store.nowPrice && (
            <Grid item xs={12} className={styles.newRentalPriceLine}>
              <FormattedMessage id="headers.booking_modify.price_now" />
              {renderPrice(store.nowPrice)}
            </Grid>
          )
        }
      </Grid>
      <Grid item xs={12}>
        <button onClick={callback} className={styles.primaryActionButton}>
          <span><FormattedMessage id="action-buttons.configure-booking.apply" /></span>
        </button>
      </Grid>
    </Grid>
  )
}