import { action, computed, observable } from "mobx";
import { IPickupDropoffInfoProps } from "../../components/BookingDetails/PickupDropoffInfo";
import { formatForUrl, toDate } from "../helpers/date-formatters";
import { configureUrlWithQueryFrom } from "../helpers/url-helpers";
import { Price, RentalActivityPriced } from "../models/api-models";
import { BookingParameters, Depots, DepotSchedulesModel, Relocation } from "../models/search-models";
import { SpecialSearchResultCandidate } from "../models/specialSearchResultCandidate";
import { getDepots, getDepotSchedules, getHotdeal, getPriceAvailProduct, getRelocation } from "../services/Api";
import { BookingStore } from "./booking-store";
import { LocationPath, SearchStore } from "./search-store";

export class EditSearchStore {
  @observable public newRentalPrice: RentalActivityPriced | null = null
  @observable public originalRentalPrice: RentalActivityPriced | null = null
  @observable public bookingParameters: BookingParameters | null = null
  @observable public originalSearchDetail: IPickupDropoffInfoProps | null = null
  @observable public isLoading: boolean = false
  @observable public isBusy: boolean = false
  @observable public getPriceException: string | null = null
  @observable public pickupDepotDateExclusions: Date[] = []
  @observable public dropoffDepotDateExclusions: Date[] = []

  @observable private depots: Depots | undefined
  @observable private pickupDepotSchedule: DepotSchedulesModel | undefined
  @observable private pickup_date: Date | undefined
  @observable private maximumPossibleAdults: number = 6
  @observable private maximumPossibleChildren: number = 0
  @observable private relocation: Relocation | undefined

  private instanceCache: {[key: string]: EditSearchStore} = {}
  public getSubInstanceFor(searchStore: SearchStore, deal: SpecialSearchResultCandidate): EditSearchStore {
    const key = deal.specialDetail.deal.inventoryControlNumber

    var instance = this.instanceCache[key]
    if (instance) return instance

    instance = new EditSearchStore()
    instance.resetWithSearchStoreAndSuggestedDeal(searchStore, deal)
    this.instanceCache[key] = instance

    return instance
  }

  @action clearSearch() {
    this.newRentalPrice = null
    this.getPriceException = null
  }
  @action private resetSearchDetails(bookingParameters: BookingParameters) {
    this.bookingParameters = this.deepCloneObject(bookingParameters)
    this.originalSearchDetail = this.buildSearchDetailFrom(bookingParameters)
  }
  @action public resetWithBookingStore(bookingStore: BookingStore) {
    if (!bookingStore.searchParameters || !bookingStore.selectedSearchResult)
      return

    this.resetSearchDetails(bookingStore.searchParameters)
    
    const selectedResult = bookingStore.selectedSearchResult
    this.originalRentalPrice = this.deepCloneObject(selectedResult)

    this.maximumPossibleAdults = selectedResult.rental?.product?.sleeping?.adult || 6
    this.maximumPossibleChildren = selectedResult.rental?.product?.seating?.restraintsChild || 0

    this.clearSearch()
  }
  @action public resetWithSearchStoreAndSuggestedDeal(searchStore: SearchStore, suggestedDeal: SpecialSearchResultCandidate) {
    if (!searchStore.searchParameters) return

    const { specialDetail, product } = suggestedDeal

    const bookingParameters: BookingParameters = {
      ...searchStore.searchParameters,
      pick_up_location: specialDetail.revisedStartLocationCode,
      drop_off_location: specialDetail.revisedEndLocationCode,
      pick_up_date: specialDetail.revisedStartDateTime,
      drop_off_date: specialDetail.revisedEndDateTime,
      vehicle_code: product.code,
      rate_plan_code: product.info.ratePlanCode,
      hotdeal_id: `${specialDetail.deal.inventoryKey}:${specialDetail.deal.inventoryControlNumber}`
    }

    this.resetSearchDetails(bookingParameters)
    this.originalRentalPrice = null

    this.maximumPossibleAdults = suggestedDeal.product.info.sleepingCapacity.adult
    this.maximumPossibleChildren = suggestedDeal.product.info.seatingCapacity.child

    this.clearSearch()

    this.updateParameters(bookingParameters)
  }
  @action public async updateParameters(bookingParameters: BookingParameters) {
    if (!bookingParameters) return

    this.bookingParameters = bookingParameters
    this.isLoading = true

    try {
      await Promise.all([
        this.refreshAvailableDepots(),
        this.refreshPickupDepot(bookingParameters.pick_up_location),
        this.refreshDropoffDepot(bookingParameters.drop_off_location),
        this.refreshSelectedRelocationDetails()
      ])
    } finally {
      this.isLoading = false
    }
  }

  @action public async getQuoteForRelocation() {
    if (!this.bookingParameters) return

    this.clearSearch()
    this.isBusy = true

    try {
      this.newRentalPrice = await getPriceAvailProduct(this.constrainBookingDates(this.bookingParameters))
    } catch (exp) {
      this.getPriceException = exp?.response?.data?.detail!
    } finally {
      this.isBusy = false
    }
  }
  @action public async getQuoteForChangedSearchParameters() {
    if (!this.bookingParameters) return

    this.clearSearch()
    this.isBusy = true

    try {
      this.newRentalPrice = await getPriceAvailProduct(this.constrainBookingDates(this.bookingParameters))
    } catch (exp) {
      this.getPriceException = exp?.response?.data?.detail!
    } finally {
      this.isBusy = false
    }
  }

  @action async clearAndRefreshPickupDepot(depotCode: string) {
    if (!this.bookingParameters) return

    this.clearSearch()
    await this.refreshPickupDepot(depotCode)
  }
  @action async clearAndRefreshDropoffDepot(depotCode: string) {
    if (!this.bookingParameters) return

    this.clearSearch()
    await this.refreshDropoffDepot(depotCode)
  }

  @action updatePickupDate(date: Date | null) {
    if (!this.bookingParameters || !date) return

    this.clearSearch()
    this.pickup_date = date
    this.bookingParameters.pick_up_date = formatForUrl(date)
  }
  @action updateDropOffDate(date: Date | null) {
    if (!this.bookingParameters || !date) return

    this.clearSearch()
    this.bookingParameters.drop_off_date = formatForUrl(date)
  }

  @action updateSleepsAdultsFilter(sleeps: string) {
    if (!this.bookingParameters) return

    this.clearSearch()
    this.bookingParameters.adults = sleeps || "1"
  }
  @action updateSleepsChildrenFilter(sleeps: string) {
    if (!this.bookingParameters) return

    this.clearSearch()
    this.bookingParameters.children = sleeps || "0"
  }

  @computed get wasPrice() {
    return this.originalRentalPrice?.customerInvoice?.charge?.amount || null
  }
  @computed get nowPrice() {
    return this.newRentalPrice?.customerInvoice?.charge?.amount || null
  }
  @computed get difference() {
    if (!this.wasPrice || !this.nowPrice) return null

    const currency = this.wasPrice.currency
    if (currency !== this.nowPrice.currency) return null

    return {
      currency: currency,
      amount: this.nowPrice?.amount! - this.wasPrice?.amount!
    } as Price
  }

  @computed get pickupDepots() {
    if (this.relocation) {
      const acceptedDepotCodes = this.relocation?.fromDepots.flatMap(d => d.code)
      return this.depots?.depots.filter(depot => acceptedDepotCodes.includes(depot.code))
    }

    return this.depots?.depots
  }
  @computed get dropOffDepots() {
    let acceptedDropoffDepots = this.depots?.depots;
    if (this.relocation) {
      const acceptedDepotCodes = this.relocation?.toDepots.flatMap(d => d.code)
      acceptedDropoffDepots = acceptedDropoffDepots?.filter(depot => acceptedDepotCodes.includes(depot.code))
    }

    return acceptedDropoffDepots?.filter(depot => this.pickupDepotSchedule?.dropOffAllowed.includes(depot.code))
  }

  @computed get proposedSearchDetail(): IPickupDropoffInfoProps {
    return this.buildSearchDetailFrom(this.bookingParameters!)
  }

  private dateExclusionsFrom(schedule: DepotSchedulesModel): Date[] {
    return schedule.operatingSchedules
      .map(os => os.holidays.map(h => h.dates.map(d => new Date(d)).flat()).flat())
      .flat()
  }
  private async scheduleFor(depotCode: string): Promise<DepotSchedulesModel> {
    const { partner_code, country, vehicle_type } = this.bookingParameters!
    return await getDepotSchedules(partner_code, country, vehicle_type, depotCode)
  }
  private async refreshPickupDepot(depotCode: string) {
    this.bookingParameters!.pick_up_location = depotCode
    this.pickupDepotSchedule = await this.scheduleFor(depotCode)
    this.pickupDepotDateExclusions = this.dateExclusionsFrom(this.pickupDepotSchedule)
  }
  private async refreshDropoffDepot(depotCode: string) {
    this.bookingParameters!.drop_off_location = depotCode
    const dropoffDepotSchedule = await this.scheduleFor(depotCode)
    this.dropoffDepotDateExclusions = this.dateExclusionsFrom(dropoffDepotSchedule)
  }

  private depotNameFromCode(code: string) {
    return this.depots?.depots.find(depot => depot.code === code)?.name
  }
  private constrainBookingDates(parameters: BookingParameters): BookingParameters {
    const result = this.deepCloneObject(parameters)

    const startDate = toDate(parameters.pick_up_date)
    const minStartDate = this.minStartDate
    result.pick_up_date = formatForUrl((minStartDate && startDate < minStartDate) ? minStartDate : startDate)

    const endDate = toDate(parameters.drop_off_date)
    const maxEndDate = this.maxEndDate
    result.drop_off_date = formatForUrl((maxEndDate && endDate > maxEndDate) ? maxEndDate : endDate)

    return result
  }
  private buildSearchDetailFrom(parameters: BookingParameters): IPickupDropoffInfoProps {
    const pickupLocationCode = parameters.pick_up_location
    const dropoffLocationCode = parameters.drop_off_location

    return {
      startDepot: {
        code: pickupLocationCode,
        name: this.depotNameFromCode(pickupLocationCode)!
      },
      startDateTime: toDate(parameters.pick_up_date),
      endDepot: {
        code: dropoffLocationCode,
        name: this.depotNameFromCode(dropoffLocationCode)!
      },
      endDateTime: toDate(parameters.drop_off_date),
      vehicleCode: parameters.vehicle_code
    }
  }
  private async refreshAvailableDepots() {
    const { partner_code, country, vehicle_type } = this.bookingParameters!
    this.depots = await getDepots(partner_code, country, vehicle_type)
  }
  private async refreshSelectedRelocationDetails() {
    if (this.bookingParameters?.relocation_id)
      this.relocation = await getRelocation(this.bookingParameters?.relocation_id)
    else if (this.bookingParameters?.hotdeal_id)
      this.relocation = await getHotdeal(this.bookingParameters?.hotdeal_id)
    else
      this.relocation = undefined
  }

  private dateOrFallbackFrom(date: Date | undefined, fallback: Date | undefined = undefined) {
    return (date && new Date(date)) || (fallback && new Date(fallback.valueOf()))
  }
  private ensureDateAfter(date: Date, minDate: Date) {
    while (date <= minDate) date.setDate(date.getDate() + 1)
  }

  private deepCloneObject<T>(object: T) {
    return JSON.parse(JSON.stringify(object)) as T
  }

  @computed get minStartDate(): Date {
    const now = new Date()
    var result = this.dateOrFallbackFrom(this.relocation?.earliestStartDateTime, now)!
    this.ensureDateAfter(result, now)
    return result
  }
  @computed get maxStartDate(): Date | undefined {
    return this.dateOrFallbackFrom(this.relocation?.latestStartDateTime, this.maxEndDate)
  }

  @computed get minEndDate(): Date {
    var result = this.dateOrFallbackFrom(this.relocation?.earliestEndDateTime, this.minStartDate)!
    this.ensureDateAfter(result, this.minStartDate)

    if (this.pickup_date)
      this.ensureDateAfter(result, this.pickup_date)

    return result
  }
  @computed get maxEndDate(): Date | undefined {
    return this.dateOrFallbackFrom(this.relocation?.latestEndDateTime)
  }

  @computed get maxAllowedAdults(): number | undefined {
    return this.relocation?.product?.maxTotalPax || this.maximumPossibleAdults
  }
  @computed get maxAllowedChildren(): number {
    return this.relocation?.product?.maxTotalChildren || this.maximumPossibleChildren
  }

  @computed get bookingUrl(): LocationPath {
    const params = this.constrainBookingDates(this.bookingParameters!)
    const additionalInfo = {
      membership_number: params.membership_number,
      relocation_identifier: params.relocation_id,
      deal_identifier: params.hotdeal_id
    }

    return configureUrlWithQueryFrom(params, params.vehicle_code, params.rate_plan_code, additionalInfo)
  }
}