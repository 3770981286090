import React from 'react'
import ChildRestraintPolicy from '../ImportantInfo/ChildRestraintPolicy'
import ChildRestraintPolicyCar from '../ImportantInfo/ChildRestraintPolicyCar'
import PetFriendlyPolicy from '../ImportantInfo/PetFriendlyPolicy'
import Restricted4WDPolicy from '../ImportantInfo/Restricted4WDPolicy'
import TravelRestrictionsPolicy from '../ImportantInfo/TravelRestrictionsPolicy'
import TravelRestrictionsPolicyCar from '../ImportantInfo/TravelRestrictionsPolicyCar'
import VehicleDisclaimerCar from '../ImportantInfo/VehicleDisclaimerCar'
import CovidInfoRestrictions from '../ImportantInfo/CovidInfoRestrictions'
import './ImportantInfoTab.scss'
import { SupportedLocale, VendorCode } from '../../domain/models/search-models'
import { IImportantInfoSection } from '../ImportantInfo/IImportantInfoSection'
import TimeTravelPolicy from '../ImportantInfo/TimeTravelPolicy'
import Saver4PolicyAP from '../ImportantInfo/Saver4PolicyAP'
import Saver4PolicyBK from '../ImportantInfo/Saver4PolicyBK'

export interface IImportantInformationTabProps {
  infos: string[],
  partnerCode: string,
  vendorCode: VendorCode,
  countryCode: string,
  locale: SupportedLocale
}

const infoMap = new Map([
  ['Saver4PolicyAP', Saver4PolicyAP],
  ['Saver4PolicyBK', Saver4PolicyBK],
  ['ChildRestraintPolicy', ChildRestraintPolicy],
  ['ChildRestraintPolicyCar', ChildRestraintPolicyCar],
  ['PetFriendlyPolicy', PetFriendlyPolicy],
  ['Restricted4WDPolicy', Restricted4WDPolicy],
  ['TravelRestrictionsPolicy', TravelRestrictionsPolicy],
  ['TravelRestrictionsPolicyCar', TravelRestrictionsPolicyCar],
  ['VehicleDisclaimerCar', VehicleDisclaimerCar],
  ['TimeTravelPolicy', TimeTravelPolicy],
  ['CovidInfoRestrictions',CovidInfoRestrictions]
])

export const ImportantInformationTab = (e: IImportantInformationTabProps) => {
  const params: IImportantInfoSection = e
  return (
    <div className='important-infos'>
      {
        e.infos.filter(info => infoMap.has(info)).map(info => {
          return <span key={info}>{infoMap.get(info)!(params)}</span>
        })
      }
    </div>
  )
}
