import React, { FunctionComponent, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Dialog, Grid } from "@mui/material"
import Image from '../Image/Image'
import styles from './LoyaltySchemeDetails.module.scss'
import { currentLocale, LoyaltyProgramType } from "../../domain/services/MetadataService"
import { ILoyaltyProgramMembershipDetail } from "../../domain/models/search-models"
import { ILoyaltyNumberValidationResult, validateLoyaltyNumber } from "../../domain/services/Api"
import { formatNumber } from "../../domain/helpers/number-formatters"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
const classNames = require('classnames')

interface ILoyaltySchemeSelectorProps {
  possiblePointEarnRates: {[scheme: string]: number}
  onChange: (surname: string, providerDetails: ILoyaltyProgramMembershipDetail) => void
  surname: string
  current?: ILoyaltyProgramMembershipDetail
}

interface ILoyaltyMemberDetails {
  membershipNumber: string
  surname: string
}

interface ILoyaltySchemeDetailProps {
  setProviderValue: (value: ILoyaltyMemberDetails | null) => void
  possiblePointsEarned: number
  value?: string
  surname: string
  persisted: boolean
}

interface ILoyaltyNumberValidationResultProps {
  validationResult: ILoyaltyNumberValidationResult
}

export const LoyaltySchemeDetails: FunctionComponent<ILoyaltySchemeSelectorProps> = ({onChange, surname, possiblePointEarnRates, current}) => {
  const [loyaltyNumbers, setLoyaltyNumbers] = useState(current ? {[current.provider]: current.number} : {})

  const storeLoyaltyMembershipDetail = (surname: string, providerDetails: ILoyaltyProgramMembershipDetail) => {
    setLoyaltyNumbers(providerDetails)
    onChange(surname, providerDetails)
  }

  const setProviderValue = (program: string, value: ILoyaltyMemberDetails | null) => {
    if (value) {
      storeLoyaltyMembershipDetail(value.surname, {...loyaltyNumbers, [program]: value.membershipNumber})
    } else {
      delete loyaltyNumbers[program]
      storeLoyaltyMembershipDetail(surname, loyaltyNumbers)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        {
          <QFFDetails
            setProviderValue={n => setProviderValue(LoyaltyProgramType.QFF, n)}
            possiblePointsEarned={possiblePointEarnRates[LoyaltyProgramType.QFF]}
            value={loyaltyNumbers[LoyaltyProgramType.QFF]}
            surname={surname}
            persisted={!!current} />
        }
      </Grid>
    </>
  )
}

const LoyaltyNumberValidationNotice: FunctionComponent<ILoyaltyNumberValidationResultProps> = ({validationResult}) => {
  const validNumberBadge = () => (
    <p className={classNames([styles.validationResultText, styles.validationResultSuccessText])}>
      <CheckCircleOutlineIcon /> Looks good!
    </p>
  )

  const foundButNotValidBadge = (result: ILoyaltyNumberValidationResult) => (
    <p className={classNames([styles.validationResultText, styles.validationResultFailText])}>
      <ErrorOutlineIcon /> {result.message}
    </p>
  )

  const notFoundBadge = (result: ILoyaltyNumberValidationResult) => (
    <p className={classNames([styles.validationResultText, styles.validationResultFailText])}>
      <ErrorOutlineIcon /> {result.message}
    </p>
  )

  const errorBadge = () => (
    <p className={classNames([styles.validationResultText, styles.validationResultFailText])}>
      <CancelOutlinedIcon /> Unable to verify details. Please check your details and try again.
    </p>
  )

  return validationResult.status === 'VERIFIED' ? validNumberBadge()
    : validationResult.status === 'NOT_ALLOWED' ? foundButNotValidBadge(validationResult)
    : validationResult.status === 'NOT_FOUND' ? notFoundBadge(validationResult)
    : errorBadge()
}

const QFFDetails: FunctionComponent<ILoyaltySchemeDetailProps> = ({setProviderValue, possiblePointsEarned, value, surname, persisted}) => {
  const [validationResult, setValidationResult] = useState<ILoyaltyNumberValidationResult>()

  const [currentValue, setCurrentValue] = useState(value)
  const [updatedBookingSurname, setBookingSurname] = useState("")

  const currentSurname = () => {
    return updatedBookingSurname || surname
  }

  const [canApplyValue, setCanApplyValue] = useState(!!currentSurname() && !!currentValue)
  const [validatedValue, setValidatedValue] = useState(value)
  const [isOpen, setIsOpen] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const openPopup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
  
    setIsOpen(true)
  }

  const closePopup = () => setIsOpen(false)

  const surnameChanged = (value: string) => {
    setBookingSurname(value)
    setCanApplyValue(!!value && !!currentValue)
  }

  const onClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    
    setIsBusy(false)
    setValidationResult(undefined)
    setValidatedValue(undefined)
    setProviderValue(null)
  }

  const valueChanged = (value: string) => {
    setCurrentValue(value)
    setCanApplyValue(!!currentSurname() && !!value)
  }

  const onApplyToBooking = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!currentValue) return

    setIsBusy(true)
    setCanApplyValue(false)

    validateLoyaltyNumber({
      loyaltyProgramNumber: currentValue,
      loyaltyProgramType: LoyaltyProgramType.QFF,
      surname: currentSurname()
    }).then(r => {
      setIsBusy(false)
      setValidationResult(r)

      if (r.status === "VERIFIED") {
        setIsOpen(false)
        setValidatedValue(currentValue)
        setProviderValue({membershipNumber: currentValue, surname: currentSurname()})
        setCanApplyValue(true)
        closePopup()
      }
    })
  }

  const logo = (
    <Image
      url="/logos/qff.png"
      description="Qantas Frequent Flyer Logo"
      defaultImage=''
      className={styles.providerLogo}
    />
  )

  const notRegisteredBlurb = {
    "en-GB": (<span>Add your Qantas Frequent Flyer number to earn <span className={styles.QFFPrimaryText}>{formatNumber(possiblePointsEarned)} Qantas Points<sup>&dagger;</sup></span> on your booking.</span>),
    "de-DE": (<span>Fügen Sie Ihre Qantas-Vielfliegernummer hinzu, um <span className={styles.QFFPrimaryText}>{formatNumber(possiblePointsEarned)} Qantas-Punkte<sup>&dagger;</sup></span> bei Ihrer Buchung zu sammeln.</span>)
  }[currentLocale()]

  const registeredBlurb = (
    <p>
      <strong><FormattedMessage id="labels.loyalty_scheme.qff_number" /></strong><br />
      {validatedValue}
    </p>
  )

  const actionButton = () => {
    if (persisted) return undefined

    return validatedValue
      ? <button className="action-buttons__primary-fullwidth" onClick={onClear}>Remove</button>
      : <button className="action-buttons__primary-fullwidth" onClick={openPopup}>Add</button>
  }

  return (
    <>
      <div className="booking-payment-option">
        <Grid container item alignItems="center" spacing={2}>
          <Grid container item md={10} alignItems="center" justifyContent="center">
            <Grid item md={3}>{logo}</Grid>
            <Grid item md={9}>
              {
                validatedValue
                  ? registeredBlurb
                  : notRegisteredBlurb
              }
            </Grid>
          </Grid>

          <Grid item xs={12} md={2}>
            {actionButton()}
          </Grid>
        </Grid>
      </div>

      {
        <Dialog open={isOpen} onClose={closePopup}>
          <div className={styles.loyaltyFormContainer} style={{margin: "2em"}}>
            <Grid container item>
              <Grid container item spacing={2} alignItems="flex-end">
                <Grid item xs={6}>
                  <FormattedMessage id="guest-details.last-name">
                    {
                      t => (
                        <label htmlFor='QFFSurname'>
                          {t}
                          <input name='QFFSurname' type='text' id='QFFSurname' disabled={!!surname && surname !== ""} readOnly={!!surname && surname !== ""} placeholder={t?.toLocaleString()} onChange={e => surnameChanged(e.target.value)} value={currentSurname()} />
                        </label>
                      )
                    }              
                  </FormattedMessage>
                </Grid>

                <Grid item xs={6}>
                  <FormattedMessage id="labels.loyalty_scheme.qff_number">
                    {
                      t => (
                        <label htmlFor='QFFMembershipNumber'>
                          {t}
                          <input name='QFFMembershipNumber' type='text' id='QFFMembershipNumber' placeholder={t?.toLocaleString()} onChange={e => valueChanged(e.target.value)} value={currentValue} />
                        </label>
                      )
                    }              
                  </FormattedMessage>
                </Grid>

                <Grid item xs={12}>
                  <button className="action-buttons__primary-fullwidth" onClick={onApplyToBooking} disabled={!canApplyValue || isBusy}>
                    {
                      isBusy
                        ? "Please wait.."
                        : "Apply to Booking"
                    }
                  </button>
                </Grid>
    
                {
                  validationResult && validationResult.status !== "SKIPPED" && (
                    <Grid item xs={12}>
                      <LoyaltyNumberValidationNotice validationResult={validationResult} />
                    </Grid>
                  )
                }
              </Grid>
  
              <Grid item>
                <div className={styles.disclaimerText}>
                  <ul className={styles.noBullets}>
                    <li><FormattedMessage id="messages.possible_loyalty_earn.qff.disclaimer" /></li>
                    <li>
                      <span className={styles.QFFPrimaryText}>
                        <sup>&dagger;</sup>
                      </span>
                      <FormattedMessage id="messages.possible_loyalty_earn.disclaimer" />
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      }
    </>
  )
}